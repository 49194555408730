import React, { useEffect } from "react";
import OrbitButton from "../../shared/OrbitButton";
import { FileButton } from "../../shared/RaisedButton";
import CreditCard from "../../../assets/credit-card.svg";
import { useDispatch, useSelector } from "react-redux";
import { GET_LAST_PAYMENT } from "../../../actions";
import { RootState } from "../../../Constants/types";

const infoFields = ["Current subscription", "Credit Card"];

export default function Subscription() {
  const payment = useSelector(
    (state: RootState) => state.paymentStatus.lastPayment
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_LAST_PAYMENT });
  }, []);

  const plan =
    payment?.amount === 1000
      ? "$" + (payment?.amount / 100).toString() + " / month"
      : payment?.amount === 10000
      ? "$" + (payment?.amount / 100).toString() + " / month"
      : "None";

  return (
    <div className="body" style={{ marginTop: 40 }}>
      <div className="title" style={{ marginLeft: 220 }}>
        SUBSCRIPTION
      </div>
      <div className="col">
        {infoFields.map((infoField) => {
          switch (infoField) {
            case "Current subscription":
              return (
                <div
                  id={"Subscription-" + infoField}
                  className="options-container"
                  style={{ marginLeft: 27, alignItems: "center" }}
                >
                  <div className="options">{infoField}</div>
                  <OrbitButton
                    text={plan}
                    style={{ marginLeft: 20, width: 155, height: 36 }}
                  />
                  <OrbitButton
                    text="SEE OTHER OPTIONS"
                    white
                    style={{
                      width: 185,
                      height: 24,
                      borderRadius: 4,
                      marginLeft: 20,
                      backgroundColor: "white",
                    }}
                  />
                </div>
              );
            case "Credit Card":
              return (
                <div
                  id={"Subscription-" + infoField}
                  className="options-container"
                  style={{ marginLeft: 92, alignItems: "center" }}
                >
                  <div className="options">{infoField}</div>
                  <img src={CreditCard} style={{ marginLeft: 20 }} />
                  <div className="options-value" style={{ marginLeft: 10 }}>
                    Ending in {payment?.last4}
                  </div>
                  <OrbitButton
                    text="CHANGE CREDIT CARD"
                    white
                    style={{
                      width: 205,
                      height: 24,
                      borderRadius: 4,
                      marginLeft: 28,
                      backgroundColor: "white",
                    }}
                  />
                </div>
              );
          }
        })}
      </div>
    </div>
  );
}
