import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { Orbit, RootState } from "../../../Constants/types";

import {
  FilterItem,
  FlowFilterItem,
  ListFilterItem,
  OrbitFilterItem,
} from "./FilterItem";
import "./FilterList.css";

type FilterListProps = {
  list: string[];
  onSelect: (orbit: string) => void;
  style?: CSSProperties;
};

export function Lists({ list, onSelect, style }: FilterListProps) {
  const selectedLists = useSelector(
    (state: RootState) => state.filtersAndLists.selectedLists
  );
  return (
    <div className="max-size column" style={style}>
      {list.map((name) => (
        <FilterItem
          label={name}
          key={"orbit-" + name}
          onSelect={() => onSelect(name)}
          isSelected={selectedLists.includes(name)}
          style={{ marginTop: 8 }}
        />
      ))}
    </div>
  );
}

export function Flows({ list, onSelect, style }: FilterListProps) {
  const selectedFlow = useSelector(
    (state: RootState) => state.flows.selectedFlow
  );
  return (
    <div className="max-size column" style={style}>
      {list.map((name) => (
        <FilterItem
          label={name}
          key={"orbit-" + name}
          onSelect={() => onSelect(name)}
          isSelected={selectedFlow === name}
        />
      ))}
    </div>
  );
}

export function MissingProperties({ list, onSelect, style }: FilterListProps) {
  const selectedProperties = useSelector(
    (state: RootState) => state.filtersAndLists.selectedProperties
  );
  const contacts = useSelector(
    (state: RootState) => state.contacts.contactsList
  );

  const countMissing = (name: string): number => {
    let filteredContacts;
    switch (name) {
      case "Orbit":
        filteredContacts = contacts.filter(
          (contact) => contact.orbitNumber === 6
        );
        return filteredContacts ? filteredContacts.length : 0;
      case "List":
        filteredContacts = contacts.filter(
          (contact) => contact.lists?.length === 0
        );
        return filteredContacts ? filteredContacts.length : 0;
      case "Name":
        filteredContacts = contacts.filter((contact) => contact.surname === "");

        return filteredContacts ? filteredContacts.length : 0;
      case "Email":
        filteredContacts = contacts.filter((contact) => contact.email === "");

        return filteredContacts ? filteredContacts.length : 0;
      case "Phone":
        filteredContacts = contacts.filter(
          (contact) => contact.phoneNumbers?.length === 0
        );
        return filteredContacts ? filteredContacts.length : 0;
      default:
        return 0;
    }
  };

  return (
    <div className="max-size column" style={style}>
      {list.map((name, index) => (
        <div id={name + "-" + index} className="missing-property-container">
          <FilterItem
            label={"Missing " + name}
            key={"orbit-" + name}
            onSelect={() => onSelect(name)}
            isSelected={selectedProperties.includes(name)}
            style={{ marginBottom: 16 }}
          />
          <span className="missing-number">{countMissing(name)}</span>
        </div>
      ))}
    </div>
  );
}

type OrbitsProps = {
  orbits: Orbit[];
  onSelect: (orbitNumber: number) => void;
  style?: CSSProperties;
};

export function Orbits({ orbits, onSelect, style }: OrbitsProps) {
  return (
    <div className="orbits" style={style}>
      {orbits.map((data) => (
        <div
          key={"orbit-" + data.number}
          className="row"
          style={{ marginBottom: 16 }}
        >
          <OrbitFilterItem
            orbit={data}
            orbitNumber={data.number}
            onSelect={onSelect}
            style={{}}
          />
        </div>
      ))}
    </div>
  );
}
