import SelectInput from "@material-ui/core/Select/SelectInput";
import React from "react";
import Select, { OptionTypeBase, ValueType } from "react-select";
import makeAnimated from "react-select/animated";

import {
  PRIMARY,
  PRIMARY_60,
  WARM_GRAY,
} from "../../../../Constants/colorsPalette";

interface country {
  country: string;
  code: string;
}

const countryList: country[] = [
  { country: "Australia", code: "AU" },
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Bulgaria", code: "BG" },
  { country: "Brazil ", code: "BR" },
  { country: "Canada", code: "CA" },
  { country: "Cyprus", code: "CY" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Denmark", code: "DK" },
  { country: "Estonia", code: "EE" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Greece", code: "GR" },
  { country: "Hong Kong", code: "HK" },
  { country: "India", code: "IN" },
  { country: "Ireland", code: "IE" },
  { country: "Italy", code: "IT" },
  { country: "Japan", code: "JP" },
  { country: "Latvia", code: "LV" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Malaysia", code: "MY" },
  { country: "Malta", code: "MT" },
  { country: "Mexico ", code: "MX" },
  { country: "Netherlands", code: "NL" },
  { country: "New Zealand", code: "NZ" },
  { country: "Norway", code: "NO" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Romania", code: "RO" },
  { country: "Singapore", code: "SG" },
  { country: "Slovakia", code: "SK" },
  { country: "Slovenia", code: "SI" },
  { country: "Spain", code: "ES" },
  { country: "Sweden", code: "SE" },
  { country: "Switzerland", code: "CH" },
  { country: "United Kingdom", code: "GB" },
  { country: "United States", code: "US" },
];

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: `1px dotted ${PRIMARY_60}`,
    backgroundColor: WARM_GRAY,
    color: PRIMARY,
    fontWeight: state.isSelected ? "bold" : "normal",
    padding: 20,
    "&:hover": {
      backgroundColor: PRIMARY_60,
      color: WARM_GRAY,
    },
  }),
  control: (styles: any) => ({
    ...styles,
    width: "100%",
    maxWidth: 454,
    backgroundColor: WARM_GRAY,
    border: "none",
    borderBottom: `1px solid ${PRIMARY}`,
    borderRadius: "4px 4px 0px 0px",
    color: PRIMARY,
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 10,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

const animatedComponents = makeAnimated();

export default function CountrySelect({ onChange }: { onChange: Function }) {
  return (
    <Select
      className="country-select"
      components={animatedComponents}
      options={countryList.map((country, i) => ({
        value: country.code,
        label: country.country,
      }))}
      onChange={(option: any) => onChange(option?.value)}
      styles={customStyles}
      closeMenuOnScroll
    />
  );
}
