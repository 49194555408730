import React from "react";
import { FlowStatus } from "../../../Constants/types";

type props = {
  status: FlowStatus;
  colSpan?: number;
};

export default function StatusTag({ status, colSpan }: props) {
  return (
    <td colSpan={colSpan}>
      <span className="status-tag">{status}</span>
    </td>
  );
}
