import React, { CSSProperties } from "react";

import { Add } from "@material-ui/icons";
import { ArrowDown, ArrowRight } from "../../shared/CustomIcons";
import OrbitButton from "../../shared/OrbitButton";

type ListTriggerProps = {
  text?: string;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
};

export default function ListTrigger({
  text,
  style,
  onClick,
}: ListTriggerProps) {
  return (
    <div className="row" style={{ ...style, justifyContent: "space-between" }}>
      <div className="row centered">
        <ArrowDown id="arrow-down" />
        <ArrowRight id="arrow-right" />
        {text ? <span>&nbsp;&nbsp;{text}</span> : null}
      </div>
    </div>
  );
}
