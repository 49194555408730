import { combineReducers } from "redux";

import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import testUserReducer from "./testUserReducer";
import filtersReducer from "./filtersReducer";
import contactsReducer from "./contactsReducer";
import paymentReducer from "./paymentReducer";
import flowsReducer from "./flowsReducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  testUser: testUserReducer,
  paymentStatus: paymentReducer,
  contacts: contactsReducer,
  filtersAndLists: filtersReducer,
  flows: flowsReducer,
});

export default rootReducer;
