import React from "react";
import { Contact } from "../../../Constants/types";
import shareIcon from "../../../assets/share.svg";
import mailIcon from "../../../assets/mail.svg";
import phoneIcon from "../../../assets/phone.svg";
import companyIcon from "../../../assets/company.png";

type props = {
  contact: Contact;
};

export default function ThirdSection({ contact }: props) {
  return (
    <div className="third-section">
      <div className="row">
        <span className="title">All Contact Details</span>
        <img
          style={{ marginLeft: 10 }}
          src={shareIcon}
          width={15}
          height={15}
        />
      </div>
      <div className="details">
        <div className="example left">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <span style={{ float: "left" }}>Personal</span>
            {/* <img
              className="share-icon"
              src={shareIcon}
              width={15}
              height={15}
            /> */}
          </div>
          <div className="example-details">
            <div className="row">
              <img src={mailIcon} width={15} height={15} />
              <span className="detail">{contact?.email}</span>
            </div>
            <div className="row" style={{ marginTop: 10 }}>
              <img src={phoneIcon} width={15} height={15} />
              <span className="detail">{contact?.phoneNumbers[0]?.number}</span>
            </div>
          </div>
        </div>
        <div className="example right">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <span style={{ float: "left" }}>Professional</span>
            {/* <img
              className="share-icon"
              src={shareIcon}
              width={15}
              height={15}
            /> */}
          </div>
          <div className="example-details">
            <div className="row">
              <img src={companyIcon} width={15} height={15} />
              <span className="detail">add company address</span>
            </div>
            <div className="row" style={{ marginTop: 10 }}>
              <img src={mailIcon} width={15} height={15} />
              <span className="detail">{contact?.email}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
