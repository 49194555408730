import React from "react";
import SlidesContent from "./SlidesContent";
import Slide from "./Slide";
import "./Slides.css";

type props = {
  setSelectedId: Function;
  selectedId: number;
};

export default function Slides({ selectedId, setSelectedId }: props) {
  return (
    <>
      {SlidesContent.map((content, id) => (
        <Slide
          key={"slide" + id}
          id={"slide" + id}
          Content={content}
          setSelectedId={() => selectedId !== id && setSelectedId(id)}
        />
      ))}
    </>
  );
}
