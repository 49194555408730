import React from "react";
import Modal from "react-modal";

import Close from "@material-ui/icons/Close";
import "./Modal.css";

const customStyles: Modal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "30px 20px",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "530px",
    width: "87%",
    maxWidth: "900px",
  },
  overlay: {
    backgroundColor: "rgba(45, 38, 51, 0.9)", //PRIMARY
  },
};

type props = {
  title: string;
  Content: ({ closeModal }: { closeModal?: () => void }) => JSX.Element;
  Trigger: (onClick: (event: React.MouseEvent) => void) => JSX.Element;
};

export default function ModalWindow({ title, Content, Trigger }: props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal(event: React.MouseEvent) {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div style={{ width: "100%" }}>
      {Trigger(openModal)}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Upload Photo"
      >
        <div className="modal-header fixed-row">
          <span className="modal-title">{title}</span>
          <Close onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
        <br />
        <Content closeModal={closeModal} />
      </Modal>
    </div>
  );
}

Modal.setAppElement("#root");
