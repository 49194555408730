import {
  put,
  takeLeading,
  call,
  takeEvery,
  all,
  takeLatest,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNOUT,
  CHECK_EXTRA_USER_DATA,
  EDIT_EXTRA_DATA_IN_STATE,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_DATA_SUCCESS,
  GET_CONTACTS,
  GET_LISTS,
  GET_ORBITS,
  EDIT_ACCOUNT_SETTINGS,
  CHANGE_USER_PASSWORD,
  EDIT_CHANGE_PASSWORD_ERROR,
  CHANGE_USER_PASSWORD_FINISH,
  CHANGE_USER_PASSWORD_START,
  UPDATE_PROFILE_PICTURE,
  UPDATE_USER_AFTER_PROFILE_PICTURE_CHANGE,
  UPDATE_PROFILE_PICTURE_FINISH,
  UPDATE_PROFILE_PICTURE_START,
} from "../actions";
import firebase, { rsf } from "../config/firebase";
import { Action } from "../Constants/types";

import CookieService from "../Cookies/CookieService";

function* getCurrentUserData() {
  const getUserData = firebase.functions().httpsCallable("getUserData");
  const currentUser = firebase.auth().currentUser;
  const userData = yield call(getUserData);

  if (userData) {
    yield put({
      type: GET_CURRENT_USER_DATA_SUCCESS,
      value: userData.data.data,
    });
  } else {
    yield put({ type: GET_CURRENT_USER_DATA_SUCCESS, value: null });
  }
}

function* checkExtraUserData() {
  const user = firebase.auth().currentUser;
  if (user) {
    let userData;
    firebase
      .firestore()
      .collection("users")
      .doc(user?.uid)
      .get()
      .then((currentUser) => {
        userData = currentUser.data();
      });

    const userPayments = yield call(
      rsf.firestore.getCollection,
      `stripe_customers/${user?.uid}/payment_methods`
    );

    const hasSubscribed = userPayments.docs[0]?.data() ? true : false;
    const signedinithGoogle = userData ? true : false;

    yield put({
      type: EDIT_EXTRA_DATA_IN_STATE,
      value: { hasSubscribed, signedinithGoogle },
    });

    yield put({ type: GET_CURRENT_USER_DATA });
  } else {
    yield put({
      type: EDIT_EXTRA_DATA_IN_STATE,
      value: {},
    });
  }
}

function* signup(action: Action) {
  try {
    var avatarUrl = undefined;
    if (action.value.userData.avatar.size > 0) {
      const avatarName =
        "avatar_" + Math.random().toString(36).slice(2) + ".png";
      const task = rsf.storage.uploadFile(
        `images/${avatarName}`,
        action.value.userData.avatar
      );
      const channel = eventChannel((emit) => task.on("state_changed", emit));
      yield takeEvery(channel, () => {});
      yield task;

      avatarUrl = yield call(
        rsf.storage.getDownloadURL,
        `images/${avatarName}`
      );
    }
    const data = {
      firstName: action.value.userData.firstName,
      lastName: action.value.userData.lastName,
      email: action.value.userData.email,
      username: action.value.userData.username.toLowerCase(),
      password: action.value.userData.password,
      avatarUrl,
    };
    var createUser = firebase.functions().httpsCallable("createUser");

    const response = yield call(createUser, data);
    if (!response || response?.data.code !== 200) {
      yield put({ type: SIGNUP_ERROR, value: response.data.message });
      // throw new Error("Something went wrong! please try again.");
    } else {
      yield put({ type: SIGNUP_ERROR, value: "" });
      yield put({ type: CHECK_EXTRA_USER_DATA });
      yield put({ type: SIGNUP_SUCCESS, value: response.data.user });
      yield call(rsf.auth.signInWithCustomToken, response.data.token);
      action.value.history.push("/signup/googleSignin");
    }
  } catch (error) {
    console.log(error);
    // yield put({ type: SIGNUP_ERROR, value: error });
  }
}

function* login(action: Action) {
  try {
    var getEmailUsingUsername = firebase
      .functions()
      .httpsCallable("getEmailUsingUsername");
    const response = yield call(getEmailUsingUsername, {
      username: action.value.userData.username.toLowerCase(),
    });

    if (response && response.data.code === 200) {
      yield call(
        rsf.auth.signInWithEmailAndPassword,
        response.data.data.email,
        action.value.userData.password
      );

      yield put({
        type: LOGIN_ERROR,
        value: "",
      });

      yield put({ type: CHECK_EXTRA_USER_DATA });
      yield put({ type: LOGIN_SUCCESS });
      yield put({ type: GET_CURRENT_USER_DATA });
      action.value.history.push("/my-orbits");
    } else {
      throw new Error("Something went wrong! please try again.");
    }
  } catch (error) {
    console.log(JSON.parse(JSON.stringify(error)));
    yield put({
      type: LOGIN_ERROR,
      value: JSON.parse(JSON.stringify(error)).message,
    });
  }
}

function* signout() {
  try {
    yield call(() => firebase.auth().signOut());
    yield put({ type: CHECK_EXTRA_USER_DATA });
  } catch (error) {
    console.log(error);
  }
}

function* editAccountSettings(action: Action) {
  try {
    const editAccountSettings = firebase
      .functions()
      .httpsCallable("editAccountSettings");
    let response;
    switch (action.value.changedField) {
      case "firstName":
        response = yield call(editAccountSettings, {
          firstName: action.value.firstName,
        });
        break;
      case "lastName":
        response = yield call(editAccountSettings, {
          lastName: action.value.lastName,
        });
        break;
      case "phoneNumber":
        response = yield call(editAccountSettings, {
          phoneNumber: action.value.phoneNumber,
          phoneType: action.value.phoneType,
        });
        break;
      case "displayName":
        response = yield call(editAccountSettings, {
          displayName: action.value.displayName,
        });
        break;
    }
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
    }
  } catch (error) {
    console.log(error);
  }
}

function* changeUserPassword(action: Action) {
  try {
    const oldPassword = action.value.oldPassword;
    const newPassword = action.value.newPassword;
    const confirmedPassword = action.value.confirmedPassword;
    if (oldPassword === "" || newPassword === "" || confirmedPassword === "") {
      yield put({
        type: EDIT_CHANGE_PASSWORD_ERROR,
        value: "A field or more is missing",
      });
    } else {
      if (newPassword !== confirmedPassword) {
        yield put({
          type: EDIT_CHANGE_PASSWORD_ERROR,
          value: "Your new password and confirmation password do not match",
        });
      } else {
        var user = firebase.auth().currentUser;
        if (user) {
          const email = user?.email ? user?.email : "";
          var credential = firebase.auth.EmailAuthProvider.credential(
            email,
            oldPassword
          );
          user
            .reauthenticateWithCredential(credential)
            .then(function () {
              // User re-authenticated.
              if (user) {
                user
                  .updatePassword(newPassword)
                  .then(function () {
                    // Update successful.
                  })
                  .catch(function (error) {});
              }
            })
            .catch(function (error) {});
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateProfilePicture(action: Action) {
  try {
    yield put({ type: UPDATE_PROFILE_PICTURE_START });
    var avatarUrl = undefined;
    if (action.value.picture.size > 0) {
      const avatarLink: string = action.value.avatarName;
      let avatarName = avatarLink.split("%2F")[1].split("?")[0];
      if (avatarName === "avatar_placeholder.svg") {
        avatarName = "avatar_" + Math.random().toString(36).slice(2) + ".png";
      }
      const task = rsf.storage.uploadFile(
        `images/${avatarName}`,
        action.value.picture
      );
      const channel = eventChannel((emit) => task.on("state_changed", emit));
      yield takeEvery(channel, () => {});
      yield task;

      avatarUrl = yield call(
        rsf.storage.getDownloadURL,
        `images/${avatarName}`
      );
    }
    const updateProfilePicture = firebase
      .functions()
      .httpsCallable("updateProfilePicture");
    const response = yield call(updateProfilePicture, {
      pictureUrl: avatarUrl,
    });
    // const response = yield call(updateProfilePicture, {
    //   pictureUrl: action.value.pictureUrl,
    // });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong");
    } else {
      yield put({
        type: UPDATE_USER_AFTER_PROFILE_PICTURE_CHANGE,
        value: avatarUrl,
      });

      yield put({ type: UPDATE_PROFILE_PICTURE_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* signupSaga() {
  yield all([
    takeLeading(SIGNUP, signup),
    takeLeading(LOGIN, login),
    takeLeading(SIGNOUT, signout),
    takeLeading(GET_CURRENT_USER_DATA, getCurrentUserData),
    takeLatest(CHECK_EXTRA_USER_DATA, checkExtraUserData),
    takeLatest(CHANGE_USER_PASSWORD, changeUserPassword),
    takeEvery(EDIT_ACCOUNT_SETTINGS, editAccountSettings),
    takeEvery(UPDATE_PROFILE_PICTURE, updateProfilePicture),
  ]);
}
