import React from "react";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideZero({ inViewport, forwardedRef }: props) {
  // let name =
  //   useSelector((state: RootState) => state.testUser.testUserFirstName) || "";

  // name = name?.charAt(0).toUpperCase() + name?.slice(1);

  return (
    <div className="slide" ref={forwardedRef}>
      <div className="centered ">
        <p className="slide-headline">Welcome to your universe!</p>
      </div>
    </div>
  );
}
// , {name}!
