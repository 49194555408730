import React, { CSSProperties } from "react";
import "./OrbitButton.css";

type props = {
  text: string;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
  to?: string;
  dark?: boolean;
  dark60?: boolean;
  white?: boolean;
  disabled?: boolean;
  icon?: string;
  style?: CSSProperties;
  loading?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export default function OrbitButton({
  text,
  type = "button",
  dark,
  dark60,
  white,
  disabled,
  style,
  className,
  icon,
  to,
  loading,
  onClick,
}: props) {
  const classNameDefault =
    "orbit-btn fixed-row centered " +
    (dark60 ? "dark60" : "") +
    (dark ? "dark" : "") +
    (white ? "white" : "");
  return (
    <button
      className={className ? className : classNameDefault}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
