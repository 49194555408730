import React, { ChangeEvent, CSSProperties, useState } from "react";

import { IconButton } from "@material-ui/core";
import ToggleIcon from "material-ui-toggle-icon";
import { Visibility, VisibilityOff, Close } from "@material-ui/icons";
import "./InputField.css";

type Props = {
  type?: string;
  inputmode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | undefined;
  placeholder: string;
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onKeyPress?:
    | ((event: React.KeyboardEvent<HTMLInputElement>) => void)
    | undefined;
  opaque?: boolean;
  style?: CSSProperties;
  className?: string;
  width?: CSSProperties["width"];
  icon?: Function;
  leadingIcon?: Function;
  id?: string;
  name?: string;
  pattern?: string;
  note?: string;
  invalid?: boolean;
  errorMessage?: string;
  required?: boolean;
  clearable?: boolean;
  onClear?: Function;
  value?: string;
};

export default function InputField({
  type = "text",
  inputmode,
  placeholder,
  onChange,
  onKeyPress,
  opaque,
  style,
  className,
  width,
  icon,
  leadingIcon,
  id,
  name,
  pattern,
  note,
  invalid,
  errorMessage,
  required,
  clearable,
  onClear,
  value,
}: Props) {
  const classes =
    " input-field " +
    (opaque ? " opaque " : "") +
    (icon || clearable ? " with-icon " : "") +
    (leadingIcon ? " with-leading " : "") +
    (invalid ? " invalid " : "");
  return (
    <div
      className="input-container column"
      style={{ width: "100%", maxWidth: width }}
    >
      <div className={classes + " fixed-row"} style={{ width: "100%" }}>
        {leadingIcon && (
          <div className="leadingIcon centered" style={style}>
            {leadingIcon && leadingIcon()}
          </div>
        )}
        <input
          required={required}
          type={type}
          inputMode={inputmode}
          placeholder={placeholder}
          onChange={onChange}
          style={style}
          id={id}
          name={name}
          pattern={pattern}
          value={value}
          className={className}
          onKeyPress={onKeyPress}
        />
        {icon && (
          <div className="icon centered" style={style}>
            {icon && icon()}
          </div>
        )}
        {clearable && (
          <div className="icon centered" style={style}>
            {clearable && (
              <Close
                className="input-clear"
                onClick={() => onClear && onClear()}
              />
            )}
          </div>
        )}
      </div>
      {errorMessage && <span className="input-error">{errorMessage}</span>}
      {note && <span className="input-hint">{note}</span>}
    </div>
  );
}

export function PasswordInput(props: Props) {
  const [isVisible, setVisible] = useState(false);
  const icon = () => (
    <IconButton onClick={() => setVisible(!isVisible)}>
      <ToggleIcon
        on={isVisible}
        onIcon={<VisibilityOff />}
        offIcon={<Visibility />}
      />
    </IconButton>
  );

  return (
    <InputField {...props} type={isVisible ? "text" : "password"} icon={icon} />
  );
}
