import React, { CSSProperties, HtmlHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import {
  PRIMARY,
  SECONDARY,
  WARM_GRAY,
} from "../../../Constants/colorsPalette";
import "./RaisedButton.css";

type props = {
  text: String;
  id?: string;
  type?: "button" | "submit" | "reset" | undefined;
  to?: string;
  style?: CSSProperties;
  icon?: Function;
  leadingIcon?: Function;
  dark?: Boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export default function RaisedButton({
  id,
  text,
  type = "button",
  style,
  dark = false,
  to,
  icon,
  leadingIcon,
  disabled,
  loading,
  onClick,
}: props) {
  const className =
    "raised-btn fixed-row centered " +
    (dark ? " dark " : "") +
    (disabled ? " disabled " : "") +
    (leadingIcon ? " with-leading " : "");

  const containerStyle: CSSProperties = {
    width: style?.width ? style?.width : "100%",
    maxWidth: style?.maxWidth,
    height: style?.height,
    maxHeight: style?.maxHeight,
    margin: style?.margin,
  };

  const buttonStyle: CSSProperties = {
    ...style,
    width: "100%",
    maxWidth: "100%",
  };

  const content = loading ? (
    <Loader
      type="TailSpin"
      color={dark ? WARM_GRAY : SECONDARY}
      height={30}
      width={30}
    />
  ) : (
    <>
      {leadingIcon && leadingIcon()} &nbsp;{text} &nbsp;
      {icon && icon()}
    </>
  );

  return (
    <div
      id={id}
      className={className}
      style={containerStyle}
      onClick={loading ? undefined : onClick}
    >
      {to && !disabled ? (
        <Link
          to={to}
          style={buttonStyle}
          type={type}
          className={"fixed-row centered"}
        >
          {content}
        </Link>
      ) : (
        <button
          className="fixed-row centered"
          style={buttonStyle}
          type={type}
          disabled={disabled}
        >
          {content}
        </button>
      )}
    </div>
  );
}

export function FileButton({
  text,
  style,
  dark = false,
  icon,
  disabled,
  loading,
  onChange,
}: props & {
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}) {
  const className =
    "raised-btn fixed-row centered " +
    (dark ? " dark " : "") +
    (disabled ? " disabled " : "");

  const content = loading ? (
    <Loader
      type="TailSpin"
      color={dark ? SECONDARY : PRIMARY}
      height={30}
      width={30}
    />
  ) : (
    <>
      &nbsp;&nbsp; {text} &nbsp;&nbsp; {icon && icon()}
    </>
  );
  return (
    <label className={className} style={style}>
      <input type="file" style={{ display: "none" }} onChange={onChange} />
      {content}
    </label>
  );
}
