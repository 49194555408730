import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { getFirebase } from "react-redux-firebase";

import reducers from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const _window: any = window;

const middlewares = [sagaMiddleware, reduxThunk.withExtraArgument(getFirebase)];
const composeEnhancers =
  _window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export default store;
