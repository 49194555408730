import { SwapVert } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SORT_CONTACTS } from "../../../actions";
import { Contact, RootState } from "../../../Constants/types";

type TableHeaderProps = {
  headers: { title: string; contactKey: keyof Contact | "status" }[];
  spans: number[];
  setSortingValue: Function;
  sortedBy: keyof Contact | null;
};

type HeaderTitleProps = {
  title: string;
  contactKey: keyof Contact | "status";
  sortedBy?: keyof Contact | null;
  colSpan?: number;
  sortContacts: Function;
};

export default function TableHeader({
  headers,
  spans,
  setSortingValue,
  sortedBy,
}: TableHeaderProps) {
  return (
    <tr className="header-row">
      {headers.map((header, i) => (
        <HeaderTitle
          key={"title-" + header.title}
          {...header}
          colSpan={spans[i]}
          sortContacts={setSortingValue}
          sortedBy={sortedBy}
        />
      ))}
    </tr>
  );
}

function HeaderTitle({
  title,
  contactKey,
  colSpan,
  sortContacts,
}: // sortedBy,
HeaderTitleProps) {
  const sortedBy = useSelector(
    (state: RootState) => state.contacts.sortingValue
  );
  const contacts = useSelector(
    (state: RootState) => state.contacts.contactsList
  );
  const dispatch = useDispatch();
  return (
    <th colSpan={colSpan}>
      <div
        className={
          "row title " +
          (sortedBy !== null && sortedBy === contactKey ? " selected " : "")
        }
      >
        <span>{title}</span>
        {title && (
          <SwapVert
            className="sort-icon"
            onClick={() =>
              dispatch({
                type: SORT_CONTACTS,
                value: { contacts, newSortingValue: contactKey },
              })
            }
          />
        )}
      </div>
    </th>
  );
}
