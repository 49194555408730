import {
  CHECK_EXTRA_USER_DATA,
  GET_LAST_PAYMENT,
  GET_ORBITS,
  NEW_PAYMENT,
  PAYMENT_FAILED,
  UPDATE_LAST_PAYMENT,
} from "../actions";
import { put, takeLeading, call } from "redux-saga/effects";
import { Action } from "../Constants/types";
import { rsf } from "../config/firebase";
import firebase from "../config/firebase";

function* newPayment(action: Action) {
  try {
    const {
      cardElement,
      billing_details,
      amount,
      stripe,
      history,
    } = action.value;
    const user = firebase.auth().currentUser;

    const snapshot = yield call(
      rsf.firestore.getDocument,
      `stripe_customers/${user?.uid}`
    );
    const clientSecret = snapshot.data().setup_secret;

    const { setupIntent, error } = yield call(
      stripe.confirmCardSetup,
      clientSecret,
      {
        payment_method: {
          card: cardElement ? cardElement : { token: "" },
          billing_details,
        },
      }
    );

    if (error) {
      throw new Error(error.message);
    }

    yield call(
      rsf.firestore.addDocument,
      `stripe_customers/${user?.uid}/payment_methods`,
      { id: setupIntent?.payment_method }
    );

    yield call(
      rsf.firestore.addDocument,
      `stripe_customers/${user?.uid}/payments`,
      {
        payment_method: setupIntent?.payment_method,
        currency: "usd",
        amount: amount,
        status: "new",
      }
    );

    yield put({ type: CHECK_EXTRA_USER_DATA });
    history.push("/my-orbits");
  } catch (error) {
    console.log(error);
    yield put({ type: PAYMENT_FAILED });
  }
}

function* getLastPayment() {
  try {
    const getLastPayment = firebase.functions().httpsCallable("getLastPayment");
    const uid = firebase.auth().currentUser?.uid;
    const response = yield call(getLastPayment, { uid });
    if (!response || response?.data.status !== "success") {
      throw new Error("Something went wrong");
    } else {
      yield put({
        type: UPDATE_LAST_PAYMENT,
        value: response.data.data.payment,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* paymentSaga() {
  yield takeLeading(NEW_PAYMENT, newPayment);
  yield takeLeading(GET_LAST_PAYMENT, getLastPayment);
}
