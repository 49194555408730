import React, { useState } from "react";
import { useDispatch } from "react-redux";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Loader from "react-loader-spinner";

import firebase from "../../../config/firebase";
import { CHECK_EXTRA_USER_DATA, GENERATE_ACCESS_TOKEN } from "../../../actions";
import { PRIMARY } from "../../../Constants/colorsPalette";
import RaisedButton from "../../shared/RaisedButton";
import GoogleContacts, {
  GoogleContactsResponseOffline,
} from "react-google-contacts";

export default function GoogleSignin() {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const currentUser = firebase.auth().currentUser;

  const uiConfig: firebaseui.auth.Config = {
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };

  const signin = () => {
    setProcessing(true);
    firebase
      .auth()
      .currentUser?.linkWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => {
        setProcessing(false);
        setSuccess(true);
      })
      .catch((error) => {
        setProcessing(false);
        console.log(error); //TODO: error handling
      });
  };

  const moveToSubscription = () => {
    setProcessing(true);
    dispatch({ type: CHECK_EXTRA_USER_DATA });
  };

  const responseCallback = (response: GoogleContactsResponseOffline) => {
    setProcessing(true);
    dispatch({
      type: GENERATE_ACCESS_TOKEN,
      value: { userID: currentUser?.uid, code: response.code },
    });
    setProcessing(false);
    setSuccess(true);
  };

  return (
    <div className="signup-form-container google column">
      <div className="signup-title column">
        <span className="title">Linking Google Contacts</span>
        <hr style={{ width: "60%", backgroundColor: `${PRIMARY}` }} />
        <div className="column subtitle">
          <span>
            Please sign into your Google account to import and sync your
            contacts.
          </span>
          <span>
            Any updates you make in Orbit will also be reflected in
            contacts.google.com.
          </span>
        </div>
      </div>
      <div
        className={"column" + (processing ? " centered" : "")}
        style={{ flexGrow: 1, paddingTop: "6%" }}
      >
        {processing && (
          <Loader type="TailSpin" color={PRIMARY} height={"100%"} width={30} />
        )}
        <div
          className="google-btn"
          style={{ display: processing || success ? "none" : "block" }}
        >
          {/* <button className="hidden-btn" onClick={signin} /> */}
          <div className="ui-btn-container">
            <GoogleContacts
              clientId="201100756936-7nt3a3qrl8ibb7fp85b9b1f69n2t3roc.apps.googleusercontent.com"
              redirectUri="http://localhost:3000/oauthcallback/:code"
              buttonText="Sync Google Contacts"
              responseType="code"
              accessType="offline"
              onSuccess={responseCallback}
              onFailure={responseCallback}
            />
          </div>
        </div>
      </div>
      {success && (
        <RaisedButton
          text="CONTINUE TO SUBSCRIPTION"
          dark
          loading={processing}
          style={{ maxWidth: "339px" }}
          onClick={moveToSubscription}
        />
      )}
      <br />
      <br />
    </div>
  );
}
