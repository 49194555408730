import Downshift from "downshift";
import React, { useState } from "react";
import { Contact } from "../../../Constants/types";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import { useDispatch } from "react-redux";
import { ADD_PARTNER_TO_CONTACT } from "../../../actions";

type props = {
  contacts: Contact[];
  placeholder: string;
  onSelect: (selectedItem: any) => void;
  addNonExistingPerson: (value: any) => void;
};

export default function DownshiftForLinking({
  contacts,
  placeholder,
  onSelect,
  addNonExistingPerson,
}: props) {
  const [value, setValue] = useState("");
  return (
    <Downshift
      onSelect={(selectedItem) => onSelect(selectedItem)}
      inputValue={value}
      onInputValueChange={(newValue) => setValue(newValue)}
      itemToString={(item) => (item ? item.givenName + " " + item.surname : "")}
      onOuterClick={() => setValue(value)}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getLabelProps,
        getToggleButtonProps,
        inputValue,
        highlightedIndex,
        selectedItem,
        isOpen,
      }) => (
        <div className="centered">
          {/* <label {...getLabelProps()}>Enter a fruit:</label> */}
          <input
            className="add-person"
            style={{ width: 110 }}
            placeholder={placeholder}
            {...getInputProps()}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                addNonExistingPerson(value);
                setValue(value);
              }
            }}
          />
          <ul {...getMenuProps()} className="options-menu contacts">
            {isOpen &&
              value !== "" &&
              contacts
                .filter(
                  (contact) =>
                    contact.givenName !== "" &&
                    (contact.givenName
                      .toLowerCase()
                      .toString()
                      .includes(value.toLowerCase()) ||
                      contact.surname
                        .toLowerCase()
                        .toString()
                        .includes(value.toLowerCase()) ||
                      (
                        contact.givenName.toLowerCase().toString() +
                        " " +
                        contact.surname.toLowerCase().toString()
                      ).includes(value.toLowerCase()))
                )
                .map((item, index) => (
                  <li
                    className="options-menu-item"
                    {...getItemProps({
                      key: `${item}${index}`,
                      item,
                      index,
                    })}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <img
                        id="preview"
                        className="avatar-img"
                        src={
                          item.pictureUrl !== ""
                            ? item.pictureUrl
                            : avatarPlaceholder
                        }
                        alt=""
                        width={30}
                        height={30}
                        style={{ borderRadius: 20 }}
                      />
                      <span>
                        {item.givenName} {item.surname}
                      </span>
                    </div>
                  </li>
                ))}
          </ul>
        </div>
      )}
    </Downshift>
  );
}
