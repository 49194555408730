import React, { useEffect, useState } from "react";

import {
  PRIMARY_60,
  SUCCESS,
  WARM_GRAY,
} from "../../../Constants/colorsPalette";
import RaisedButton from "../RaisedButton";
import ModalWindow from "../Modal";
import AvatarEditor from "./AvatarEditor";
// import FlatButton from "../FlatButton";
// import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import "./PhotoChange.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../Constants/types";

type props = {
  photoURL: string;
  onChange: Function;
  photoFile?: File;
};

export default function PhotoChange({ onChange, photoURL, photoFile }: props) {
  const changeProfilePictureProcessing = useSelector(
    (state: RootState) => state.auth.changeProfilePictureProcessing
  );

  const [imageUrl, setImageUrl] = useState(photoURL);
  const [photoExists, setPhotoExists] = useState(
    photoURL ===
      "https://firebasestorage.googleapis.com/v0/b/orbit-cd765.appspot.com/o/images%2Favatar_placeholder.svg?alt=media&token=c6c6e301-6d3f-4bd4-b539-ee0a2761210c"
      ? false
      : true
  );

  if (photoFile) {
    var imgFile = new File([photoFile], "placeHolder");
    var fileReader = new FileReader();
    fileReader.readAsDataURL(imgFile);

    fileReader.onload = function (oFREvent) {
      if (document && document.getElementById("preview")) {
        var preview: any = document.getElementById("preview");
        preview.src = oFREvent?.target?.result;
      }
    };
  }

  useEffect(() => {
    setImageUrl(photoURL);
  }, [photoURL]);

  return (
    <div className="photo-upload-container column centered">
      <div className="avatar-holder">
        <img
          id="preview"
          width="145px"
          height="145px"
          style={{
            borderRadius: "50%",
            backgroundColor: WARM_GRAY,
          }}
          src={imageUrl}
        />
      </div>
      <br />
      <ModalWindow
        title="Upload Photo"
        Content={({ closeModal }) =>
          AvatarEditor(onChange, photoURL, closeModal)
        }
        Trigger={(onClick: (event: React.MouseEvent) => void) => (
          <RaisedButton
            text={photoExists ? "Change photo" : "Upload photo"}
            style={{
              width: "195px",
              height: "36px",
              // maxWidth: "200px",
              margin: "0px auto",
            }}
            dark
            // disabled={skipped}
            onClick={onClick}
            loading={changeProfilePictureProcessing}
          />
        )}
      />
    </div>
  );
}

const CheckMarkIcon = () => (
  <>
    <div className="animation-ctn">
      <div className="success-icon success-icon--order-success svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 154 154"
          width="25px"
          height="25px"
        >
          <g fill="none" stroke={SUCCESS} strokeWidth="2">
            <circle
              cx="77"
              cy="77"
              r="72"
              style={{
                strokeDasharray: "480px, 480px",
                strokeDashoffset: "960px",
              }}
            ></circle>
            <circle
              id="colored"
              fill={SUCCESS}
              cx="77"
              cy="77"
              r="72"
              style={{
                strokeDasharray: "480px, 480px",
                strokeDashoffset: "960px",
              }}
            ></circle>
            <polyline
              className="st0"
              stroke="#fff"
              strokeWidth="10"
              points="43.5,77.8 63.7,97.9 112.2,49.4 "
              style={{
                strokeDasharray: "100px, 100px",
                strokeDashoffset: "200px",
              }}
            />
          </g>
        </svg>
      </div>
    </div>
    &nbsp;&nbsp;
  </>
);
