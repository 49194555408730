import React, { Component } from "react";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { ElementsConsumer, Elements } from "@stripe/react-stripe-js";
import logo from "../../assets/OrbitLogo_Purple.png";

import Details from "./steps/Details";
import GoogleSignin from "./steps/GoogleSignin";
import Subscribe from "./steps/Subscribe";
import "./Signup.css";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  // Elements,
} from "react-stripe-elements";

interface SignupProps {
  match: any;
}

interface SignupState {}

export default class Signup extends Component<SignupProps, SignupState> {
  step = this.props.match.params.step;
  stripePromise = loadStripe(
    "pk_test_51HoVY3CQYfNr8jkCwbG66P3ROTIJO3B3fICpsAbgK6TC1Hbq719AF5fQzub2JlZ4Jdt2T5BtyXBcIk6uCRyWtQEu00onyhykcf"
  );

  renderStep = () => {
    switch (this.step) {
      case "details":
        return <Details />;
      case "googleSignin":
        return <GoogleSignin />;
      case "subscribe":
        return <Subscribe />;
    }
  };

  render() {
    return (
      <div className="signup-container">
        <div className="logo-container">
          <img id="logo" src={logo} />
        </div>
        {this.renderStep()}
      </div>
    );
  }
}
