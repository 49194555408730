import { Reducer } from "redux";
import {
  VERIFY,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  CHECK_EXISTING_TEST_USER,
} from "../actions";
import { TestUser, Action } from "../Constants/types";

interface testUserRootState {
  isVerified: boolean;
  testUserFirstName?: string;
  testUserLastName?: string;
  isVerifying: boolean;
  checkingStorage: boolean;
}

const initialState: testUserRootState = {
  isVerified: false,
  testUserFirstName: "",
  testUserLastName: "",
  isVerifying: false,
  checkingStorage: false,
};

const testUserReducer: Reducer<testUserRootState, Action> = (
  state: testUserRootState = initialState,
  action: Action
) => {
  const newState = { ...state };

  switch (action.type) {
    case CHECK_EXISTING_TEST_USER:
      return {
        ...newState,
        checkingStorage: true,
      };

    case VERIFY:
      return {
        ...newState,
        isVerifying: true,
      };

    case VERIFY_SUCCESS:
      delete action.value.password;
      const user: TestUser = action.value;
      console.log(user);

      return {
        ...newState,
        isVerified: true,
        testUserFirstName: user.firstName,
        testUserLastName: user.lastName,
        isVerifying: false,
        checkingStorage: false,
      };

    case VERIFY_ERROR:
      console.log(action);

      return { ...newState, isVerifying: false, checkingStorage: false };
  }
  return newState;
};

export default testUserReducer;
