import SlideZero from "./0_SlideZero";
import SlideOne from "./1_SlideOne";
import SlideTwo from "./2_SlideTwo";
import SlideThree from "./3_SlideThree";
import SlideFour from "./4_SlideFour";
import SlideFive from "./5_SlideFive";
import SlideSix from "./6_SlideSix";
import SlideSeven from "./7_SlideSeven";
import SlideEight from "./8_SlideEight";
import SlideNine from "./9_SlideNine";
import SlideTen from "./10_SlideTen";
import SlideEleven from "./11_SlideEleven";
import SlideTwelve from "./12_SlideTwelve";
import SlideThirteen from "./13_SlideThirteen";
import SlideFourteen from "./14_SlideFourteen";

const SlidesConent = [
  SlideZero,
  SlideOne,
  SlideTwo,
  SlideThree,
  SlideFour,
  SlideFive,
  SlideSix,
  SlideSeven,
  SlideEight,
  SlideNine,
  SlideTen,
  SlideEleven,
  SlideTwelve,
  SlideThirteen,
  SlideFourteen,
];

export default SlidesConent;
