import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";

import Search from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu, MenuItem } from "@szhsin/react-menu";
import InputField from "../shared/InputField";
import OrbitsSidebar from "./OrbitsSidebar";
import ContactsTable from "../ContactsTable";
import {
  CHANGE_PAGE_NUMBER,
  CLEAR_ALL_FILTERS,
  GET_CONTACTS,
  GET_LISTS,
  GET_ORBITS,
  RESET_CHANGE_IN_CONTACTS_LIST,
  SEARCH_CONTACTS,
  SET_CHANGE_IN_CONTACTS_LIST,
  SIGNOUT,
  SYNC_CONTACTS,
} from "../../actions";
import { PRIMARY } from "../../Constants/colorsPalette";
import { Contact, RootState } from "../../Constants/types";
import "./OrbitContactsPage.css";
import "@szhsin/react-menu/dist/index.css";
import OrbitButton from "../shared/OrbitButton";
import Pagination from "react-js-pagination";
import AddContactCard from "./AddContactCard";
import firebase from "firebase";

export default function OrbitContactsPage() {
  const contacts: { contact: Contact }[] = useSelector(
    (state: RootState) => state.contacts.contactsList
  ).map((contact) => ({ contact }));

  const addContact = useSelector(
    (state: RootState) => state.contacts.addContact
  );

  const changeInContactsAttributes = useSelector(
    (state: RootState) => state.contacts.changeInContactsAttributes
  );

  const { selectedFlow, flows } = useSelector(
    (state: RootState) => state.flows
  );
  const processing = useSelector(
    (state: RootState) => state.contacts.processing
  );

  const currentPageNumber = useSelector(
    (state: RootState) => state.contacts.currentPageNumber
  );

  const user = firebase.auth().currentUser;

  const WAITING_TIME = 1800000;

  const [searchValue, setSearchValue] = useState("");
  const [visibleContacts, setVisibleContacts] = useState(contacts);
  const ITEMS_COUNT_PER_PAGE = 50;
  const dispatch = useDispatch();

  const syncContactsFromGoogle = () => {
    dispatch({ type: SYNC_CONTACTS });
  };

  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        syncContactsFromGoogle();
      }, WAITING_TIME);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (!processing) {
      dispatch({ type: GET_CONTACTS });
    }
    dispatch({ type: GET_ORBITS });
    dispatch({ type: GET_LISTS });
    dispatch({ type: CLEAR_ALL_FILTERS });
  }, []);

  useEffect(() => {
    if (selectedFlow) {
      const newContacts = flows.filter((flow) => flow.title === selectedFlow)[0]
        .contacts;
      setVisibleContacts(newContacts);
    } else if (
      (visibleContacts.length !==
        contacts.slice(
          (currentPageNumber - 1) * ITEMS_COUNT_PER_PAGE,
          ITEMS_COUNT_PER_PAGE * currentPageNumber
        ).length &&
        searchValue === "") ||
      changeInContactsAttributes
    ) {
      setVisibleContacts(
        contacts.slice(
          (currentPageNumber - 1) * ITEMS_COUNT_PER_PAGE,
          ITEMS_COUNT_PER_PAGE * currentPageNumber
        )
      );
      dispatch({ type: RESET_CHANGE_IN_CONTACTS_LIST });
    }
  }, [selectedFlow, contacts, changeInContactsAttributes, flows]);

  const signOut = () => dispatch({ type: SIGNOUT });
  const renderHeader = () => (
    <div className="header row">
      <InputField
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Press Enter to search contacts"
        width="278px"
        style={{ height: "36px", fontSize: 12 }}
        clearable
        onClear={() => {
          setSearchValue("");
          dispatch({ type: SEARCH_CONTACTS, value: "" });
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setVisibleContacts(
              contacts.filter(
                ({ contact }) =>
                  contact.givenName?.includes(searchValue) ||
                  contact.surname?.includes(searchValue) ||
                  contact.email?.includes(searchValue) ||
                  contact.phoneNumbers[0]?.number
                    .split(" ")
                    .join("")
                    .includes(searchValue)
              )
            );
          }
        }}
        leadingIcon={() => <Search style={{ color: PRIMARY }} />}
      />

      {/* <OrbitButton
        text="Sync contacts"
        dark
        style={{ width: 130, height: 36, marginRight: 200 }}
        onClick={syncContactsFromGoogle}
      /> */}
      <div className="actions row">
        <Menu
          direction="bottom"
          align="end"
          menuButton={
            <button className="menu-trigger centered">
              <MenuIcon id="icon" />
            </button>
          }
        >
          <MenuItem id="menuItem">
            <Link className="accountLink" to={"account-settings"}>
              Account
            </Link>
          </MenuItem>
          <MenuItem id="menuItem" onClick={signOut}>
            Sign Out
          </MenuItem>
        </Menu>
      </div>
    </div>
  );

  return (
    <div className="contacts-page outer-wrapper">
      <div className="inner-wrapper row">
        <div className="max-size">
          <div
            className="column max-size fixed-left-side"
            style={{
              width: "17.5%",
              // width: 280,
            }}
          >
            <OrbitsSidebar />
          </div>
          <div
            className="main-content-wrapper"
            style={
              {
                // position: "fixed",
                // marginLeft: 280,
                // width: 1000,
                // width: "84%",
              }
            }
          >
            <div className="column">
              {renderHeader()}
              {processing ? (
                <div
                  style={{
                    alignSelf: "center",
                    marginTop: 250,
                  }}
                >
                  <Loader
                    type="TailSpin"
                    color={PRIMARY}
                    height={60}
                    width={60}
                  />
                </div>
              ) : (
                <div className="table-container">
                  <ContactsTable
                    flowView={selectedFlow ? true : false}
                    contacts={visibleContacts}
                    searchValue={searchValue}
                  />
                  <div className="row">
                    <Pagination
                      activePage={currentPageNumber}
                      itemsCountPerPage={ITEMS_COUNT_PER_PAGE}
                      totalItemsCount={contacts.length}
                      pageRangeDisplayed={5}
                      onChange={(currentPage) => {
                        dispatch({ type: SET_CHANGE_IN_CONTACTS_LIST });
                        dispatch({
                          type: CHANGE_PAGE_NUMBER,
                          value: currentPage,
                        });
                      }}
                      innerClass="pages-container"
                      activeClass="pages-active"
                      itemClass="pages-item"
                      linkClass="pages-link"
                      disabledClass="pages-disabled"
                    />
                  </div>
                  {addContact ? <AddContactCard /> : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
