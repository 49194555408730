import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideThree({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Not Social Media</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Facebook, Instagram, Twitter, TikTok et al. can be fun... but
          ultimately sells your data and distorts your reality.
        </span>
        <span style={{ fontSize: "100px" }}>🙀</span>
        <span className="sub-description">
          Every 5 years a new dominant social network peaks. Before you know it
          all your friends and colleagues are indexed (and captured) by the app
          of the moment, rather than in your permanent and personal Contacts
          Book.
        </span>
        <span className="sub-description">
          The Law of Social Networks: all social media companies eventually
          devolve into an attention stealing, reality distorting, infinite
          scrolling “New Feed”.
        </span>
        <span className="sub-description">
          Learn more about the negative consequences of social media for your
          health and our society:
        </span>
        <span className="sub-description">www.thesocialdilemma.com</span>
        <br />
      </div>
    </div>
  );
}
