import React, { useEffect, useState } from "react";

import Done from "@material-ui/icons/Done";
import editIcon from "../../../assets/pencil-fill.svg";
import { EDIT_ACCOUNT_SETTINGS } from "../../../actions";
import { useDispatch } from "react-redux";
import PhotoChange from "../../shared/PhotoChange";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";

type props = {
  userData?: any;
};

export default function PersonalInfo({ userData }: props) {
  const [firstName, setFirstName] = useState(userData?.firstName);
  const [changingFirstName, setChangingFirstName] = useState(false);

  const [lastName, setLastName] = useState(userData?.lastName);
  const [changingLastName, setChangingLastName] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("Add phone");
  const [changingPhoneNumber, setChangingPhoneNumber] = useState(false);

  const [displayName, setDisplayName] = useState(userData?.displayName);
  const [changingDisplayName, setChangingDisplayName] = useState(false);

  const emptyImage: File = new File([], "");
  const [avatar, setAvatar] = useState(emptyImage);

  const dispatch = useDispatch();

  useEffect(() => {
    setFirstName(userData?.firstName);
    setLastName(userData?.lastName);
    setDisplayName(userData?.displayName);
    if (userData?.phoneNumber === undefined) {
      setPhoneNumber("Add phone");
    } else {
      userData?.phoneNumber === " "
        ? setPhoneNumber("Add phone")
        : setPhoneNumber(userData?.phoneNumber);
    }
  }, [userData]);

  return (
    <div className="personal-container">
      <span className="personal-title">PERSONAL INFO</span>
      <div className="personal-data">
        <div className="personal-field">
          <div className="row">
            <div className="title-container">
              <span className="personal-field-title">First Name</span>
            </div>
            {changingFirstName ? (
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            ) : (
              <span className="personal-field-data">{firstName}</span>
            )}
          </div>
          {changingFirstName ? (
            <Done
              className="edit-icon"
              style={{ width: 18, height: 18 }}
              onClick={() => {
                dispatch({
                  type: EDIT_ACCOUNT_SETTINGS,
                  value: { changedField: "firstName", firstName },
                });
                setChangingFirstName(false);
              }}
            />
          ) : (
            <img
              className="edit-icon"
              src={editIcon}
              width={18}
              height={18}
              onClick={() => {
                setChangingFirstName(true);
              }}
            />
          )}
        </div>
        <div className="personal-field">
          <div className="row">
            <div className="title-container">
              <span className="personal-field-title">Surname</span>
            </div>
            {changingLastName ? (
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            ) : (
              <span className="personal-field-data">{lastName}</span>
            )}
          </div>
          {changingLastName ? (
            <Done
              className="edit-icon"
              style={{ width: 18, height: 18 }}
              onClick={() => {
                dispatch({
                  type: EDIT_ACCOUNT_SETTINGS,
                  value: { changedField: "lastName", lastName },
                });
                setChangingLastName(false);
              }}
            />
          ) : (
            <img
              className="edit-icon"
              src={editIcon}
              width={18}
              height={18}
              onClick={() => {
                setChangingLastName(true);
              }}
            />
          )}
        </div>
        <div className="personal-field">
          <div className="row">
            <div className="title-container">
              <span className="personal-field-title">Contact email</span>
            </div>
            <span className="personal-field-data">{userData?.email}</span>
          </div>
        </div>
        <div className="personal-field">
          <div className="row">
            <div className="title-container">
              <span className="personal-field-title">Contact phone</span>
            </div>
            <div className="column">
              {changingPhoneNumber ? (
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              ) : (
                <span
                  className={
                    phoneNumber === "Add phone"
                      ? "placeholder-style"
                      : "personal-field-data"
                  }
                >
                  {phoneNumber}
                </span>
              )}
              <span className="description">
                Used for security and account retrieval.
              </span>
            </div>
          </div>
          {changingPhoneNumber ? (
            <Done
              className="edit-icon"
              style={{ width: 18, height: 18 }}
              onClick={() => {
                dispatch({
                  type: EDIT_ACCOUNT_SETTINGS,
                  value: {
                    changedField: "phoneNumber",
                    phoneNumber: phoneNumber.toString(),
                    phoneType: "mobile",
                  },
                });
                phoneNumber === "" && setPhoneNumber("Add phone");
                setChangingPhoneNumber(false);
              }}
            />
          ) : (
            <img
              className="edit-icon"
              src={editIcon}
              width={18}
              height={18}
              onClick={() => {
                if (phoneNumber === "Add phone") {
                  setPhoneNumber("");
                }
                setChangingPhoneNumber(true);
              }}
            />
          )}
        </div>
        <div className="personal-field" style={{ marginTop: -11 }}>
          <div className="row">
            <div className="title-container">
              <span className="personal-field-title">Display name</span>
            </div>
            <div className="column">
              {changingDisplayName ? (
                <input
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              ) : (
                <span className="personal-field-data">{displayName}</span>
              )}
              <span className="description">
                This may be used later to create your personal Orbit URL. ie.
                www.orbitcontacts.com/YourUserName
              </span>
            </div>
          </div>
          {changingDisplayName ? (
            <Done
              className="edit-icon"
              style={{ width: 18, height: 18 }}
              onClick={() => {
                dispatch({
                  type: EDIT_ACCOUNT_SETTINGS,
                  value: {
                    changedField: "displayName",
                    displayName,
                  },
                });
                setChangingDisplayName(false);
              }}
            />
          ) : (
            <img
              className="edit-icon"
              src={editIcon}
              width={18}
              height={18}
              onClick={() => {
                setChangingDisplayName(true);
              }}
            />
          )}
        </div>
      </div>
      <div className="profile-picture-container">
        <div className="form-column">
          <PhotoChange
            photoFile={avatar?.size > 0 ? avatar : undefined}
            onChange={(img: File) => {
              setAvatar(img);
            }}
            photoURL={
              userData?.pictureUrl ? userData?.pictureUrl : avatarPlaceholder
            }
          />
        </div>
      </div>
    </div>
  );
}
