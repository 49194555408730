import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Close from "@material-ui/icons/Close";
import { SECONDARY } from "../../../Constants/colorsPalette";
import { RootState } from "../../../Constants/types";
import {
  CHANGE_GROUPING,
  CLEAR_ALL_FILTERS,
  SET_CHANGE_IN_CONTACTS_LIST,
} from "../../../actions";

export default function GroupingOptions() {
  const groupingOption = useSelector(
    (state: RootState) => state.filtersAndLists.groupingOption
  );
  const filtersOn = useSelector(
    (state: RootState) =>
      state.filtersAndLists.selectedLists.length > 0 ||
      state.filtersAndLists.selectedProperties.length > 0
  );
  const dispatch = useDispatch();
  const setOption = (groupingOption: "Show All" | "Intersection") => {
    dispatch({ type: CHANGE_GROUPING, value: groupingOption });
  };
  const clearAll = () => {
    dispatch({ type: CLEAR_ALL_FILTERS });
    dispatch({ type: SET_CHANGE_IN_CONTACTS_LIST });
  };

  return (
    <div className={"group-options fixed-row" + (filtersOn ? "" : " disabled")}>
      <AllSelections
        onClick={
          filtersOn
            ? () => {
                setOption("Show All");
                dispatch({ type: SET_CHANGE_IN_CONTACTS_LIST });
              }
            : undefined
        }
        fill={groupingOption === "Show All" ? SECONDARY : "#CBC9CC"}
      />
      <Intersection
        onClick={
          filtersOn
            ? () => {
                setOption("Intersection");
                dispatch({ type: SET_CHANGE_IN_CONTACTS_LIST });
              }
            : undefined
        }
        fill={groupingOption === "Intersection" ? SECONDARY : "#CBC9CC"}
      />
      <Clear onClick={clearAll} />
    </div>
  );
}

const AllSelections = ({
  fill,
  onClick,
}: {
  fill: string;
  onClick?: () => void;
}) => (
  <div className="column">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 30"
      width="36px"
      height="24px"
      onClick={onClick}
    >
      <g fill={fill} fillRule="evenodd" clipRule="evenodd">
        <path d="M22.5 2a15 15 0 000 26 15 15 0 110-26zM22.5 2a15 15 0 010 26 15 15 0 100-26z" />
        <path d="M22.5 26.8a14 14 0 000-23.6 14 14 0 000 23.6z" />
      </g>
    </svg>
    <span style={{ color: fill }}>SEE ALL</span>
  </div>
);

const Intersection = ({
  fill,
  onClick,
}: {
  fill: string;
  onClick?: () => void;
}) => (
  <div className="column" onClick={onClick}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 45 30"
      width="36px"
      height="24px"
    >
      <g fill={fill} fillRule="evenodd" clipRule="evenodd">
        <path d="M15 29a14 14 0 100-28 14 14 0 000 28zm0 1a15 15 0 100-30 15 15 0 000 30z" />
        <path d="M30 29a14 14 0 100-28 14 14 0 000 28zm0 1a15 15 0 100-30 15 15 0 000 30z" />
        <path d="M23 27.6a15 15 0 01-1-25 15 15 0 011 25z" />
      </g>
    </svg>
    <span style={{ color: fill }}>OVERLAP</span>
  </div>
);

const Clear = ({ onClick }: { onClick: () => void }) => (
  <div className="column">
    <button onClick={onClick} className="clear-btn centered">
      <Close />
    </button>
    <span>CLEAR</span>
  </div>
);
