import React, { CSSProperties, useState } from "react";
import { PRIMARY_60 } from "../../../Constants/colorsPalette";
import RaisedButton from "../RaisedButton";
import "./SelectButtons.css";

type props = {
  options: { label: string; value: any }[];
  onValueChange: Function;
};

export default function SelectButtons({ options, onValueChange }: props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const customStyles: CSSProperties = {
    maxWidth: "222px",
    margin: "0px 5px",
  };
  const selectedStyle: CSSProperties = {
    ...customStyles,
    backgroundColor: PRIMARY_60,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div className="select-buttons-container row centered">
      {options.map(({ label, value }, i) => (
        <RaisedButton
          key={"select-" + label}
          text={label}
          onClick={() => {
            setSelectedIndex(i);
            onValueChange(value);
          }}
          style={selectedIndex === i ? selectedStyle : customStyles}
        />
      ))}
    </div>
  );
}
