import React, {
  forwardRef,
  RefObject,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import {
  ADD_LIST,
  EDIT_COLLECTION_NAME,
  EDIT_LIST_NAME,
} from "../../../actions";

type props = {
  valueType: string;
  title?: string;
  headerId?: string;
  defaultValue: string;
  placeholder?: string;
  onKeyPress?:
    | ((event: React.KeyboardEvent<HTMLInputElement>) => void)
    | undefined;
};

const ValueInput = forwardRef(
  ({ valueType, title, headerId, defaultValue, placeholder }: props, ref) => {
    const [value, setValue] = useState(defaultValue);
    const dispatch = useDispatch();

    const operate = (valueType: string) => {
      switch (valueType) {
        case "group":
          defaultValue !== value &&
            dispatch({
              type: EDIT_COLLECTION_NAME,
              value: { newListHeader: value, listId: headerId },
            });
          break;
        case "list":
          defaultValue !== value.toLowerCase() &&
            dispatch({
              type: EDIT_LIST_NAME,
              value: {
                oldTag: defaultValue,
                newTag: value.toLowerCase(),
                listId: headerId,
              },
            });
          break;
        case "new-list":
          dispatch({
            type: ADD_LIST,
            value: {
              listHeader: title,
              tag: value,
            },
          });
          break;
        default:
          break;
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        operate(valueType);
      },
    }));

    return (
      <input
        className="adding-input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            operate(valueType);
          }
        }}
      />
    );
  }
);

export default ValueInput;
