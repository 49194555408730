import { Contact, Orbit } from "../Constants/types";

export function timeSince(date: Date) {
  var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

export function filterContacts(
  contacts: Contact[],
  groupingOption: "Show All" | "Intersection" | "none",
  selectedOrbits: number[],
  selectedLists: string[],
  selectedProperties: string[]
) {
  if (
    selectedLists.length === 0 &&
    selectedOrbits.length === 0 &&
    selectedProperties.length === 0
  ) {
    return contacts;
  } else {
    var missingPropsArray: Contact[] = [];
    for (let i = 0; i < contacts.length; i++) {
      for (let j = 0; j < selectedProperties.length; j++) {
        let flag = false;
        switch (selectedProperties[j]) {
          case "Orbit":
            contacts[i].orbitNumber === 6 ? (flag = true) : (flag = false);
            break;
          case "List":
            contacts[i].lists.length === 0 ? (flag = true) : (flag = false);
            break;
          case "Name":
            contacts[i].surname === "" ? (flag = true) : (flag = false);
            break;
          case "Email":
            contacts[i].email === "" ? (flag = true) : (flag = false);
            break;
          case "Phone":
            contacts[i].phoneNumbers.length > 0
              ? (flag = false)
              : (flag = true);
            break;
        }
        if (flag) {
          missingPropsArray.push(contacts[i]);
          break;
        }
      }
    }
    if (groupingOption === "Show All") {
      const firstArray = contacts.filter(
        (contact) =>
          selectedOrbits.includes(contact.orbitNumber) ||
          contact.lists.filter((list) => selectedLists.includes(list)).length >
            0
      );
      const mergedArray = [...firstArray, ...missingPropsArray];
      let set = new Set();
      let unionArray = mergedArray.filter((contact) => {
        if (!set.has(contact.id)) {
          set.add(contact.id);
          return true;
        }
        return false;
      }, set);
      return unionArray;
    } else {
      const unionOfSelectedOrbits =
        selectedOrbits.length > 0
          ? contacts.filter((contact) =>
              selectedOrbits.includes(contact.orbitNumber)
            )
          : contacts;

      if (groupingOption === "Intersection") {
        const orbitsListsIntersection = unionOfSelectedOrbits.filter(
          (contact) =>
            contact.lists.filter((list) => selectedLists.includes(list))
              .length === selectedLists.length
        );

        if (missingPropsArray.length !== 0) {
          const fullIntersection = orbitsListsIntersection.filter((item1) =>
            missingPropsArray.some((item2) => item1.id === item2.id)
          );

          return fullIntersection;
        } else {
          return orbitsListsIntersection;
        }
      } else {
        return unionOfSelectedOrbits;
      }
    }
  }
}
