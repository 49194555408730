import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHANGE_USER_PASSWORD,
  CHANGE_USER_PASSWORD_FINISH,
  CHANGE_USER_PASSWORD_START,
} from "../../../actions";
import { PRIMARY } from "../../../Constants/colorsPalette";
import { RootState } from "../../../Constants/types";
import Loader from "react-loader-spinner";
import OrbitButton from "../../shared/OrbitButton";
import { IconButton } from "@material-ui/core";
import ToggleIcon from "material-ui-toggle-icon";
import { Visibility, VisibilityOff, Close } from "@material-ui/icons";
import alertIcon from "../../../assets/alert-circle.svg";
import Done from "@material-ui/icons/Done";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [confirmedPasswordVisible, setConfirmedPasswordVisible] = useState(
    false
  );

  const [disabled, setDisabled] = useState(true);

  const changePasswordError = useSelector(
    (state: RootState) => state.auth.changePasswordError
  );
  const changePasswordProcessing = useSelector(
    (state: RootState) => state.auth.changePasswordProcessing
  );

  const dispatch = useDispatch();

  const isPasswordInvalid = (password: string) => {
    return password && !password.match(/^(?=.*[a-z])(?=.*[0-9]).{8,}$/)
      ? true
      : false;
  };

  useEffect(() => {
    setDisabled(
      !(
        !isPasswordInvalid(newPassword) &&
        !isPasswordInvalid(confirmedPassword) &&
        oldPassword !== "" &&
        newPassword !== "" &&
        confirmedPassword !== ""
      )
    );
  }, [newPassword, confirmedPassword]);

  return (
    <div className="change-password-container">
      <span className="change-password-title">CHANGE PASSWORD</span>
      <div className="change-password-data">
        <div className="change-password-data-field">
          <span className="change-password-data-field-title">
            Current password
          </span>
          <div className="password-container">
            <div className="password-input">
              <input
                className={!oldPasswordVisible ? "invisible" : ""}
                type={!oldPasswordVisible ? "Password" : "text"}
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
              <IconButton
                onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
                style={{ height: 16 }}
              >
                <ToggleIcon
                  on={oldPasswordVisible}
                  onIcon={<VisibilityOff />}
                  offIcon={<Visibility />}
                />
              </IconButton>
            </div>
          </div>
        </div>
        <div className="change-password-data-field">
          <span className="change-password-data-field-title">New password</span>
          <div className="password-container">
            <div className="password-input">
              <input
                className={!newPasswordVisible ? "invisible" : ""}
                type={!newPasswordVisible ? "Password" : "text"}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              <IconButton
                onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                style={{ height: 16 }}
              >
                <ToggleIcon
                  on={newPasswordVisible}
                  onIcon={<VisibilityOff />}
                  offIcon={<Visibility />}
                />
              </IconButton>
            </div>
            {isPasswordInvalid(newPassword) ? (
              <div className="row">
                <img
                  src={alertIcon}
                  width={13}
                  height={13}
                  style={{ marginLeft: 3 }}
                />
                <span className="warning-message">
                  Password does not meet requirements.
                </span>
              </div>
            ) : (
              <div>
                {newPassword !== "" ? (
                  <div className="row">
                    <Done />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className="change-password-data-field">
          <span className="change-password-data-field-title">
            Re-enter new password
          </span>
          <div className="password-container">
            <div className="password-input">
              <input
                className={!confirmedPasswordVisible ? "invisible" : ""}
                type={!confirmedPasswordVisible ? "Password" : "text"}
                value={confirmedPassword}
                onChange={(e) => {
                  setConfirmedPassword(e.target.value);
                }}
              />
              <IconButton
                onClick={() =>
                  setConfirmedPasswordVisible(!confirmedPasswordVisible)
                }
                style={{ height: 16 }}
              >
                <ToggleIcon
                  on={confirmedPasswordVisible}
                  onIcon={<VisibilityOff />}
                  offIcon={<Visibility />}
                />
              </IconButton>
            </div>
            {isPasswordInvalid(confirmedPassword) ? (
              <div className="row">
                <img
                  src={alertIcon}
                  width={13}
                  height={13}
                  style={{ marginLeft: 3 }}
                />
                <span className="warning-message">
                  Password does not meet requirements.
                </span>
              </div>
            ) : (
              <div>
                {newPassword === confirmedPassword &&
                newPassword !== "" &&
                confirmedPassword !== "" ? (
                  <div className="row">
                    <Done />
                    <span className="check-message centered">
                      Passwords match
                    </span>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        {changePasswordProcessing ? (
          <div className="centered">
            <Loader type="TailSpin" color={PRIMARY} height={50} width={50} />
          </div>
        ) : (
          <div className="column">
            <span className="description-message">
              Minimum 8 characters. Use letters & numbers.
            </span>
            {changePasswordError !== "" ? (
              <span className="error-message">{changePasswordError}</span>
            ) : null}
            <div className="change-password-data-field">
              <OrbitButton
                text="SET NEW PASSWORD"
                disabled={disabled}
                dark={!disabled}
                dark60={disabled}
                style={{
                  maxWidth: "100%",
                  width: 220,
                  height: 36,
                }}
                onClick={() => {
                  dispatch({ type: CHANGE_USER_PASSWORD_START });
                  dispatch({
                    type: CHANGE_USER_PASSWORD,
                    value: {
                      oldPassword,
                      newPassword,
                      confirmedPassword,
                    },
                  });
                  dispatch({ type: CHANGE_USER_PASSWORD_FINISH });
                  setOldPassword("");
                  setNewPassword("");
                  setConfirmedPassword("");
                }}
              />
              <OrbitButton
                text="CANCEL"
                white
                style={{
                  maxWidth: "100%",
                  width: 70,
                  height: 36,
                  marginLeft: 40,
                  marginRight: 225,
                }}
                onClick={() => {
                  setOldPassword("");
                  setNewPassword("");
                  setConfirmedPassword("");
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
