// import firebase from "./firebase";
import React, { useEffect } from "react";

// const db = firebase.firestore();
// const TestUserCollection = db.collection("testUsers");

function FirebaseApp() {
  return <></>;
}

export default FirebaseApp;
