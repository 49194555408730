import React, { useState } from "react";

import {
  PRIMARY_60,
  SUCCESS,
  WARM_GRAY,
} from "../../../Constants/colorsPalette";
import RaisedButton from "../RaisedButton";
import ModalWindow from "../Modal";
import AvatarEditor from "./AvatarEditor";
import FlatButton from "../FlatButton";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import "./PhotoUpload.css";

type props = {
  onChange: Function;
  photoFile?: File;
};

export default function PhotoUpload({ onChange, photoFile }: props) {
  const [skipped, setSkipped] = useState(false);

  if (photoFile) {
    var imgFile = new File([photoFile], "placeHolder");
    var fileReader = new FileReader();
    fileReader.readAsDataURL(imgFile);

    fileReader.onload = function (oFREvent) {
      if (document && document.getElementById("preview")) {
        var preview: any = document.getElementById("preview");
        preview.src = oFREvent?.target?.result;
      }
    };
  }

  return (
    <div className="photo-upload-container column centered">
      <div className="avatar-holder">
        <img
          id="preview"
          width="200px"
          height="200px"
          style={{
            borderRadius: "50%",
            backgroundColor: WARM_GRAY,
          }}
          src={avatarPlaceholder}
        />
      </div>
      <br />
      <ModalWindow
        title="Upload Photo"
        Content={({ closeModal }) => AvatarEditor(onChange, closeModal)}
        Trigger={(onClick: (event: React.MouseEvent) => void) => (
          <RaisedButton
            text={skipped ? "" : "Upload photo"}
            style={{
              backgroundColor: PRIMARY_60,
              color: WARM_GRAY,
              maxWidth: "200px",
              margin: "0px auto",
            }}
            disabled={skipped}
            onClick={onClick}
            icon={() => (skipped ? <CheckMarkIcon /> : <></>)}
          />
        )}
      />
      {!(skipped || photoFile) && (
        <FlatButton
          style={{ color: PRIMARY_60, fontSize: "12px", marginTop: "5px" }}
          text="Skip for now"
          onClick={() => setSkipped(true)}
        />
      )}
    </div>
  );
}

const CheckMarkIcon = () => (
  <>
    <div className="animation-ctn">
      <div className="success-icon success-icon--order-success svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 154 154"
          width="25px"
          height="25px"
        >
          <g fill="none" stroke={SUCCESS} strokeWidth="2">
            <circle
              cx="77"
              cy="77"
              r="72"
              style={{
                strokeDasharray: "480px, 480px",
                strokeDashoffset: "960px",
              }}
            ></circle>
            <circle
              id="colored"
              fill={SUCCESS}
              cx="77"
              cy="77"
              r="72"
              style={{
                strokeDasharray: "480px, 480px",
                strokeDashoffset: "960px",
              }}
            ></circle>
            <polyline
              className="st0"
              stroke="#fff"
              strokeWidth="10"
              points="43.5,77.8 63.7,97.9 112.2,49.4 "
              style={{
                strokeDasharray: "100px, 100px",
                strokeDashoffset: "200px",
              }}
            />
          </g>
        </svg>
      </div>
    </div>
    &nbsp;&nbsp;
  </>
);
