import React, { Component } from "react";

import SideBar from "./Sidebar";
import Slides from "./Slides";

class WelcomePage extends Component {
  state = {
    selectedId: 0,
  };

  setSelectedId = (newID: number) => {
    if (newID !== this.state.selectedId) {
      var slide = document.getElementById(`slide${newID}`)?.offsetTop;
      setTimeout(
        () =>
          window.scrollTo({
            top: slide,
            behavior: "smooth",
          }),
        1
      );

      this.setState({ selectedId: newID });
    }
  };

  render() {
    return (
      <div className="outer-wrapper">
        <div className="inner-wrapper row">
          <div className="max-size">
            <div className="column fixed-left-side" style={{ width: "20%" }}>
              <SideBar
                selectedId={this.state.selectedId}
                setSelectedId={this.setSelectedId}
              />
            </div>
            <div className="main-content-wrapper" style={{ width: "80%" }}>
              <div className="column">
                <Slides
                  selectedId={this.state.selectedId}
                  setSelectedId={this.setSelectedId}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WelcomePage;
