import React, { useRef } from "react";

import handleViewport from "react-in-viewport";

type props = {
  Content: Function;
  setSelectedId: Function;
  id: string;
};

export default function Slide({ Content, setSelectedId, id }: props) {
  const ViewportBlock = handleViewport(Content, {
    rootMargin: "0px",
    threshold: 0.6,
  });
  return (
    <div style={{ width: "100%", height: "100vh" }} id={id}>
      <ViewportBlock onEnterViewport={() => setSelectedId()} />
    </div>
  );
}
