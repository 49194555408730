import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MOVE_CONTACT_TO_ORBIT,
  SET_CHANGE_IN_CONTACTS_LIST,
} from "../../../actions";
import Loader from "react-loader-spinner";

import { Contact, RootState } from "../../../Constants/types";

type props = {
  contact: Contact;
  colSpan: number;
};

export default function OrbitNumber({ contact, colSpan }: props) {
  const changeInContactsAttributes = useSelector(
    (state: RootState) => state.contacts.changeInContactsAttributes
  );

  const [currentValue, updateCurrentValue] = useState(contact.orbitNumber);

  const [initialValue, setInitialValue] = useState(contact.orbitNumber);
  const [isFull, setIsFull] = useState(false);
  const orbits = useSelector(
    (state: RootState) => state.filtersAndLists.orbits
  );

  const dispatch = useDispatch();

  useEffect(() => {
    updateCurrentValue(contact.orbitNumber);
    setInitialValue(contact.orbitNumber);
  }, [contact]);

  useEffect(() => {
    const currentOrbit = orbits[currentValue - 1];
    if (currentOrbit) {
      setIsFull(currentOrbit.current_capacity >= currentOrbit.max_capacity);
    }
  }, [orbits, currentValue, contact]);

  const incrementOrbit = (value: number) => {
    switch (value) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        updateCurrentValue(value + 1);
        if (
          orbits[value].current_capacity < orbits[value].max_capacity ||
          value === 5
        ) {
          dispatch({
            type: MOVE_CONTACT_TO_ORBIT,
            value: {
              contactId: contact.id,
              newOrbitNumber: value + 1,
              currentOrbitNumber: initialValue,
            },
          });
          setInitialValue(value + 1);
        } else {
          setTimeout(() => incrementOrbit(value + 1), 1000);
        }
        break;
      case 6:
        updateCurrentValue(1);
        if (orbits[0].current_capacity < orbits[0].max_capacity) {
          dispatch({
            type: MOVE_CONTACT_TO_ORBIT,
            value: {
              contactId: contact.id,
              newOrbitNumber: 1,
              currentOrbitNumber: 6,
            },
          });
          setInitialValue(1);
        } else {
          setTimeout(() => incrementOrbit(1), 1000);
        }
        break;
    }
  };

  return (
    <td colSpan={colSpan}>
      <div
        className={"orbit-number-border centered" + (isFull ? " is-full" : "")}
      >
        {currentValue === 6 ? (
          <div
            className="orbit-number-beyond centered"
            onClick={() => incrementOrbit(currentValue)}
          >
            &
          </div>
        ) : (
          <div
            className="orbit-number centered"
            onClick={() => incrementOrbit(currentValue)}
          >
            {currentValue}
          </div>
        )}
      </div>
    </td>
  );
}
