import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { SIGNUP } from "../../../actions";
import { RootState } from "../../../Constants/types";
import { PRIMARY } from "../../../Constants/colorsPalette";
import RaisedButton from "../../shared/RaisedButton";
import InputField, { PasswordInput } from "../../shared/InputField";
import PhotoUpload from "../../shared/PhotoUpload";

export default function Details() {
  const processing = useSelector((state: RootState) => state.auth.processing);
  const signupError = useSelector((state: RootState) => state.auth.signupError);
  const dispatch = useDispatch();
  const history = useHistory();

  const emptyImage: File = new File([], "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(emptyImage);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch({
      type: SIGNUP,
      value: {
        userData: { firstName, lastName, email, username, password, avatar },
        history,
      },
    });
  };

  useEffect(() => {
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      password.length >= 8 &&
      email.length > 0 &&
      email.match(/^.+@.+\.com$/)
    )
      setIsReadyToSubmit(true);
    else isReadyToSubmit && setIsReadyToSubmit(false);
  }, [firstName, lastName, password, email]);

  return (
    <div className="signup-form-container column">
      <div
        className="signup-title column"
        style={{ width: "100%", flexGrow: 1 }}
      >
        <span className="title">Account Details</span>
        <hr
          style={{
            width: "60%",
            height: "0px",
            backgroundColor: `${PRIMARY}`,
          }}
        />
        <br />
      </div>
      <form className="signup-form column" onSubmit={submitForm}>
        <div className="row centered">
          <div className="form-column">
            <label id="name">Your Name</label>
            <InputField
              id="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              aria-labelledby="name"
            />
            <InputField
              id="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              aria-labelledby="name"
            />
            <InputField
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-labelledby="contactDetails"
              invalid={email && !email.match(/^.+@.+\.com$/) ? true : false}
              errorMessage="Please enter a valid email address. "
            />
            <br />
            <label id="loginDetails">Your Login Details</label>
            <InputField
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              aria-labelledby="loginDetails"
            />
            <PasswordInput
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              aria-labelledby="loginDetails"
              pattern="^(?=.*[a-z])(?=.*[0-9]).{8,}$"
              invalid={
                password && !password.match(/^(?=.*[a-z])(?=.*[0-9]).{8,}$/)
                  ? true
                  : false
              }
              errorMessage="Password does not meet requirements."
              note="Minimum 8 characters. Use letters & numbers."
            />
          </div>
          <div className="form-column">
            <PhotoUpload
              photoFile={avatar?.size > 0 ? avatar : undefined}
              onChange={(img: File) => {
                setAvatar(img);
              }}
            />
          </div>
        </div>
        {signupError !== "" ? (
          <label id="name" style={{ color: "red" }}>
            {signupError}
          </label>
        ) : null}
        <br />
        <RaisedButton
          type="submit"
          text="CREATE YOUR ACCOUNT"
          style={{ maxWidth: "285px" }}
          disabled={!isReadyToSubmit}
          loading={processing}
          dark
        />
      </form>
    </div>
  );
}
