import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../actions";

import logo from "../../assets/OrbitLogo_Purple.png";
import { RootState } from "../../Constants/types";
import FlatButton from "../shared/FlatButton";
import InputField, { PasswordInput } from "../shared/InputField";
import RaisedButton from "../shared/RaisedButton";
import "./Login.css";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [isReady, setIsReady] = useState(false);
  const processing = useSelector((state: RootState) => state.auth.processing);
  const loginError = useSelector((state: RootState) => state.auth.loginError);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (password.length > 0 && username.length > 0) setIsReady(true);
    else isReady && setIsReady(false);
  }, [username, password]);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({
      type: LOGIN,
      value: {
        userData: {
          username,
          password,
        },
        history,
      },
    });
  };

  return (
    <div className="max-size column centered">
      <div className="login-header row">
        <span className="fixed-row">
          Don’t have an account?
          <FlatButton
            text="SIGN UP"
            to="/welcome"
            style={{ marginLeft: "5px", fontWeight: 700 }}
          />
          .
        </span>
      </div>
      <div className="login-container column centered">
        <img src={logo} id="login-logo" />
        <form className="login-form column" onSubmit={submitForm}>
          <label id="loginDetails">Your Login Details</label>
          <InputField
            id="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            aria-labelledby="loginDetails"
          />
          <PasswordInput
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            aria-labelledby="loginDetails"
          />
          <FlatButton
            text="Forgot your password? "
            style={{ fontSize: "10px" }}
            to="/ForgotYourPassword"
          />
          <br />
          {loginError !== "" ? (
            <label id="loginDetails" style={{ color: "red" }}>
              {loginError}
            </label>
          ) : null}
          <br />
          <RaisedButton
            type="submit"
            text="LOGIN"
            style={{ maxWidth: "285px" }}
            disabled={!isReady}
            loading={processing}
            dark
          />
        </form>
        <br />
        <br />
        <br />
        <br />
        <div className="login-footer">
          <FlatButton text="Privacy Policy" to="/privacy" />
        </div>
      </div>
    </div>
  );
}
