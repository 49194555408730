import React, { useState } from "react";
import firebase from "../config/firebase";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { RootState } from "../Constants/types";
import Signup from "./Signup";

export function AuthenticatedRoute(routeProps: any) {
  const user: any = firebase.auth().currentUser;
  const { signedinithGoogle, hasSubscribed } = useSelector(
    (state: RootState) => state.auth
  );

  const {
    component: Component,
    withKey: withKey,
    render: render,
    ...props
  } = routeProps;

  return (
    <Route
      {...props}
      render={(props) =>
        user ? (
          signedinithGoogle ? (
            hasSubscribed ? (
              render ? (
                render()
              ) : (
                <Component
                  {...props}
                  key={withKey ? props.match.params[withKey] : null}
                />
              )
            ) : (
              <Redirect to="/signup/subscribe" />
            )
          ) : (
            <Redirect to="/signup/googleSignin" />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export function SignupStepRoute(routeProps: any) {
  const user: any = firebase.auth().currentUser;
  const { signedinithGoogle, hasSubscribed } = useSelector(
    (state: RootState) => state.auth
  );

  return (
    <Route
      path="/signup/:step"
      render={(props) => {
        const step = props.match.params.step;

        switch (step) {
          case "details":
            return user ? (
              <Redirect to="/signup/googleSignin" />
            ) : (
              <Signup key={step} {...props} />
            );
          case "googleSignin":
            return user ? (
              signedinithGoogle ? (
                <Redirect to={"/signup/subscribe"} />
              ) : (
                <Signup key={step} {...props} />
              )
            ) : (
              <Redirect to="/signup/details" />
            );
          case "subscribe":
            return user ? (
              signedinithGoogle ? (
                hasSubscribed ? (
                  <Redirect to="/my-orbits" />
                ) : (
                  <Signup key={step} {...props} />
                )
              ) : (
                <Redirect to="/signup/googleSignin" />
              )
            ) : (
              <Redirect to="/signup/details" />
            );
        }
        return <Signup key={step} {...props} />;
      }}
    />
  );
}

// export function ProtectedRoute(routeProps: any) {
//   const isVerified = useSelector(
//     (state: RootState) => state.testUser.isVerified
//   );
//   const { component: Component, withKey: withKey, ...props } = routeProps;

//   return (
//     <Route
//       {...props}
//       render={(props) =>
//         isVerified ? (
//           <Component
//             {...props}
//             key={withKey ? props.match.params[withKey] : null}
//           />
//         ) : (
//           <Redirect to="/" />
//         )
//       }
//     />
//   );
// }
