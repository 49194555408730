export const WHITE = "#FFFFFF";
export const PRIMARY = "rgba(45,38,51,1)";
export const PRIMARY_60 = "rgba(45,38,51,0.6)";
export const SECONDARY = "rgba(52,15,82,1)";
export const SECONDARY_50 = "rgba(52,15,82,0.5)";
export const WARM_GRAY = " #f2f0f0";
export const TEAL_BLUE = "#0B3C49";
export const DUSK_BLUE = "#587C9C";
export const DUSK_BLUE_25 = "#587C9C25";
export const BURNT_ROSE = "#A2666F";
export const BURNT_ROSE_25 = "#A2666F25";
export const BRIGHT_ROSE = "#D85F71";
export const SNOW = "#f4f4f4";
export const NICKEL = "#666666";
export const COAL = "#333333";
export const MOON = "#999999";
export const SILVER = "#cccccc";
export const SUCCESS = "#2ecc71";
export const WARNING = "#f1c40f";
export const FAILURE = "#e74c3c";
