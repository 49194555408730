import React, { CSSProperties, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Done from "@material-ui/icons/Done";
import {
  ADD_COLLECTION,
  ADD_LIST,
  MODIFY_EXISTING_CONTACT,
  SHOW_CONTACT,
  UNSHOW_ADD_CONTACT,
} from "../../../actions";
import firebase from "firebase";
import { Contact, RootState } from "../../../Constants/types";

type props = {
  attributeChanged: string;
  collectionEdited?: string;
  data: string;
  colSpan?: number;
  isEditable: boolean;
  disabled: boolean;
  style?: CSSProperties;
  className?: string;
  contact?: Contact;
  onDoneClick: () => void;
};

export default function EditableCell({
  attributeChanged,
  collectionEdited,
  data,
  colSpan,
  isEditable,
  disabled,
  style,
  contact,
  onDoneClick,
}: props) {
  const [isDisabled, setDisabled] = useState(disabled);
  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  const contacts = useSelector(
    (state: RootState) => state.contacts.contactsList
  );

  const showContact = () => {
    dispatch({ type: UNSHOW_ADD_CONTACT });
    dispatch({
      type: SHOW_CONTACT,
      value: contacts[contacts.findIndex((item) => item.id === contact?.id)],
    });
  };

  const dispatch = useDispatch();
  return (
    <td
      className="editable-cell "
      onDoubleClick={() => isEditable && isDisabled && setDisabled(false)}
      onClick={() => (attributeChanged === "givenName" ? showContact() : null)}
      colSpan={colSpan}
    >
      <label className="row">
        <div className="editable-input-container">
          <input
            type="text"
            value={value}
            disabled={isDisabled}
            onChange={(e) => setValue(e.target.value)}
            style={{ ...style, width: "100%" }}
          />
        </div>
        {!isDisabled && (
          <div className="done-icon centered">
            <Done
              style={{ width: "20px" }}
              onClick={() => {
                setDisabled(true);
                onDoneClick();
                switch (attributeChanged) {
                  // case "collectionOfLists":
                  //   dispatch({
                  //     type: ADD_COLLECTION,
                  //     value: {
                  //       listHeader: value,
                  //     },
                  //   });
                  //   break;
                  // case "List":
                  //   dispatch({
                  //     type: ADD_LIST,
                  //     value: {
                  //       listHeader: collectionEdited,
                  //       tag: value,
                  //     },
                  //   });
                  //   setValue("Add list");
                  //   break;
                  case "givenName":
                  case "surname":
                  case "phoneNumbers":
                  case "email":
                    dispatch({
                      type: MODIFY_EXISTING_CONTACT,
                      value: {
                        attributeChanged,
                        newValue: value,
                        contactId: contact?.id,
                      },
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
            {/* TODO: onclick sends change contact data action*/}
          </div>
        )}
      </label>
    </td>
  );
}
