import {
  VERIFY,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  CHECK_EXISTING_TEST_USER,
} from "../actions";
import { put, takeLeading, call, fork } from "redux-saga/effects";
import testUserFunctions from "../components/firebase/testUser";
import { Action } from "../Constants/types";

function* verify(action: Action) {
  try {
    // const authUser = Object.keys(window.localStorage).filter((item) =>
    //   item.startsWith("firebase:authUser")
    // );
    // console.log(authUser);

    if (action.value) {
      const user = yield call(
        testUserFunctions.getTestUser,
        action.value.password
      );

      yield call(
        (name: string, value: string) => sessionStorage.setItem(name, value),
        "testUser",
        JSON.stringify(user)
      );

      yield put({ type: VERIFY_SUCCESS, value: user });
      action.value.history.push("/welcome");
    } else {
      yield put({ type: CHECK_EXISTING_TEST_USER });

      const user = JSON.parse(
        yield call((name: string) => sessionStorage.getItem(name), "testUser")
      );
      if (user) yield put({ type: VERIFY_SUCCESS, value: user });
      else throw new Error("No existing test user!");
    }
  } catch (error) {
    yield put({ type: VERIFY_ERROR, value: error });
  }
}

export default function* testUserSaga() {
  yield takeLeading(VERIFY, verify);
}
