import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideNine({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Bicycle of the Mind</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          21st century Rolodex as bicycle for the mind! A Modern Contact Book
          for the post-networked knowledge economy.
        </span>
        <span style={{ fontSize: "100px" }}>💻📇</span>
        <span className="sub-description">
          Most of our collaboratoin and work tools are hyper-advanced but built
          rely on a 1990s Address Book, and vCard design and paradigm.
        </span>
        <br />
      </div>
    </div>
  );
}
