import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import logo from "../../../assets/OrbitLogo_Purple.png";
import { BRIGHT_ROSE, SUCCESS } from "../../../Constants/colorsPalette";
import { RootState } from "../../../Constants/types";
import FlatButton from "../../shared/FlatButton";
import InputField, { PasswordInput } from "../../shared/InputField";
import RaisedButton from "../../shared/RaisedButton";
import "../Login.css";

export default function ForgotYourPasswordPage() {
  const [email, setEmail] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [done, isDone] = useState(false);
  const processing = useSelector((state: RootState) => state.auth.processing);

  useEffect(() => {
    if (email.length > 0) setIsReady(true);
    else isReady && setIsReady(false);
  }, [email]);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    var auth = firebase.auth();
    var emailAddress = email;

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        isDone(true);
        // history.push("/login");
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  return (
    <div className="max-size column centered">
      <div className="login-header row">
        <span className="fixed-row">
          Don’t have an account?
          <FlatButton
            text="SIGN UP"
            to="/welcome"
            style={{ marginLeft: "5px", fontWeight: 700 }}
          />
          .
        </span>
      </div>
      <div className="login-container column centered">
        <img src={logo} id="login-logo" />
        <form className="reset-form column" onSubmit={submitForm}>
          <label className="reset-form-label">
            No problem. You can reset it if you enter your email below.
          </label>
          <div className="input-part">
            <InputField
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <br />
          <br />
          <RaisedButton
            type="submit"
            text="RESET PASSWORD"
            style={{ maxWidth: "285px" }}
            disabled={!isReady}
            loading={processing}
            dark
          />

          <br />
          <br />
          {done ? (
            <div>
              <label className="reset-form-label" style={{ color: SUCCESS }}>
                Done! Go check your email and reset your password
              </label>
              <br />
              <br />
              <FlatButton text="GO BACK TO LOGIN" to="/login" />
            </div>
          ) : null}
        </form>
        <br />
        <br />
        <div className="login-footer">
          <FlatButton
            text="Privacy Policy"
            to="/privacy"
            style={{ marginTop: 15 }}
          />
        </div>
      </div>
    </div>
  );
}
