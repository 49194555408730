import React, { Component, useState } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";

import { PRIMARY } from "../../../../Constants/colorsPalette";
import SplitFieldsForm from "./SplitFieldsForm";

export default function Subscribe() {
  const publicApiKey =
    "pk_test_51HoVY3CQYfNr8jkCwbG66P3ROTIJO3B3fICpsAbgK6TC1Hbq719AF5fQzub2JlZ4Jdt2T5BtyXBcIk6uCRyWtQEu00onyhykcf";

  return (
    <div className="signup-form-container column">
      <div className="signup-title column">
        <span className="title">Your Subscription</span>
        <hr
          style={{
            width: "60%",
            backgroundColor: `${PRIMARY}`,
          }}
        />
      </div>
      <StripeProvider apiKey={publicApiKey}>
        <Elements>
          <SplitFieldsForm />
        </Elements>
      </StripeProvider>
    </div>
  );
}
