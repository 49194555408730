import { Reducer } from "redux";
import { Action, Flow } from "../Constants/types";
import { dummyFlows } from "../Constants/dummyContacts";
import { SET_SELECTED_FLOW } from "../actions";

interface flowsRootState {
  flows: Flow[];
  selectedFlow?: string; //the title of the flow for now, later it could be the id of the flow
}

const initialState: flowsRootState = {
  flows: dummyFlows,
  selectedFlow: undefined,
};

const flowsReducer: Reducer<flowsRootState, Action> = (
  state: flowsRootState = initialState,
  action: Action
) => {
  const newState = { ...state };

  switch (action.type) {
    case SET_SELECTED_FLOW:
      return { ...newState, selectedFlow: action.value };
  }
  return newState;
};

export default flowsReducer;
