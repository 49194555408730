import {
  GET_LISTS,
  ADD_LIST,
  UPDATE_USER_LISTS,
  SELECT_ORBIT,
  SELECT_LIST,
  EDIT_ORBITS_IN_STATE,
  EDIT_LISTS_IN_STATE,
  EDIT_CONTACTS_LIST,
  CHANGE_GROUPING,
  CLEAR_ALL_FILTERS,
  ADD_COLLECTION,
  GET_LISTS_START,
  GET_LISTS_FINISH,
  GET_ORBITS,
  UPLOAD_ORBITS,
  EDIT_ORBITS_TITLES,
  EDIT_ORBIT_TITLE,
  EDIT_ORBITS_TITLES_FINISH,
  GET_ORBITS_FINISH,
  GET_ORBITS_START,
  ADD_LIST_TO_CONTACT,
  SELECT_PROPERTY,
  EDIT_PROPERTIES_IN_STATE,
  UPDATE_CONTACT_AFTER_LIST_ADDITION,
  UPDATE_USER_TAGS,
  EDIT_LIST_NAME,
  GET_CONTACTS,
  EDIT_COLLECTION_NAME,
  SET_CHANGE_IN_TAGS_NAMES,
  DELETE_LIST_FROM_COLLECTION,
  DELETE_EMPTY_COLLECTION,
  DELETE_LIST_FROM_CONTACT,
  UPDATE_CONTACT_AFTER_LIST_DELETION,
  SET_CHANGE_IN_CONTACTS_LIST,
} from "../actions";
import { filterContacts } from "../functions";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { Action, Contact, List, RootState } from "../Constants/types";

import "firebase/storage";
import firebase from "firebase";
import store from "../store";

function* getOrbits() {
  try {
    yield put({ type: GET_ORBITS_START });
    const getOrbits = firebase.functions().httpsCallable("getOrbits");
    const uid = firebase.auth().currentUser?.uid;
    const response = yield call(getOrbits, { uid });
    if (!response || response?.data.code !== 200) {
      throw new Error("Something went wrong again!");
    } else {
      yield put({ type: UPLOAD_ORBITS, value: response.data.data.orbits });
      yield put({ type: GET_ORBITS_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* addCollection(action: Action) {
  try {
    yield put({ type: GET_LISTS_START });
    if (action.value.listHeader !== "") {
      const addNewListHeader = firebase
        .functions()
        .httpsCallable("addNewListHeader");
      const uid = firebase.auth().currentUser?.uid;
      const listOfHeaders = yield select(
        (state: RootState) => state.filtersAndLists.lists
      );
      const containsHeader =
        listOfHeaders.filter(
          (collection: List) => collection["title"] === action.value.listHeader
        ).length > 0
          ? true
          : false;
      if (!containsHeader) {
        const response = yield call(addNewListHeader, {
          listHeader: action.value.listHeader,
          uid,
        });
        if (!response || response?.data.code !== 200) {
          throw new Error("Something went wrong! please try again.");
        } else {
          yield put({ type: GET_LISTS });
        }
      } else {
        yield put({ type: GET_LISTS_FINISH });
      }
    } else {
      yield put({ type: GET_LISTS_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* editCollectionName(action: Action) {
  try {
    yield put({ type: GET_LISTS_START });
    const listOfHeaders = yield select(
      (state: RootState) => state.filtersAndLists.lists
    );
    if (
      action.value.newListHeader !== "" &&
      !listOfHeaders.find(
        (header: List) => header.title === action.value.newListHeader
      )
    ) {
      const editListHeader = firebase
        .functions()
        .httpsCallable("editListHeader");
      const response = yield call(editListHeader, {
        newListHeader: action.value.newListHeader,
        listId: action.value.listId,
      });
      if (!response || response.data.status !== "success") {
        throw new Error("Something went wrong!");
      } else {
        yield put({ type: GET_LISTS });
      }
    } else {
      yield put({ type: GET_LISTS_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteEmptyCollection(action: Action) {
  try {
    yield put({ type: GET_LISTS_START });
    const deleteAnEmptyListOfTags = firebase
      .functions()
      .httpsCallable("deleteAnEmptyListOfTags");
    const response = yield call(deleteAnEmptyListOfTags, {
      listId: action.value.listId,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong");
    } else {
      yield put({ type: GET_LISTS });
    }
  } catch (error) {
    console.log(error);
  }
}

function* addList(action: Action) {
  try {
    yield put({ type: GET_LISTS_START });
    const listOfHeaders = yield select(
      (state: RootState) => state.filtersAndLists.lists
    );
    const currentCollection: List =
      listOfHeaders[
        listOfHeaders.findIndex(
          (header: List) => header.title === action.value.listHeader
        )
      ];
    if (
      action.value.tag !== "" &&
      !currentCollection.tags.includes(action.value.tag)
    ) {
      const addTagToAlreadyExistingList = firebase
        .functions()
        .httpsCallable("addTagToAlreadyExistingList");
      const uid = firebase.auth().currentUser?.uid;
      const response = yield call(addTagToAlreadyExistingList, {
        listHeader: action.value.listHeader,
        tag: action.value.tag.toLowerCase(),
        uid,
      });
      if (!response || response?.data.code !== 200) {
        throw new Error("Something went wrong! please try again.");
      } else {
        yield put({ type: GET_LISTS });
      }
    } else {
      yield put({ type: GET_LISTS_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* editListName(action: Action) {
  try {
    yield put({ type: GET_LISTS_START });
    const listOfHeaders = yield select(
      (state: RootState) => state.filtersAndLists.lists
    );
    const currentCollection: List =
      listOfHeaders[
        listOfHeaders.findIndex(
          (header: List) => header.id === action.value.listId
        )
      ];
    if (
      action.value.newTag !== "" &&
      !currentCollection.tags.includes(action.value.newTag)
    ) {
      const editTagTitle = firebase.functions().httpsCallable("editTagTitle");
      const response = yield call(editTagTitle, {
        oldTag: action.value.oldTag,
        newTag: action.value.newTag,
        listId: action.value.listId,
      });

      if (!response || response.data.status !== "success") {
        throw new Error("Something went wrong!");
      } else {
        // yield call(getLists);
        yield put({ type: GET_LISTS });
        yield put({ type: SET_CHANGE_IN_TAGS_NAMES });
      }
    } else {
      yield put({ type: GET_LISTS_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteListFromCollection(action: Action) {
  try {
    yield put({ type: GET_LISTS_START });
    const deleteATagFromAListOfTags = firebase
      .functions()
      .httpsCallable("deleteATagFromListOfTags");
    const response = yield call(deleteATagFromAListOfTags, {
      tag: action.value.tag,
      listId: action.value.listId,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong");
    } else {
      yield put({ type: GET_LISTS });
      yield put({ type: SET_CHANGE_IN_TAGS_NAMES });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLists() {
  try {
    yield put({ type: GET_LISTS_START });
    const getLists = firebase.functions().httpsCallable("getLists");
    const user = firebase.auth().currentUser;
    const response = yield call(getLists, {
      uid: user?.uid,
    });
    if (!response || response?.data.code !== 200) {
      throw new Error("Something went wrong! please try again.");
    } else {
      yield put({ type: UPDATE_USER_LISTS, value: response.data.data.lists });

      var tags: string[] = [];
      for (let index = 0; index < response.data.data.lists.length; index++) {
        const element = response.data.data.lists[index];
        tags = tags.concat(element.tags);
      }
      yield put({ type: UPDATE_USER_TAGS, value: tags });
      yield put({ type: GET_LISTS_FINISH });
    }
  } catch (error) {}
}

function* addListToContact(action: Action) {
  try {
    if (action.value.tag !== "") {
      const addTagToContact = firebase
        .functions()
        .httpsCallable("addTagToContact");
      const uid = firebase.auth().currentUser?.uid;
      const response = yield call(addTagToContact, {
        uid,
        contactId: action.value.contactId,
        tag: action.value.tag.toLowerCase(),
      });
      if (!response || response?.data.code !== 200) {
        throw new Error("Something went wrong again!");
      } else {
        const contactsListIndex = store
          .getState()
          .contacts.contactsList.findIndex(
            (contact) => contact.id === action.value.contactId
          );
        const originalListIndex = store
          .getState()
          .contacts.originalContacts.findIndex(
            (contact) => contact.id === action.value.contactId
          );

        yield put({
          type: UPDATE_CONTACT_AFTER_LIST_ADDITION,
          value: {
            id: action.value.contactId,
            contactsListIndex,
            originalListIndex,
            list: action.value.tag,
          },
        });
        yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteListFromContact(action: Action) {
  try {
    const deleteTagFromAContact = firebase
      .functions()
      .httpsCallable("deleteTagFromAContact");
    const response = yield call(deleteTagFromAContact, {
      contactId: action.value.contactId,
      tag: action.value.tag,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong");
    } else {
      const contactsListIndex = store
        .getState()
        .contacts.contactsList.findIndex(
          (contact) => contact.id === action.value.contactId
        );
      const originalListIndex = store
        .getState()
        .contacts.originalContacts.findIndex(
          (contact) => contact.id === action.value.contactId
        );
      // const contactsListIndex = yield select((state: RootState) =>
      //   state.contacts.contactsList.findIndex(
      //     (contact) => contact.id === action.value.contactId
      //   )
      // );
      // const originalListIndex = yield select((state: RootState) =>
      //   state.contacts.originalContacts.findIndex(
      //     (contact) => contact.id === action.value.contactId
      //   )
      // );
      yield put({
        type: UPDATE_CONTACT_AFTER_LIST_DELETION,
        value: {
          contactsListIndex,
          originalListIndex,
          list: action.value.tag,
        },
      });
      yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
    }
  } catch (error) {
    console.log(error);
  }
}

function* selectOrbit(action: Action) {
  try {
    const selectedOrbits: number[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedOrbits
    );
    const selectedLists: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedLists
    );
    const selectedProperties: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedProperties
    );
    const groupingOption: "Show All" | "Intersection" | "none" = yield select(
      (state: RootState) => state.filtersAndLists.groupingOption
    );
    const contacts: Contact[] = yield select(
      (state: RootState) => state.contacts.originalContacts
    );

    const newSelectedOrbits: number[] = (() => {
      if (selectedOrbits.includes(action.value.orbitNumber))
        return selectedOrbits.filter(
          (orbitNumber) => orbitNumber !== action.value.orbitNumber
        );
      return selectedOrbits.concat([action.value.orbitNumber]);
    })();

    const filteredContacts = filterContacts(
      contacts,
      groupingOption,
      newSelectedOrbits,
      selectedLists,
      selectedProperties
    );

    yield put({ type: EDIT_ORBITS_IN_STATE, value: newSelectedOrbits });
    yield put({ type: EDIT_CONTACTS_LIST, value: filteredContacts });
    yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
  } catch (error) {
    console.log(error);
  }
}

function* selectList(action: Action) {
  try {
    const selectedOrbits: number[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedOrbits
    );
    const selectedLists: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedLists
    );
    const selectedProperties: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedProperties
    );
    const groupingOption: "Show All" | "Intersection" | "none" = yield select(
      (state: RootState) => state.filtersAndLists.groupingOption
    );
    const contacts: Contact[] = yield select(
      (state: RootState) => state.contacts.originalContacts
    );

    const newFiltersState: {
      selectedLists: string[];
      groupingOption: "Show All" | "Intersection" | "none";
    } = (() => {
      if (selectedLists.includes(action.value.listName)) {
        const newSelectedLists = selectedLists.filter(
          (listName) => listName !== action.value.listName
        );
        const newGroupingOtion =
          newSelectedLists.length > 0 || selectedProperties.length > 0
            ? groupingOption
            : "none";
        return {
          selectedLists: newSelectedLists,
          groupingOption: newGroupingOtion,
        };
      } else {
        const newGroupingOtion =
          groupingOption === "none" ? "Show All" : groupingOption;
        return {
          selectedLists: selectedLists.concat([action.value.listName]),
          groupingOption: newGroupingOtion,
        };
      }
    })();

    const filteredContacts = filterContacts(
      contacts,
      newFiltersState.groupingOption,
      selectedOrbits,
      newFiltersState.selectedLists,
      selectedProperties
    );

    yield put({ type: EDIT_LISTS_IN_STATE, value: newFiltersState });
    yield put({ type: EDIT_CONTACTS_LIST, value: filteredContacts });
    yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
  } catch (error) {
    console.log(error);
  }
}

function* selectProperty(action: Action) {
  try {
    const selectedOrbits: number[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedOrbits
    );
    const selectedLists: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedLists
    );

    const selectedProperties: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedProperties
    );

    const groupingOption: "Show All" | "Intersection" | "none" = yield select(
      (state: RootState) => state.filtersAndLists.groupingOption
    );
    const contacts: Contact[] = yield select(
      (state: RootState) => state.contacts.originalContacts
    );

    const newFiltersState: {
      selectedProperties: string[];
      groupingOption: "Show All" | "Intersection" | "none";
    } = (() => {
      if (selectedProperties.includes(action.value.propertyName)) {
        const newSelectedProperties = selectedProperties.filter(
          (propertyName) => propertyName !== action.value.propertyName
        );
        const newGroupingOtion =
          newSelectedProperties.length > 0 || selectedLists.length > 0
            ? groupingOption
            : "none";
        return {
          selectedProperties: newSelectedProperties,
          groupingOption: newGroupingOtion,
        };
      } else {
        const newGroupingOtion =
          groupingOption === "none" ? "Show All" : groupingOption;
        return {
          selectedProperties: selectedProperties.concat([
            action.value.propertyName,
          ]),
          groupingOption: newGroupingOtion,
        };
      }
    })();

    const filteredContacts = filterContacts(
      contacts,
      newFiltersState.groupingOption,
      selectedOrbits,
      selectedLists,
      newFiltersState.selectedProperties
    );
    yield put({
      type: EDIT_PROPERTIES_IN_STATE,
      value: newFiltersState,
    });
    yield put({ type: EDIT_CONTACTS_LIST, value: filteredContacts });
    yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
  } catch (error) {
    console.log(error);
  }
}

function* changeGrouping(action: Action) {
  const selectedOrbits: number[] = yield select(
    (state: RootState) => state.filtersAndLists.selectedOrbits
  );
  const selectedLists: string[] = yield select(
    (state: RootState) => state.filtersAndLists.selectedLists
  );
  const selectedProperties: string[] = yield select(
    (state: RootState) => state.filtersAndLists.selectedProperties
  );
  const contacts: Contact[] = yield select(
    (state: RootState) => state.contacts.originalContacts
  );

  const filteredContacts = filterContacts(
    contacts,
    action.value,
    selectedOrbits,
    selectedLists,
    selectedProperties
  );
  yield put({ type: EDIT_CONTACTS_LIST, value: filteredContacts });
  yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
}

function* clearAll() {
  try {
    const originalContacts: Contact[] = yield select(
      (state: RootState) => state.contacts.originalContacts
    );
    yield put({ type: EDIT_CONTACTS_LIST, value: originalContacts });
  } catch (error) {
    console.log(error);
  }
}

function* editOrbitsTitles(action: Action) {
  yield put({ type: GET_ORBITS_START });
  yield call(editOrbitTitle, {
    prevTitle: action.value.o1PrevTitle,
    newTitle: action.value.o1NewTitle,
    orbitNumber: 1,
  });
  yield call(editOrbitTitle, {
    prevTitle: action.value.o2PrevTitle,
    newTitle: action.value.o2NewTitle,
    orbitNumber: 2,
  });
  yield call(editOrbitTitle, {
    prevTitle: action.value.o3PrevTitle,
    newTitle: action.value.o3NewTitle,
    orbitNumber: 3,
  });
  yield call(editOrbitTitle, {
    prevTitle: action.value.o4PrevTitle,
    newTitle: action.value.o4NewTitle,
    orbitNumber: 4,
  });
  yield call(editOrbitTitle, {
    prevTitle: action.value.o5PrevTitle,
    newTitle: action.value.o5NewTitle,
    orbitNumber: 5,
  });
  yield put({ type: GET_ORBITS });
  yield put({ type: EDIT_ORBITS_TITLES_FINISH });
}
function* editOrbitTitle({ prevTitle, newTitle, orbitNumber }: any) {
  try {
    if (prevTitle !== newTitle) {
      const editOrbitTitle = firebase
        .functions()
        .httpsCallable("editOrbitTitle");
      const uid = firebase.auth().currentUser?.uid;
      const response = yield call(editOrbitTitle, {
        uid,
        orbitNumber,
        newTitle,
      });
      if (!response || response.data.status === "fail") {
        throw new Error("Something went wrong again!");
      }
    }
  } catch (error) {
    console.log(error.data);
  }
}

export default function* filtersSaga() {
  yield all([
    takeEvery(ADD_COLLECTION, addCollection),
    takeEvery(DELETE_EMPTY_COLLECTION, deleteEmptyCollection),
    takeEvery(EDIT_COLLECTION_NAME, editCollectionName),
    takeEvery(ADD_LIST, addList),
    takeEvery(DELETE_LIST_FROM_COLLECTION, deleteListFromCollection),
    takeEvery(EDIT_LIST_NAME, editListName),
    takeEvery(ADD_LIST_TO_CONTACT, addListToContact),
    takeEvery(DELETE_LIST_FROM_CONTACT, deleteListFromContact),
    takeEvery(GET_LISTS, getLists),
    takeLatest(GET_ORBITS, getOrbits),
    takeLatest(EDIT_ORBITS_TITLES, editOrbitsTitles),
    takeEvery(EDIT_ORBIT_TITLE, editOrbitTitle),
    takeLeading(SELECT_ORBIT, selectOrbit),
    takeLeading(SELECT_LIST, selectList),
    takeLeading(SELECT_PROPERTY, selectProperty),
    takeLeading(CHANGE_GROUPING, changeGrouping),
    takeLeading(CLEAR_ALL_FILTERS, clearAll),
  ]);
}
