import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Contact, RootState } from "../../../Constants/types";
import DownshiftForLinking from "./DownshitForLinking";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import { PRIMARY } from "../../../Constants/colorsPalette";
import Loader from "react-loader-spinner";
import {
  ADD_KEY_PERSON_TO_CONTACT,
  ADD_PARTNER_TO_CONTACT,
  DELETE_NOTE,
  GET_ALL_NOTES_OF_CONTACT,
  REMOVE_KEY_PERSON_FROM_CONTACT,
  REMOVE_PARTNER_FROM_CONTACT,
  TOGGLE_IS_PINNED_NOTE,
} from "../../../actions";
import Done from "@material-ui/icons/Done";
import editIcon from "../../../assets/pencil-fill.svg";
import xIcon from "../../../assets/x.svg";
import purpleXIcon from "../../../assets/purple-x.svg";
import OrbitButton from "../../shared/OrbitButton";

type props = {
  contact: Contact;
};

export default function FourthSection({ contact }: props) {
  const contacts = useSelector(
    (state: RootState) => state.contacts.originalContacts
  );

  const displayedContactNotes = useSelector(
    (state: RootState) => state.contacts.displayedContactNotes
  );

  const processingPartner = useSelector(
    (state: RootState) => state.contacts.partnerProcessing
  );

  const processingKeyPeople = useSelector(
    (state: RootState) => state.contacts.keyPeopleProcessing
  );

  const processingNotes = useSelector(
    (state: RootState) => state.contacts.notesProcessing
  );

  const [editing, setEditing] = useState(false);

  const [addingKeyPerson, setAddingKeyPerson] = useState(false);
  const [addingDescription, setAddingDescription] = useState(false);

  const [existingKeyPerson, setExistingKeyPerson] = useState(undefined);
  const [nonExistingKeyPerson, setNonExistingKeyPerson] = useState("");
  const [description, setDescription] = useState("");

  const [keyPeople, setKeyPeople] = useState(contact.keyPeople);
  const [partner, setPartner] = useState(contact.partner);

  const dispatch = useDispatch();

  const addPartnerToContact = (selectedItem: any) => {
    dispatch({
      type: ADD_PARTNER_TO_CONTACT,
      value: {
        contactId: contact.id,
        contactGivenName: contact.givenName,
        contactSurname: contact.surname,
        contactPictureUrl: contact.pictureUrl,
        partnerId: selectedItem.id,
        partnerGivenName: selectedItem.givenName,
        partnerSurname: selectedItem.surname,
        partnerPictureUrl: selectedItem.pictureUrl,
      },
    });
    setPartner({
      id: selectedItem.id,
      givenName: selectedItem.givenName,
      surname: selectedItem.surname,
      pictureUrl: selectedItem.pictureUrl,
    });
  };
  const addNonExistingPartner = (value: any) => {
    dispatch({
      type: ADD_PARTNER_TO_CONTACT,
      value: {
        contactId: contact.id,
        contactGivenName: contact.givenName,
        contactSurname: contact.surname,
        contactPictureUrl: contact.pictureUrl,
        partnerGivenName: value,
      },
    });
    setPartner({
      id: "",
      givenName: value,
      surname: "",
      pictureUrl: "",
    });
  };
  const addExistingKeyPersonToContact = (selectedItem: any) => {
    dispatch({
      type: ADD_KEY_PERSON_TO_CONTACT,
      value: {
        contactId: contact.id,
        keyPersonGivenName: selectedItem.givenName,
        keyPersonSurname: selectedItem.surname,
        keyPersonUrl: selectedItem.pictureUrl,
        keyPersonId: selectedItem.id,
        description,
      },
    });
    const newKeyPeople = keyPeople.concat({
      id: selectedItem.id,
      givenName: selectedItem.givenName,
      surname: selectedItem.surname,
      pictureUrl: selectedItem.pictureUrl,
      description,
    });
    setKeyPeople(newKeyPeople);
  };
  const addNonExistingKeyPersonToContact = (value: any) => {
    dispatch({
      type: ADD_KEY_PERSON_TO_CONTACT,
      value: {
        contactId: contact.id,
        keyPersonGivenName: value,
        description,
      },
    });
    const newKeyPeople = keyPeople.concat({
      id: "",
      givenName: nonExistingKeyPerson,
      surname: "",
      pictureUrl: "",
      description,
    });
    setKeyPeople(newKeyPeople);
  };

  const removeKeyPersonFromContact = (
    keyPerson: any,
    keyPersonIndex: number
  ) => {
    dispatch({
      type: REMOVE_KEY_PERSON_FROM_CONTACT,
      value: {
        contactId: contact.id,
        keyPerson,
        keyPersonIndex,
      },
    });
    const newKeyPeople = keyPeople.filter(
      (keyPerson, index) => index !== keyPersonIndex
    );
    setKeyPeople(newKeyPeople);
  };

  const removePartnerFromContact = (partnerId?: string) => {
    if (partnerId) {
      dispatch({
        type: REMOVE_PARTNER_FROM_CONTACT,
        value: { contactId: contact.id, partnerId },
      });
    } else {
      dispatch({
        type: REMOVE_PARTNER_FROM_CONTACT,
        value: { contactId: contact.id },
      });
    }
    setPartner({
      id: "",
      givenName: "",
      surname: "",
      pictureUrl: "",
    });
  };

  const savingNonExistingKeyPerson = (value: any) => {
    setNonExistingKeyPerson(value);
    setAddingDescription(true);
  };

  const savingExistingKeyPerson = (selectedItem: any) => {
    setExistingKeyPerson(selectedItem);
    setAddingDescription(true);
  };

  const resetKeyPersonAddition = () => {
    setAddingKeyPerson(false);
    setAddingDescription(false);
    setExistingKeyPerson(undefined);
    setNonExistingKeyPerson("");
    setDescription("");
  };

  useEffect(() => {
    setEditing(false);
    setAddingKeyPerson(false);
    setAddingDescription(false);
    setExistingKeyPerson(undefined);
    setNonExistingKeyPerson("");
    setDescription("");
    setPartner(contact.partner);
    setKeyPeople(contact.keyPeople);
    dispatch({
      type: GET_ALL_NOTES_OF_CONTACT,
      value: { contactId: contact.id },
    });
  }, [contact]);
  return (
    <div className="fourth-section">
      <div className="row" style={{ justifyContent: "space-between" }}>
        <div className="partner">
          <span className="title">Partner</span>
          {processingPartner ? (
            <div className="loading-icon">
              <Loader type="TailSpin" color={PRIMARY} height={15} width={15} />
            </div>
          ) : (
            <div>
              {partner?.givenName !== "" && partner?.givenName !== " " ? (
                <div className="row">
                  <img
                    id="preview"
                    className="avatar-img"
                    src={
                      partner?.pictureUrl !== "" && partner?.pictureUrl !== " "
                        ? partner?.pictureUrl
                        : avatarPlaceholder
                    }
                    alt=""
                    width={20}
                    height={20}
                    style={{ borderRadius: 20 }}
                  />
                  <span className="person-name">
                    {partner?.givenName} {partner?.surname}
                  </span>
                  {editing ? (
                    <img
                      alt=""
                      className="x-icon"
                      src={xIcon}
                      onClick={() => {
                        removePartnerFromContact(partner.id);
                      }}
                    />
                  ) : null}
                </div>
              ) : (
                <DownshiftForLinking
                  placeholder={"Add partner"}
                  contacts={contacts}
                  onSelect={addPartnerToContact}
                  addNonExistingPerson={addNonExistingPartner}
                />
              )}
            </div>
          )}
        </div>
        {editing ? (
          <div className="edit-icon" style={{ marginTop: 15 }}>
            <Done
              style={{ width: 20 }}
              onClick={() => {
                setEditing(false);
              }}
            />
          </div>
        ) : (
          <img
            className="edit-icon"
            style={{ marginTop: 15 }}
            src={editIcon}
            onClick={() => setEditing(true)}
          />
        )}
      </div>
      <div className="key-people">
        <div>
          <div className="row">
            <span className="title">Key People</span>
            {processingKeyPeople ? (
              <div className="loading-icon">
                <Loader
                  type="TailSpin"
                  color={PRIMARY}
                  height={15}
                  width={15}
                />
              </div>
            ) : (
              <div className="addition-key-people">
                {!addingKeyPerson && !addingDescription ? null : (
                  <div className="addition-part">
                    {addingKeyPerson ? (
                      <DownshiftForLinking
                        placeholder={"Add key person"}
                        contacts={contacts}
                        onSelect={savingExistingKeyPerson}
                        addNonExistingPerson={savingNonExistingKeyPerson}
                      />
                    ) : null}
                    {addingDescription ? (
                      <div>
                        -
                        <input
                          className="add-person"
                          style={{ width: 230, marginLeft: 10 }}
                          placeholder={"Add description"}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    ) : null}
                    <div className="row">
                      {(nonExistingKeyPerson !== "" || existingKeyPerson) &&
                      description !== "" ? (
                        <div className="edit-icon">
                          <Done
                            style={{ width: 20 }}
                            onClick={() => {
                              if (existingKeyPerson) {
                                addExistingKeyPersonToContact(
                                  existingKeyPerson
                                );
                              } else {
                                addNonExistingKeyPersonToContact(
                                  nonExistingKeyPerson
                                );
                              }
                              resetKeyPersonAddition();
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="list-of-key-people">
                  {keyPeople?.map((keyPerson, index) => (
                    <div className="row" style={{ paddingBottom: 10 }}>
                      <img
                        id="preview"
                        className="avatar-img"
                        src={
                          keyPerson?.pictureUrl !== "" &&
                          keyPerson?.pictureUrl !== " "
                            ? keyPerson?.pictureUrl
                            : avatarPlaceholder
                        }
                        alt=""
                        width={20}
                        height={20}
                        style={{ borderRadius: 20 }}
                      />
                      <span className="person-name">
                        {keyPerson?.givenName} {keyPerson?.surname} -{" "}
                        {keyPerson?.description}
                      </span>
                      {editing ? (
                        <img
                          alt=""
                          className="x-icon"
                          src={xIcon}
                          onClick={() => {
                            removeKeyPersonFromContact(keyPerson, index);
                          }}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {editing ? (
          <div>
            {!addingKeyPerson && !addingDescription ? (
              <OrbitButton
                text="+"
                white
                style={{
                  fontSize: 32,
                  fontWeight: 400,
                  height: 20,
                  width: 20,
                }}
                onClick={() => {
                  setAddingKeyPerson(true);
                }}
              />
            ) : (
              <img
                className="edit-icon"
                src={purpleXIcon}
                onClick={() => {
                  resetKeyPersonAddition();
                }}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className="notes">
        <div className="notes-header">
          <span className="title">Notes</span>
        </div>
        {processingNotes ? (
          <div className="centered">
            <Loader type="TailSpin" color={PRIMARY} height={50} width={50} />
          </div>
        ) : (
          <div className="notes-content">
            {displayedContactNotes.map((note) => (
              <div key={note.id} className="note-container">
                <div className="note-and-pin">
                  <div
                    className={
                      note.isPinned ? "purple-pin-icon" : "grey-pin-icon"
                    }
                    onClick={() => {
                      dispatch({
                        type: TOGGLE_IS_PINNED_NOTE,
                        value: {
                          contactId: contact.id,
                          noteId: note.id,
                          isPinned: !note.isPinned,
                        },
                      });
                    }}
                  ></div>
                  <span className="note">{note.note}</span>
                </div>
                <div className="right-field">
                  <div className="timestamp-container">
                    <span className="timestamp">
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(note.createdAt)}
                    </span>
                    <span className="timestamp">
                      {new Intl.DateTimeFormat("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(note.createdAt)}
                    </span>
                  </div>
                  {editing ? (
                    <div>
                      {displayedContactNotes.length !== 0 ? (
                        <img
                          className="x-icon"
                          src={xIcon}
                          onClick={() => {
                            dispatch({
                              type: DELETE_NOTE,
                              value: { contactId: contact.id, noteId: note.id },
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
