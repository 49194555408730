import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideSeven({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Slow Software</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Silicon Valley’s “move fast and break things” and “fail fast” ethos
          may generate VC returns and many shiny new objects, but it also leaves
          a graveyard of outdated tools, marooned databses, and broken promises.
          Espcially in the blogging, photosharing, and social or professional
          networking.
        </span>
        <span style={{ fontSize: "100px" }}>🐢</span>
        <span className="sub-description">
          There are dozens of whiz-bang artificial intelligence and machine
          learning apps for the 0.01 elite tech workers that merges dozens of
          public data sources and tries to magically automate away any manual
          work and intuit your true preferenes. To date all of these tools,
          after hundreds of millions of dollars in funding, have mainly
          corrupted address books with more imperfect data shortly before being
          acqui-hired.
        </span>
        <span className="sub-description">
          Orbit moves slowly and intentionally to build technology that bridges
          platforms, standards, & time.
        </span>
        <br />
      </div>
    </div>
  );
}
