import React from "react";
import { MouseEvent } from "react";

import { SECONDARY, PRIMARY, WHITE } from "../../../Constants/colorsPalette";
import "./Sidebar.css";

type props = {
  cx: string | number | undefined;
  cy: string | number | undefined;
  text: string | undefined;
  id: number;
  selectedId: number | undefined;
  onClick: ((event: MouseEvent) => void) | undefined;
};

export default function SidebarElement({
  cx = 0,
  cy = 0,
  text,
  onClick,
  id,
  selectedId,
}: props) {
  const isSelected = selectedId == id ? true : false;
  return (
    <svg
      className={"sidebar-element " + (isSelected ? " selected " : "")}
      onClick={onClick}
    >
      <circle
        cx={cx}
        cy={cy}
        r="8"
        stroke={PRIMARY}
        fill={isSelected ? PRIMARY : WHITE}
      />
      <text id={id + ""} x="40" y={+cy + 10} fill={PRIMARY} fontSize="25px">
        {text}
      </text>
    </svg>
  );
}
