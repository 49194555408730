import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideEight({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">The Orbit Pledge</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Business Model is Destiny. If it is free you are the product.
        </span>
        <span style={{ fontSize: "100px" }}>🤝</span>
        <span className="sub-description">
          We take inspiration from Posthaven and other so-called Zebra startups,
          to build a tool that stand the test of time.
        </span>
        <span className="sub-description">
          Our pledge to you: You pay a globally affordable fee, we keep the
          lights on and update Orbit to be durable and current, without chasing
          fads and niche frontiers.
        </span>
        <br />
      </div>
    </div>
  );
}
