import { Reducer } from "redux";
import update from "immutability-helper";
import {
  CHANGE_GROUPING,
  UPDATE_USER_LISTS,
  EDIT_LISTS_IN_STATE,
  EDIT_ORBITS_IN_STATE,
  CLEAR_ALL_FILTERS,
  GET_LISTS_FINISH,
  GET_LISTS_START,
  UPLOAD_ORBITS,
  EDIT_ORBITS_TITLES_START,
  EDIT_ORBITS_TITLES_FINISH,
  GET_ORBITS_FINISH,
  GET_ORBITS_START,
  EDIT_PROPERTIES_IN_STATE,
  UPDATE_USER_TAGS,
  RESET_CHANGE_IN_TAGS_NAMES,
  SET_CHANGE_IN_TAGS_NAMES,
  UPDATE_ORBITS,
} from "../actions";
import { Action, Orbit, List } from "../Constants/types";

interface filtersAndListsRootState {
  orbits: Orbit[];
  lists: List[];
  tags: string[];
  listsProcessing: boolean;
  changeInTagsNames: boolean;
  orbitsProcessing: boolean;
  editOrbitsNames: boolean;
  selectedOrbits: number[];
  selectedLists: string[];
  selectedProperties: string[];
  groupingOption: "Show All" | "Intersection" | "none";
}

const initialState: filtersAndListsRootState = {
  orbits: [],
  lists: [],
  tags: [],
  listsProcessing: false,
  changeInTagsNames: false,
  orbitsProcessing: false,
  editOrbitsNames: false,
  selectedOrbits: [],
  selectedLists: [],
  selectedProperties: [],
  groupingOption: "none",
};

const filtersReducer: Reducer<filtersAndListsRootState, Action> = (
  state: filtersAndListsRootState = initialState,
  action: Action
) => {
  const newState = { ...state };

  switch (action.type) {
    case EDIT_ORBITS_TITLES_START:
      return { ...newState, editOrbitsNames: true };
    case EDIT_ORBITS_TITLES_FINISH:
      return { ...newState, editOrbitsNames: false };
    case UPLOAD_ORBITS:
      return { ...newState, orbits: action.value };
    case UPDATE_ORBITS:
      return update(newState, {
        orbits: {
          [action.value.currentOrbitNumber - 1]: {
            current_capacity: {
              $set:
                newState.orbits[action.value.currentOrbitNumber - 1]
                  .current_capacity - 1,
            },
          },
          [action.value.newOrbitNumber - 1]: {
            current_capacity: {
              $set:
                newState.orbits[action.value.newOrbitNumber - 1]
                  .current_capacity + 1,
            },
          },
        },
      });
    case EDIT_ORBITS_IN_STATE:
      return { ...newState, selectedOrbits: action.value };
    case EDIT_LISTS_IN_STATE:
      return { ...newState, ...action.value };
    case RESET_CHANGE_IN_TAGS_NAMES:
      return { ...newState, changeInTagsNames: false };
    case SET_CHANGE_IN_TAGS_NAMES:
      return { ...newState, changeInTagsNames: true };
    case EDIT_PROPERTIES_IN_STATE:
      return { ...newState, ...action.value };
    case UPDATE_USER_LISTS:
      return { ...newState, lists: action.value };
    case UPDATE_USER_TAGS:
      return { ...newState, tags: action.value };
    case CHANGE_GROUPING:
      return { ...newState, groupingOption: action.value };
    case GET_ORBITS_START:
      return { ...newState, orbitsProcessing: true };
    case GET_ORBITS_FINISH:
      return { ...newState, orbitsProcessing: false };
    case GET_LISTS_START:
      return { ...newState, listsProcessing: true };
    case GET_LISTS_FINISH:
      return { ...newState, listsProcessing: false };
    case CLEAR_ALL_FILTERS:
      return {
        ...newState,
        groupingOption: "none",
        selectedLists: [],
        selectedOrbits: [],
        selectedProperties: [],
      };
  }
  return newState;
};

export default filtersReducer;
