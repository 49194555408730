import React from "react";

import logo from "../../assets/OrbitLogo_Purple.png";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <div className="privacy-container column">
      <div className="logo-container">
        <img id="logo" src={logo} />
      </div>
      <div
        className="column  secondary"
        style={{ padding: "0px 20%", position: "relative", top: "13%" }}
      >
        <h2 style={{ textDecoration: "underline", alignSelf: "center" }}>
          Orbit Contacts - Terms of Use
        </h2>
        <h5>Effective date: Nov 20, 2020 </h5>
        <p>
          Welcome to Orbit Contacts. Please read on to learn the rules and
          restrictions that govern your use of our website(s), products,
          services and applications (the “Services”). If you have any questions,
          comments, or concerns regarding these terms or the Services, please
          contact us at:
        </p>
        <p>
          Email: privacy@orbitcontacts.com <br />
          Phone: +1.6506861280 <br />
          Address: 228 Park Ave S, PMB 53118 New York, New York 10003-1502 US
        </p>
        <p>
          These Terms of Use (the “Terms”) ARBITRATION AGREEMENT SECTION BELOW
          are a binding contract between you and
          <b>Orbit Contacts INTERNATIONAL, INC.</b> (“Orbit Contacts,” “we” and
          “us”). Your use of the Services in any way means that you agree to all
          of these Terms, and these Terms will remain in effect while you use
          the Services. These Terms include the provisions in this document as
          well as those in the Privacy Policy.
        </p>
        <p>
          <b>Please read these Terms carefully.</b> They cover important
          information about Services provided to you and any charges, taxes, and
          fees we bill you.
          <b>
            These Terms include information about future changes to these Terms,
            automatic renewals, limitations of liability, a class action waiver
            and resolution of disputes by arbitration instead of in court.
            PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT
            TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING,
            YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
          </b>
        </p>
        <p>
          <b>ARBITRATION NOTICE AND CLASS ACTION WAIVER: </b>YOU AGREE THAT
          DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
          ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
          LAWSUIT OR CLASS-WIDE ARBITRATION.
        </p>
        <h4>Will these Terms ever change?</h4>
        <p>
          We are constantly trying to improve our Services, so these Terms may
          need to change along with our Services. We reserve the right to change
          the Terms at any time, but if we do, we will place a notice on our
          site, send you an email, and/or notify you by some other means.
        </p>
        <p>
          If you don’t agree with the new Terms, you are free to reject them;
          unfortunately, that means you will no longer be able to use the
          Services. If you use the Services in any way after a change to the
          Terms is effective, that means you agree to all of the changes.
        </p>
        <p>
          Except for changes by us as described here, no other amendment or
          modification of these Terms will be effective unless in writing and
          signed by both you and us.
        </p>
        <h4>Will these Terms ever change?</h4>
        <p>
          Orbit Contacts takes the privacy of its users very seriously. For the
          current Orbit Contacts Privacy Policy, please find it at the footer of
          the site.
        </p>
        <p style={{ textDecoration: "underline", fontStyle: "italic" }}>
          Children’s Online Privacy Protection Act
        </p>
        <p>
          The Children’s Online Privacy Protection Act (“COPPA”) requires that
          online service providers obtain parental consent before they knowingly
          collect personally identifiable information online from children who
          are under thirteen (13). We do not knowingly collect or solicit
          personally identifiable information from children under thirteen (13)
          without obtaining verifiable consent from that child’s parent or
          guardian (“Parental Consent”), except for the limited amount of
          personally identifiable information we need to collect in order to
          obtain Parental Consent (“Required Information”). Until we have
          received Parental Consent, we will only use Required Information for
          the purpose of obtaining Parental Consent. If you are a child under
          thirteen (13), please do not attempt to register for or otherwise use
          the Services or send any personal information about yourself to us
          before we obtain Parental Consent, except for the Required Information
          in the context of the Parental Consent process. If we learn we have
          collected personal information from a child under thirteen (13), we
          will delete that information as quickly as possible. If you believe
          that a child under thirteen (13) may have provided us with personal
          information (beyond the Required Information) without our obtaining
          Parental Consent, please contact us at privacy@orbitcontacts.com.
        </p>
        <h4>What are the basics of using Orbit Contacts?</h4>
        <p>
          You may be required to sign up for an account, select a password and
          user name (“Orbit Contacts User ID”), and provide us with certain
          information or data, such as your contact information. You promise to
          provide us with accurate, complete, and updated registration
          information about yourself. You may not select as your Orbit Contacts
          User ID a name that you do not have the right to use, or another
          person’s name with the intent to impersonate that person. You may not
          transfer your account to anyone else without our prior written
          permission.
        </p>
        <p>
          You represent and warrant that you are an individual of legal age to
          form a binding contract (or if not, you’ve received your parent’s or
          guardian’s permission to use the Services and have gotten your parent
          or guardian to agree to these Terms on your behalf).
        </p>
        <p>
          You will only use the Services for your own internal, personal,
          non-commercial use, and not on behalf of or for the benefit of any
          third party, and only in a manner that complies with all laws that
          apply to you. If your use of the Services is prohibited by applicable
          laws, then you aren’t authorized to use the Services. We can’t and
          won’t be responsible for your using the Services in a way that breaks
          the law.
        </p>
        <p>
          You will not share your Orbit Contacts User ID, account or password
          with anyone, and you must protect the security of your Orbit Contacts
          User ID, account, password and any other access tools or credentials.
          You’re responsible for any activity associated with your Orbit
          Contacts User ID and account.
        </p>
        <h4>What about messaging?</h4>
        <p>
          As part of the Services, you may receive communications through the
          Services, including messages related to two factor authentication or
          that Orbit Contacts sends you (for example, via email or SMS).{" "}
          <b>
            By signing up for the Services and providing us with your wireless
            number, you confirm that you want Orbit Contacts to send you
            information regarding your account or transactions with us, which
            may include Orbit Contacts using automated dialing technology to
            text you at the wireless number you provided, and you agree to
            receive communications from Orbit Contacts, and you represent and
            warrant that each person you register for the Services or for whom
            you provide a wireless phone number has consented to receive
            communications from Orbit Contacts.
          </b>{" "}
          You agree to indemnify and hold Orbit Contacts harmless from and
          against any and all claims, liabilities, damages (actual and
          consequential), losses and expenses (including attorneys’ fees)
          arising from or in any way related to your breach of the foregoing.
        </p>
        <h4>Are there restrictions in how I can use the Services?</h4>
        <p>
          You represent, warrant, and agree that you will not contribute any
          Content or User Submission (each of those terms is defined below) or
          otherwise use the Services or interact with the Services in a manner
          that:
        </p>
        <ol className="primary" type="a">
          <li>
            infringes or violates the intellectual property rights or any other
            rights of anyone else (including Orbit Contacts);
          </li>
          <br />
          <li>
            violates any law or regulation, including, without limitation, any
            applicable export control laws, privacy laws or any other purpose
            not reasonably intended by Orbit Contacts;
          </li>
          <br />

          <li>
            is dangerous, harmful, fraudulent, deceptive, threatening,
            harassing, defamatory, obscene, or otherwise objectionable;
          </li>
          <br />
          <li>
            jeopardizes the security of your Orbit Contacts User ID, account or
            anyone else’s (such as allowing someone else to log in to the
            Services as you);
          </li>
          <br />
          <li>
            attempts, in any manner, to obtain the password, account, or other
            security information from any other user;
          </li>
          <br />
          <li>
            violates the security of any computer network, or cracks any
            passwords or security encryption codes;
          </li>
          <br />
          <li>
            runs Maillist, Listserv, any form of auto-responder or “spam” on the
            Services, or any processes that run or are activated while you are
            not logged into the Services, or that otherwise interfere with the
            proper working of the Services (including by placing an unreasonable
            load on the Services’ infrastructure);
          </li>
          <br />
          <li>
            “crawls,” “scrapes,” or “spiders” any page, data, or portion of or
            relating to the Services or Content (through use of manual or
            automated means);
          </li>
          <br />
          <li>copies or stores any significant portion of the Content; or</li>
          <br />
          <li>
            decompiles, reverse engineers, or otherwise attempts to obtain the
            source code or underlying ideas or information of or relating to the
            Services.
          </li>
        </ol>
        <p>
          decompiles, reverse engineers, or otherwise attempts to obtain the
          source code or underlying ideas or information of or relating to the
          Services.
        </p>
        <h4>What are my rights in the Services?</h4>
        <p>
          The materials displayed or performed or available on or through the
          Services, including, but not limited to, text, graphics, data,
          articles, photos, images, illustrations and so forth (all of the
          foregoing, the “Content”) are protected by copyright and/or other
          intellectual property laws. You promise to abide by all copyright
          notices, trademark rules, information, and restrictions contained in
          any Content you access through the Services, and you won’t use, copy,
          reproduce, modify, translate, publish, broadcast, transmit,
          distribute, perform, upload, display, license, sell, commercialize or
          otherwise exploit for any purpose any Content not owned by you, (i)
          without the prior consent of the owner of that Content or (ii) in a
          way that violates someone else’s (including Orbit Contacts's) rights.
        </p>
        <p>
          Subject to these Terms, we grant each user of the Services a
          worldwide, non-exclusive, non-sublicensable and non-transferable
          license to use (i.e., to download and display locally) Content solely
          for purposes of using the Services. Use, reproduction, modification,
          distribution or storage of any Content for any purpose other than
          using the Services is expressly prohibited without prior written
          permission from us. You understand that Orbit Contacts owns the
          Services. You won’t modify, publish, transmit, participate in the
          transfer or sale of, reproduce (except as expressly provided in this
          Section), create derivative works based on, or otherwise exploit any
          of the Services. The Services may allow you to copy or download
          certain Content, but please remember that even where these
          functionalities exist, all the restrictions in this section still
          apply.
        </p>
        <h4>Who is responsible for what I see and do on the Services?</h4>
        <p>
          Any information or Content publicly posted or privately transmitted
          through the Services is the sole responsibility of the person from
          whom such Content originated, and you access all such information and
          Content at your own risk, and we aren’t liable for any errors or
          omissions in that information or Content or for any damages or loss
          you might suffer in connection with it. We cannot control and have no
          duty to take any action regarding how you may interpret and use the
          Content or what actions you may take as a result of having been
          exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Services.
          We can’t guarantee the identity of any users with whom you interact in
          using the Services and are not responsible for which users gain access
          to the Services.{" "}
        </p>
        <p>
          You are responsible for all Content you contribute, in any manner, to
          the Services, and you represent and warrant you have all rights
          necessary to do so, in the manner in which you contribute it. If you
          contribute or otherwise use or submit any Content through the Services
          that contains personal information of a child under the age of 13, you
          represent and warrant that you are the parent or legal guardian of
          such child and you hereby consent to our use of such personal
          information in connection with providing the Services.
        </p>
        <p>
          The Services may contain links or connections to third-party websites
          or services that are not owned or controlled by Orbit Contacts. When
          you access third-party websites or use third-party services, you
          accept that there are risks in doing so, and that Orbit Contacts is
          not responsible for such risks.
        </p>
        <p>
          Orbit Contacts has no control over, and assumes no responsibility for,
          the content, accuracy, privacy policies, or practices of or opinions
          expressed in any third-party websites or by any third party that you
          interact with through the Services. In addition, Orbit Contacts will
          not and cannot monitor, verify, censor or edit the content of any
          third-party site or service. We encourage you to be aware when you
          leave the Services and to read the terms and conditions and privacy
          policy of each third-party website or service that you visit or
          utilize. By using the Services, you release and hold us harmless from
          any and all liability arising from your use of any third-party website
          or service.
        </p>
        <p>
          Your interactions with organizations and/or individuals found on or
          through the Services, including payment and delivery of goods or
          services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with
          any online or offline transaction with any of these third parties. You
          agree that Orbit Contacts shall not be responsible or liable for any
          loss or damage of any sort incurred as the result of any such
          dealings.
        </p>
        <p>
          If there is a dispute between participants on this site or Services,
          or between users and any third party, you agree that Orbit Contacts is
          under no obligation to become involved. In the event that you have a
          dispute with one or more other users, you release Orbit Contacts, its
          directors, officers, employees, agents, and successors from claims,
          demands, and damages of every kind or nature, known or unknown,
          suspected or unsuspected, disclosed or undisclosed, arising out of or
          in any way related to such disputes and/or our Services. You shall and
          hereby do waive California Civil Code Section 1542 or any similar law
          of any jurisdiction, which says in substance: “A general release does
          not extend to claims that the creditor or releasing party does not
          know or suspect to exist in his or her favor at the time of executing
          the release and that, if known by him or her, would have materially
          affected his or her settlement with the debtor or released party.”
        </p>
        <h4>Will Orbit Contacts ever change the Services?</h4>
        <p>
          We’re always trying to improve our Services, so they may change over
          time. We may suspend or discontinue any part of the Services, or we
          may introduce new features or impose limits on certain features or
          restrict access to parts or all of the Services. We’ll try to give you
          notice when we make a material change to the Services that would
          adversely affect you, but this isn’t always practical. We reserve the
          right to remove any Content from the Services at any time, for any
          reason (including, but not limited to, if someone alleges you
          contributed that Content in violation of these Terms), in our sole
          discretion, and without notice.
        </p>
        <h4>Do the Services cost anything?</h4>
        <p>
          The Services may be free or we may charge a fee for using the
          Services. If you are using a free version of the Services, we will
          notify you before any Services you are then using begin carrying a
          fee, and if you wish to continue using such Services, you must pay all
          applicable fees for such Services. Note that if you elect to receive
          text messages through the Services, data and message rates may apply.
          Any and all such charges, fees or costs are your sole responsibility.
          You should consult with your wireless carrier to determine what rates,
          charges, fees or costs may apply to your use of the Services.
        </p>
        <ol className="primary" type="a">
          <li>
            <u>Paid Services.</u> Certain of our Services may be subject to
            payments now or in the future (the “Paid Services”). Please note
            that any payment terms presented to you in the process of using or
            signing up for a Paid Service are deemed part of these Terms.
          </li>
          <br />
          <li>
            <u>Billing.</u> We use a third-party payment processor (the “Payment
            Processor”) to bill you through a payment account linked to your
            account on the Services (your “Billing Account”) for use of the Paid
            Services. The processing of payments will be subject to the terms,
            conditions and privacy policies of the Payment Processor in addition
            to these Terms. Currently, we use Stripe, Inc. as our Payment
            Processor. You can access Stripe’s Terms of Service at
            https://stripe.com/us/checkout/legal and their Privacy Policy at
            https://stripe.com/us/privacy. We are not responsible for any error
            by, or other acts or omissions of, the Payment Processor. By
            choosing to use Paid Services, you agree to pay us, through the
            Payment Processor, all charges at the prices then in effect for any
            use of such Paid Services in accordance with the applicable payment
            terms, and you authorize us, through the Payment Processor, to
            charge your chosen payment provider (your “Payment Method”). You
            agree to make payment using that selected Payment Method. We reserve
            the right to correct any errors or mistakes that the Payment
            Processor makes even if it has already requested or received
            payment.
          </li>
          <br />
          <li>
            <u>Payment Method.</u> The terms of your payment will be based on
            your Payment Method and may be determined by agreements between you
            and the financial institution, credit card issuer or other provider
            of your chosen Payment Method. If we, through the Payment Processor,
            do not receive payment from you, you agree to pay all amounts due on
            your Billing Account upon demand.
          </li>
          <br />
          <li>
            <u>Recurring Billing.</u> Some of the Paid Services may consist of
            an initial period, for which there is a one-time charge, followed by
            recurring period charges as agreed to by you. By choosing a
            recurring payment plan, you acknowledge that such Services have an
            initial and recurring payment feature and you accept responsibility
            for all recurring charges prior to cancellation. WE MAY SUBMIT
            PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM
            YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED
            BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE
            YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED
            BEFORE WE REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR
            CHANGE YOUR PAYMENT METHOD, GO TO ACCOUNT SETTINGS.
          </li>
          <br />
          <li>
            <u>Current Information Required.</u> YOU MUST PROVIDE CURRENT,
            COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST
            PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT
            CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS,
            CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST
            PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD
            IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A
            POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR
            USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN
            BE MADE AT ACCOUNT SETTINGS. IF YOU FAIL TO PROVIDE ANY OF THE
            FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU
            FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU
            HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.
          </li>
          <br />
          <li>
            <u>Change in Amount Authorized.</u> If the amount to be charged to
            your Billing Account varies from the amount you preauthorized (other
            than due to the imposition or change in the amount of state sales
            taxes), you have the right to receive, and we shall provide, notice
            of the amount to be charged and the date of the charge before the
            scheduled date of the transaction. Any agreement you have with your
            payment provider will govern your use of your Payment Method. You
            agree that we may accumulate charges incurred and submit them as one
            or more aggregate charges during or at the end of each billing
            cycle.
          </li>
          <br />
          <li>
            <u>Auto-Renewal for Paid Services.</u> Unless you opt out of
            auto-renewal, which can be done through your account settings, any
            Paid Services you have signed up for will be automatically extended
            for successive renewal periods of the same duration as the
            subscription term originally selected, at the then-current
            non-promotional rate. To change or resign your Paid Services at any
            time, go to account settings. If you terminate a Paid Service, you
            may use your subscription until the end of your then-current term,
            and your subscription will not be renewed after your then-current
            term expires. However, you will not be eligible for a prorated
            refund of any portion of the subscription fee paid for the
            then-current subscription period. IF YOU DO NOT WANT TO CONTINUE TO
            BE CHARGED ON A RECURRING MONTHLY BASIS, YOU MUST CANCEL THE
            APPLICABLE PAID SERVICE THROUGH YOUR ACCOUNT SETTINGS OR TERMINATE
            YOUR Orbit Contacts ACCOUNT BEFORE THE END OF THE RECURRING TERM.
            PAID SERVICES CANNOT BE TERMINATED BEFORE THE END OF THE PERIOD FOR
            WHICH YOU HAVE ALREADY PAID, AND EXCEPT AS EXPRESSLY PROVIDED IN
            THESE TERMS, Orbit Contacts WILL NOT REFUND ANY FEES THAT YOU HAVE
            ALREADY PAID.
          </li>
          <br />
          <li>
            <u>Reaffirmation of Authorization.</u> Your non-termination or
            continued use of a Paid Service reaffirms that we are authorized to
            charge your Payment Method for that Paid Service. We may submit
            those charges for payment and you will be responsible for such
            charges. This does not waive our right to seek payment directly from
            you. Your charges may be payable in advance, in arrears, per usage,
            or as otherwise described when you initially selected to use the
            Paid Service.
          </li>
          <br />
          <li>
            <u>Free Trials and Other Promotions.</u> Any free trial or other
            promotion that provides access to a Paid Service must be used within
            the specified time of the trial. You must stop using a Paid Service
            before the end of the trial period in order to avoid being charged
            for that Paid Service. If you cancel prior to the end of the trial
            period and are inadvertently charged for a Paid Service, please
            contact us at support@orbitcontacts.com.
          </li>
        </ol>
        <h4>What if I want to stop using the Services? </h4>
        <p>
          You’re free to do that at any time by contacting us at
          support@orbitcontacts.com please refer to our Privacy Policy, as well
          as the licenses above, to understand how we treat information you
          provide to us after you have stopped using our Services.
        </p>
        <p>
          Orbit Contacts is also free to terminate (or suspend access to) your
          use of the Services or your account for any reason in our discretion,
          including your breach of these Terms. Orbit Contacts has the sole
          right to decide whether you are in violation of any of the
          restrictions set forth in these Terms.
        </p>
        <p>
          Account termination may result in destruction of any Content
          associated with your account, so keep that in mind before you decide
          to terminate your account.
        </p>
        <p>
          If you have deleted your account by mistake, contact us immediately at
          support@orbitcontacts.com – we will try to help, but unfortunately, we
          can’t promise that we can recover or restore anything.
        </p>
        <p>
          Provisions that, by their nature, should survive termination of these
          Terms shall survive termination. By way of example, all of the
          following will survive termination: any obligation you have to pay us
          or indemnify us, any limitations on our liability, any terms regarding
          ownership or intellectual property rights, and terms regarding
          disputes between us, including without limitation the arbitration
          agreement.
        </p>
        <h4>What about Mobile Applications?</h4>
        <p>
          You acknowledge and agree that the availability of our mobile
          application is dependent on the third party stores from which you
          download the application, e.g., the App Store from Apple or the
          Android app market from Google (each an “App Store”). Each App Store
          may have its own terms and conditions to which you must agree before
          downloading mobile applications from such store, including the
          specific terms relating to Apple App Store set forth below. You agree
          to comply with, and your license to use our application is conditioned
          upon your compliance with, such App Store terms and conditions. To the
          extent such other terms and conditions from such App Store are less
          restrictive than, or otherwise conflict with, the terms and conditions
          of these Terms of Use, the more restrictive or conflicting terms and
          conditions in these Terms of Use apply.
        </p>
        <h4>
          I use the Orbit Contacts App available via the Apple App Store –
          should I know anything about that?
        </h4>
        <p>
          These Terms apply to your use of all the Services, including our iOS
          applications (the “Application”) available via the Apple, Inc.
          (“Apple”) App Store, but the following additional terms also apply to
          the Application:
        </p>
        <ol className="primary" type="a">
          <li>
            Both you and Orbit Contacts acknowledge that the Terms are concluded
            between you and Orbit Contacts only, and not with Apple, and that
            Apple is not responsible for the Application or the Content;
          </li>
          <br />
          <li>
            The Application is licensed to you on a limited, non-exclusive,
            non-transferrable, non-sublicensable basis, solely to be used in
            connection with the Services for your private, personal,
            non-commercial use, subject to all the terms and conditions of these
            Terms as they are applicable to the Services;
          </li>
          <br />
          <li>
            You will only use the Application in connection with an Apple device
            that you own or control;
          </li>
          <br />
          <li>
            You acknowledge and agree that Apple has no obligation whatsoever to
            furnish any maintenance and support services with respect to the
            Application;
          </li>
          <br />
          <li>
            In the event of any failure of the Application to conform to any
            applicable warranty, including those implied by law, you may notify
            Apple of such failure; upon notification, Apple’s sole warranty
            obligation to you will be to refund to you the purchase price, if
            any, of the Application;
          </li>
          <br />
          <li>
            You acknowledge and agree that Orbit Contacts, and not Apple, is
            responsible for addressing any claims you or any third party may
            have in relation to the Application;
          </li>
          <br />
          <li>
            You acknowledge and agree that, in the event of any third-party
            claim that the Application or your possession and use of the
            Application infringes that third party’s intellectual property
            rights, Orbit Contacts, and not Apple, will be responsible for the
            investigation, defense, settlement and discharge of any such
            infringement claim;
          </li>
          <br />
          <li>
            You represent and warrant that you are not located in a country
            subject to a U.S. Government embargo, or that has been designated by
            the U.S. Government as a “terrorist supporting” country, and that
            you are not listed on any U.S. Government list of prohibited or
            restricted parties;
          </li>
          <br />
          <li>
            Both you and Orbit Contacts acknowledge and agree that, in your use
            of the Application, you will comply with any applicable third-party
            terms of agreement which may affect or be affected by such use; and
          </li>
          <br />
          <li>
            Both you and Orbit Contacts acknowledge and agree that Apple and
            Apple’s subsidiaries are third-party beneficiaries of these Terms,
            and that upon your acceptance of these Terms, Apple will have the
            right (and will be deemed to have accepted the right) to enforce
            these Terms against you as the third-party beneficiary hereof.
          </li>
        </ol>
        <h4>What else do I need to know?</h4>
        <p>
          <u>
            <i>Warranty Disclaimer.</i>
          </u>{" "}
          Orbit Contacts and its licensors, suppliers, partners, parent,
          subsidiaries or affiliated entities, and each of their respective
          officers, directors, members, employees, consultants, contract
          employees, representatives and agents, and each of their respective
          successors and assigns (Orbit Contacts and all such parties together,
          the “Orbit Contacts Parties”) make no representations or warranties
          concerning the Services, including without limitation regarding any
          Content contained in or accessed through the Services, and the Orbit
          Contacts Parties will not be responsible or liable for the accuracy,
          copyright compliance, legality, or decency of material contained in or
          accessed through the Services or any claims, actions, suits
          procedures, costs, expenses, damages or liabilities arising out of use
          of, or in any way related to your participation in, the Services. The
          Orbit Contacts Parties make no representations or warranties regarding
          suggestions or recommendations of services or products offered or
          purchased through or in connection with the Services. THE SERVICES AND
          CONTENT ARE PROVIDED BY Orbit Contacts (AND ITS LICENSORS AND
          SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES
          OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR
          ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
          IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>
        <p>
          <u>
            <i>Limitation of Liability. </i>
          </u>{" "}
          TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO
          CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING, WITHOUT
          LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY
          OF THE Orbit Contacts PARTIES BE LIABLE TO YOU OR TO ANY OTHER PERSON
          FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL
          DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS
          INTERRUPTION, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY
          OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE
          GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN
          EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE
          AMOUNTS PAID AND/OR PAYABLE BY YOU TO Orbit Contacts IN CONNECTION
          WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS
          APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME
          STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
          CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE LIMITATION AND
          EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>
        <p>
          <u>
            <i>Indemnity. </i>
          </u>{" "}
          You agree to indemnify and hold the Orbit Contacts Parties harmless
          from and against any and all claims, liabilities, damages (actual and
          consequential), losses and expenses (including attorneys’ fees)
          arising from or in any way related to any claims relating to (a) your
          use of the Services (including any actions taken by a third party
          using your account), and (b) your violation of these Terms. In the
          event of such a claim, suit, or action (“Claim”), we will attempt to
          provide notice of the Claim to the contact information we have for
          your account (provided that failure to deliver such notice shall not
          eliminate or reduce your indemnification obligations hereunder).
        </p>
        <p>
          <u>
            <i>Assignment. </i>
          </u>{" "}
          You may not assign, delegate or transfer these Terms or your rights or
          obligations hereunder, or your Services account, in any way (by
          operation of law or otherwise) without Orbit Contacts's prior written
          consent. We may transfer, assign, or delegate these Terms and our
          rights and obligations without consent.
        </p>
        <p>
          <u>
            <i>Choice of Law. </i>
          </u>
          These Terms are governed by and will be construed under the Federal
          Arbitration Act, applicable federal law, and the laws of the State of
          New York, without regard to the conflicts of laws provisions thereof.
        </p>
        <p>
          <u>
            <i>Arbitration Agreement. </i>
          </u>
          Please read the following ARBITRATION AGREEMENT carefully because it
          requires you to arbitrate certain disputes and claims with Orbit
          Contacts and limits the manner in which you can seek relief from Orbit
          Contacts. Both you and Orbit Contacts acknowledge and agree that for
          the purposes of any dispute arising out of or relating to the subject
          matter of these Terms, Orbit Contacts's officers, directors, employees
          and independent contractors (“Personnel”) are third-party
          beneficiaries of these Terms, and that upon your acceptance of these
          Terms, Personnel will have the right (and will be deemed to have
          accepted the right) to enforce these Terms against you as the
          third-party beneficiary hereof.
        </p>
        <p>
          (a) <i>Arbitration Rules; </i> Applicability of Arbitration Agreement.
          The parties shall use their best efforts to settle any dispute, claim,
          question, or disagreement arising out of or relating to the subject
          matter of these Terms directly through good-faith negotiations, which
          shall be a precondition to either party initiating arbitration. If
          such negotiations do not resolve the dispute, it shall be finally
          settled by binding arbitration in New York County, New York. The
          arbitration will proceed in the English language, in accordance with
          the JAMS Streamlined Arbitration Rules and Procedures (the “Rules”)
          then in effect, by one commercial arbitrator with substantial
          experience in resolving intellectual property and commercial contract
          disputes. The arbitrator shall be selected from the appropriate list
          of JAMS arbitrators in accordance with such Rules. Judgment upon the
          award rendered by such arbitrator may be entered in any court of
          competent jurisdiction.
        </p>
        <p>
          (b) <i>Costs of Arbitration. </i> The Rules will govern payment of all
          arbitration fees. Orbit Contacts will pay all arbitration fees for
          claims less than seventy-five thousand ($75,000) dollars. Orbit
          Contacts will not seek its attorneys’ fees and costs in arbitration
          unless the arbitrator determines that your claim is frivolous.{" "}
        </p>
        <p>
          (c) <i>Small Claims Court; </i> Infringement. Either you or Orbit
          Contacts may assert claims, if they qualify, in small claims court in
          New York County, New York or any United States county where you live
          or work. Furthermore, notwithstanding the foregoing obligation to
          arbitrate disputes, each party shall have the right to pursue
          injunctive or other equitable relief at any time, from any court of
          competent jurisdiction, to prevent the actual or threatened
          infringement, misappropriation or violation of a party's copyrights,
          trademarks, trade secrets, patents or other intellectual property
          rights.
        </p>
        <p>
          (d) <i>Waiver of Jury Trial. </i> YOU AND Orbit Contacts WAIVE ANY
          CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
          FRONT OF A JUDGE OR JURY. You and Orbit Contacts are instead choosing
          to have claims and disputes resolved by arbitration. Arbitration
          procedures are typically more limited, more efficient, and less costly
          than rules applicable in court and are subject to very limited review
          by a court. In any litigation between you and Orbit Contacts over
          whether to vacate or enforce an arbitration award, YOU AND Orbit
          Contacts WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead to have
          the dispute be resolved by a judge.
        </p>
        <p>
          (e) <i>Waiver of Class or Consolidated Actions. </i> ALL CLAIMS AND
          DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
          ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
          BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED
          OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER
          OR USER. If however, this waiver of class or consolidated actions is
          deemed invalid or unenforceable, neither you nor Orbit Contacts is
          entitled to arbitration; instead all claims and disputes will be
          resolved in a court as set forth in (g) below.
        </p>
        <p>
          (f) <i>Opt-out. </i> You have the right to opt out of the provisions
          of this Section by sending written notice of your decision to opt out
          to the following address: privacy@orbitcontacts.com postmarked within
          thirty (30) days of first accepting these Terms. You must include (i)
          your name and residence address, (ii) the email address and/or
          telephone number associated with your account, and (iii) a clear
          statement that you want to opt out of these Terms’ arbitration
          agreement.
        </p>
        <p>
          (g) <i>Exclusive Venue. </i> If you send the opt-out notice in (f),
          and/or in any circumstances where the foregoing arbitration agreement
          permits either you or Orbit Contacts to litigate any dispute arising
          out of or relating to the subject matter of these Terms in court, then
          the foregoing arbitration agreement will not apply to either party,
          and both you and Orbit Contacts agree that any judicial proceeding
          (other than small claims actions) will be brought in the state or
          federal courts located in, respectively, New York County, New York, or
          the federal district in which that county falls.
        </p>
        <p>
          (h) <i>Severability. </i> If the prohibition against class actions and
          other claims brought on behalf of third parties contained above is
          found to be unenforceable, then all of the preceding language in this
          Arbitration Agreement section will be null and void. This arbitration
          agreement will survive the termination of your relationship with Orbit
          Contacts.
        </p>
        <p>
          <u>
            <i>Miscellaneous.</i>
          </u>{" "}
          You will be responsible for paying, withholding, filing, and reporting
          all taxes, duties, and other governmental assessments associated with
          your activity in connection with the Services, provided that the Orbit
          Contacts may, in its sole discretion, do any of the foregoing on your
          behalf or for itself as it sees fit. The failure of either you or us
          to exercise, in any way, any right herein shall not be deemed a waiver
          of any further rights hereunder. If any provision of these Terms are
          found to be unenforceable or invalid, that provision will be limited
          or eliminated, to the minimum extent necessary, so that these Terms
          shall otherwise remain in full force and effect and enforceable. You
          and Orbit Contacts agree that these Terms are the complete and
          exclusive statement of the mutual understanding between you and Orbit
          Contacts, and that these Terms supersede and cancel all previous
          written and oral agreements, communications and other understandings
          relating to the subject matter of these Terms. You hereby acknowledge
          and agree that you are not an employee, agent, partner, or joint
          venture of Orbit Contacts, and you do not have any authority of any
          kind to bind Orbit Contacts in any respect whatsoever.
        </p>
        <p>
          Except as expressly set forth in the sections above regarding the
          Apple Application and the arbitration agreement, you and Orbit
          Contacts agree there are no third-party beneficiaries intended under
          these Terms.
        </p>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
