import { Reducer } from "redux";
import update from "immutability-helper";
import {
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  EDIT_EXTRA_DATA_IN_STATE,
  CHECK_EXTRA_USER_DATA,
  GET_CURRENT_USER_DATA_SUCCESS,
  EDIT_CHANGE_PASSWORD_ERROR,
  CHANGE_USER_PASSWORD_FINISH,
  CHANGE_USER_PASSWORD_START,
  UPDATE_USER_AFTER_PROFILE_PICTURE_CHANGE,
  UPDATE_PROFILE_PICTURE_FINISH,
  UPDATE_PROFILE_PICTURE_START,
} from "../actions";
import { Action } from "../Constants/types";

interface authRootState {
  userData: any;
  loginError: string;
  signupError: string;
  changePasswordError: any;
  changePasswordProcessing: boolean;
  changeProfilePictureProcessing: boolean;
  signedinithGoogle: boolean;
  hasSubscribed: boolean;
  processing: boolean;
  ready: boolean;
}

const initialState: authRootState = {
  userData: null,
  loginError: "",
  signupError: "",
  changePasswordError: null,
  changePasswordProcessing: false,
  changeProfilePictureProcessing: false,
  signedinithGoogle: false,
  hasSubscribed: false,
  processing: false,
  ready: false,
};

const authReducer: Reducer<authRootState, Action> = (
  state: authRootState = initialState,
  action: Action
) => {
  const newState = { ...state };

  switch (action.type) {
    case LOGIN:
    case SIGNUP:
      return { ...state, processing: true };

    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      return { ...state, processing: false, userData: action.value };

    case LOGIN_ERROR:
      return { ...state, processing: false, loginError: action.value };
    case SIGNUP_ERROR:
      return { ...state, processing: false, signupError: action.value };

    case GET_CURRENT_USER_DATA_SUCCESS:
      return { ...state, userData: action.value };

    case CHECK_EXTRA_USER_DATA:
      return { ...state, ready: false };
    case EDIT_EXTRA_DATA_IN_STATE:
      return { ...state, ...action.value, ready: true };

    case CHANGE_USER_PASSWORD_START:
      return { ...state, changePasswordProcessing: true };
    case CHANGE_USER_PASSWORD_FINISH:
      return { ...state, changePasswordProcessing: false };
    case UPDATE_PROFILE_PICTURE_START:
      return { ...state, changeProfilePictureProcessing: true };
    case UPDATE_PROFILE_PICTURE_FINISH:
      return { ...state, changeProfilePictureProcessing: false };
    case EDIT_CHANGE_PASSWORD_ERROR:
      return { ...state, changePasswordError: action.value };
    case UPDATE_USER_AFTER_PROFILE_PICTURE_CHANGE:
      return update(newState, {
        userData: {
          pictureUrl: { $set: action.value },
        },
      });
  }
  return newState;
};
export default authReducer;
