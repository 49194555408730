import React, { useEffect, useState } from "react";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import { WARM_GRAY } from "../../../Constants/colorsPalette";
import { Contact, RootState } from "../../../Constants/types";
import OrbitNumber from "../table components/OrbitNumber";
import TagsList from "../table components/TagsList";
import closeIcon from "../../../assets/x.svg";
import { useDispatch, useSelector } from "react-redux";
import { UNSHOW_CONTACT } from "../../../actions";
import CookieService from "../../../Cookies/CookieService";
import axios from "axios";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import ThirdSection from "./ThirdSection";
import FourthSection from "./FourthSection";

type props = {};

export default function ContactCard({}: props) {
  const selectedContact = useSelector(
    (state: RootState) => state.contacts.displayedContact
  );

  const [displayedContact, setDisplayedContact] = useState(selectedContact);

  useEffect(() => {
    setDisplayedContact(selectedContact);
  }, [selectedContact]);

  return (
    <div className="contact-card-body">
      <FirstSection contact={displayedContact} />
      <div className="similar-section">
        <ThirdSection contact={displayedContact} />
        <FourthSection contact={displayedContact} />
      </div>
    </div>
  );
}
