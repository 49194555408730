import { CLEAR_ALL_FILTERS, SELECT_FLOW, SET_SELECTED_FLOW } from "../actions";
import { put, select, takeLeading } from "redux-saga/effects";
import { Action, RootState } from "../Constants/types";

import "firebase/storage";

function* selectFlow(action: Action) {
  const selectedFlow: string | undefined = yield select(
    (state: RootState) => state.flows.selectedFlow
  );

  if (selectedFlow && selectedFlow === action.value.flowTitle) {
    //we are already viewing this flow
    yield put({ type: SET_SELECTED_FLOW, value: undefined });
  } else {
    yield put({ type: SET_SELECTED_FLOW, value: action.value.flowTitle });
  }

  //TODO: set contacts in contacts reducer state to the contacts of the selected flow
  yield put({ type: CLEAR_ALL_FILTERS });
}

export default function* filtersSaga() {
  yield takeLeading(SELECT_FLOW, selectFlow);
}
