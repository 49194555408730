import React from "react";

import logo from "../../assets/OrbitLogo_Purple.png";
import { PRIMARY } from "../../Constants/colorsPalette";
import FlatButton from "../shared/FlatButton";
import RaisedButton from "../shared/RaisedButton";
import "./LandingPage.css";

export default function LandingPage() {
  return (
    <div className="landing-container">
      <div className="nav-bar row">
        <img id="logo" src={logo} />
        <RaisedButton
          style={{ backgroundColor: PRIMARY, width: "172px" }}
          dark
          id="loginBtn_1"
          text="LOGIN"
          to="/login"
        />
      </div>
      <div className="landing-content column">
        <h1>Orbit Contacts</h1>
        <p>
          Harness the power and joy of your contacts by finding who you want to
          talk to right when you need them.
        </p>
        <br />
        <br />
        <div className="fixed-row">
          <RaisedButton
            dark
            text="SIGN UP"
            to="/welcome"
            style={{ maxWidth: "285px" }}
          />
          <RaisedButton id="loginBtn_2" dark text="LOGIN" to="/login" />
        </div>
      </div>
      <div className="landing-footer row">
        <FlatButton to="/privacy" text="Privacy Policy" />
      </div>
    </div>
  );
}
