import React, { useEffect, useState } from "react";
import { Contact, FlowStatus, PhoneNumber } from "../../../Constants/types";
import { timeSince } from "../../../functions";
import ContactAvatar from "./ContactAvatar";
import EditableCell from "./EditableCell";
import OrbitNumber from "./OrbitNumber";
import StatusTag from "./StatusTag";
import TagsList from "./TagsList";
import defaultAvatar from "../../../assets/avatar_placeholder.svg";
import axios from "axios";
import { Orbit } from "../../../Constants/types";
import CookieService from "../../../Cookies/CookieService";

type props = {
  contact: Contact;
  fieldKey: any;
  index: number;
  rowIndex: number;
  spans: number[];
  status?: FlowStatus;
};

export default function TableRow({
  contact,
  fieldKey,
  index,
  rowIndex,
  spans,
  status,
}: props) {
  if (status && index === 0) {
    return (
      <StatusTag
        key={"status-" + rowIndex + index + status.toString()}
        colSpan={spans[0]}
        status={status}
      />
    );
  } else if (fieldKey === "orbit") {
    return (
      <OrbitNumber
        key={"orbit-" + rowIndex + index}
        colSpan={spans[index]}
        contact={contact}
      />
    );
  } else if (fieldKey === "pictureUrl") {
    if (contact.pictureUrl === "") {
      return (
        <ContactAvatar
          key={"avatar-" + rowIndex + index}
          colSpan={spans[index]}
          src={defaultAvatar}
        />
      );
    } else {
      return (
        <ContactAvatar
          key={"avatar-" + rowIndex + index}
          colSpan={spans[index]}
          src={contact.pictureUrl}
        />
      );
    }
  } else if (fieldKey === "lists") {
    return (
      <TagsList
        key={"tags-" + rowIndex + index}
        colSpan={spans[index]}
        contact={contact}
      />
    );
  } else if (fieldKey === "phoneNumbers") {
    return (
      <EditableCell
        key={"data-" + rowIndex + index}
        colSpan={spans[index]}
        data={
          contact["phoneNumbers"]
            ? contact["phoneNumbers"][0]?.number.split(" ").join("")
            : ""
        }
        disabled
        isEditable
        onDoneClick={() => {}}
        attributeChanged={fieldKey}
        contact={contact}
      />
    );
  } else if (fieldKey === "lastContacted") {
    const dateSince: string =
      contact.lastContacted !== " " && contact.lastContacted !== ""
        ? timeSince(new Date(contact.lastContacted * 1000))
        : "Never";

    return (
      <EditableCell
        key={"date-" + rowIndex + index}
        colSpan={spans[index]}
        data={dateSince}
        isEditable={false}
        attributeChanged=""
        disabled
        onDoneClick={() => {}}
      />
    );
  }
  const contactKey: keyof Contact = fieldKey;
  return (
    <EditableCell
      key={"data-" + rowIndex + index}
      colSpan={spans[index]}
      data={contact[contactKey]?.toString()}
      disabled
      isEditable
      onDoneClick={() => {}}
      attributeChanged={fieldKey}
      contact={contact}
    />
  );
}
