import React, { SVGProps } from "react";

type props = SVGProps<SVGSVGElement>;

export function ArrowRight({
  width = "24",
  height = "24",
  ...svgProps
}: props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 24"
      {...svgProps}
    >
      <path d="M0 0 L12 12 l-12 12 Z" />
    </svg>
  );
}

export function ArrowDown({ width = "24", height = "24", ...svgProps }: props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 12"
      {...svgProps}
    >
      <path d="M0 0 L24 0 l-12 12 Z" />
    </svg>
  );
}
