import React, { useState } from "react";
import { Contact } from "../../../Constants/types";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import { ADD_NOTE_TO_CONTACT, UNSHOW_CONTACT } from "../../../actions";
import { useDispatch, useSelector } from "react-redux";
import OrbitButton from "../../shared/OrbitButton";
import { timeSince } from "../../../functions";
import OrbitNumber from "../table components/OrbitNumber";
import TagsList from "../table components/TagsList";
import notesIcon from "../../../assets/align-left.svg";

type props = {
  contact: Contact;
};

export default function FirstSection({ contact }: props) {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();

  return (
    <div className="first-section">
      <div className="profile-info">
        <img
          id="preview"
          className="avatar-img"
          src={
            contact?.pictureUrl !== "" ? contact?.pictureUrl : avatarPlaceholder
          }
          alt=""
        />
        <div className="name-orbit-tags-container">
          <span className="full-name">
            {contact?.givenName} {contact?.surname}
          </span>
          <div className="orbit-tags-container">
            <OrbitNumber contact={contact} colSpan={1} />
            <div className="tags-list" style={{ marginLeft: 16 }}>
              <TagsList contact={contact} />
            </div>
          </div>
        </div>
      </div>

      <div className="notes-section">
        <span className="last-contacted">
          Last contacted{" "}
          {contact.lastContacted !== " " && contact.lastContacted !== ""
            ? timeSince(new Date(contact.lastContacted * 1000))
            : "Never"}
        </span>
        <div className="note-input">
          <img src={notesIcon} width={25} height={25} />
          <input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder="Quick Note. Press enter to save."
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                dispatch({
                  type: ADD_NOTE_TO_CONTACT,
                  value: { contactId: contact.id, note: value },
                });
                setValue("");
              }
            }}
          />
        </div>
      </div>
      <OrbitButton
        text="x"
        white
        style={{
          fontSize: 20,
          fontWeight: 700,
          height: 18,
          width: 18,
          paddingBottom: 5,
          position: "absolute",
          right: 15,
          top: 15,
        }}
        onClick={() => {
          dispatch({ type: UNSHOW_CONTACT });
        }}
      />
    </div>
  );
}
