import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_LIST_TO_CONTACT,
  DELETE_LIST_FROM_CONTACT,
  SET_CHANGE_IN_CONTACTS_LIST,
} from "../../../actions";
import { Contact, List, RootState } from "../../../Constants/types";
import xIcon from "../../../assets/x.svg";

import Downshift from "downshift";

type props = {
  contact: Contact;
  colSpan?: number;
};

export default function TagsList({ colSpan, contact }: props) {
  const tags = useSelector((state: RootState) => state.filtersAndLists.tags);

  const [value, setValue] = useState("");
  const [availableTags, setAvailableTags] = useState(contact.lists);

  const dispatch = useDispatch();

  useEffect(() => {
    setAvailableTags(contact.lists);
  }, [contact]);

  return (
    <td colSpan={colSpan} className="tags-list">
      <div className="row">
        {availableTags?.map((list) => (
          <div key={"list-" + list} className="list-tag">
            {list}
            <div
              className="delete-icon centered"
              onClick={() => {
                dispatch({
                  type: DELETE_LIST_FROM_CONTACT,
                  value: { contactId: contact.id, tag: list },
                });
                setAvailableTags(availableTags.filter((tag) => tag !== list));
              }}
            ></div>
            {/* <img src={xIcon} className="delete-icon" /> */}
          </div>
        ))}
        <div key={"list-test"} style={{ display: "flex" }}>
          <Downshift
            onChange={(selection) => {
              if (
                selection !== null &&
                tags.includes(selection.toLowerCase()) &&
                !availableTags.includes(selection.toLowerCase())
              ) {
                dispatch({
                  type: ADD_LIST_TO_CONTACT,
                  value: { contactId: contact.id, tag: selection },
                });
                let newAvaialbleTags = availableTags?.concat([
                  selection.toLowerCase(),
                ]);
                setAvailableTags(newAvaialbleTags);
              }
              setValue("");
            }}
            inputValue={value}
            onInputValueChange={(newValue) => setValue(newValue)}
            itemToString={(item) => (item ? item : "")}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              inputValue,
              isOpen,
            }) => (
              <div>
                {/* <label {...getLabelProps()}>Enter a fruit:</label> */}
                <input
                  className="list-tag test"
                  style={{ width: 48 }}
                  {...getInputProps()}
                />
                <ul {...getMenuProps()} className="options-menu">
                  {isOpen &&
                    tags
                      .filter(
                        (tag) =>
                          !inputValue || tag.includes(inputValue.toLowerCase())
                      )
                      .map((item, index) => (
                        <li
                          className="options-menu-item"
                          {...getItemProps({
                            key: `${item}${index}`,
                            item,
                            index,
                          })}
                        >
                          {item}
                        </li>
                      ))}
                </ul>
              </div>
            )}
          </Downshift>
        </div>
      </div>
    </td>
  );
}
