import React, { useEffect, useState } from "react";
import AddContactAvatar from "../../../assets/AddContactAvatar.png";
import OrbitNumber from "./diff-version-components/OrbitNumber";
import TagsList from "./diff-version-components/TagsList";
import notesIcon from "../../../assets/align-left.svg";
import OrbitButton from "../../shared/OrbitButton";
import mailIcon from "../../../assets/mail.svg";
import phoneIcon from "../../../assets/phone.svg";
import locationIcon from "../../../assets/map-pin.svg";
import avatarPlaceholder from "../../../assets/avatar_placeholder.svg";
import xIcon from "../../../assets/x.svg";
import purpleXIcon from "../../../assets/purple-x.svg";
import editIcon from "../../../assets/pencil-fill.svg";
import DownshiftForLinking from "../../ContactsTable/ContactCard/DownshitForLinking";
import { useDispatch, useSelector } from "react-redux";
import { KeyPerson, Note, RootState } from "../../../Constants/types";
import { Done } from "@material-ui/icons";
import { ADD_CONTACT, UNSHOW_ADD_CONTACT } from "../../../actions";
import RaisedButton from "../../shared/RaisedButton";

export default function AddContactCard() {
  const contacts = useSelector(
    (state: RootState) => state.contacts.originalContacts
  );

  const addContactProcessing = useSelector(
    (state: RootState) => state.contacts.addContactProcessing
  );

  const [editing, setEditing] = useState(false);

  const [firstName, setFirstName] = useState("First Name");
  const [surname, setSurname] = useState("Surname");

  const [orbitNumber, setOrbitNumber] = useState(6);

  const [lists, setLists] = useState<string[]>([]);

  const [noteValue, setNoteValue] = useState("");

  const [personalEmail, setPersonalEmail] = useState("Add email");
  const [personalPhone, setPersonalPhone] = useState("Add phone");
  const [personalAddress, setPersonalAddress] = useState("Add address");

  const [professionalEmail, setProfessionalEmail] = useState("Add email");
  const [professionalPhone, setProfessionalPhone] = useState("Add phone");
  const [professionalAddress, setProfessionalAddress] = useState("Add address");

  const [addingKeyPerson, setAddingKeyPerson] = useState(false);
  const [addingDescription, setAddingDescription] = useState(false);

  const [existingKeyPerson, setExistingKeyPerson] = useState(undefined);
  const [nonExistingKeyPerson, setNonExistingKeyPerson] = useState("");
  const [description, setDescription] = useState("");

  const [keyPeople, setKeyPeople] = useState<KeyPerson[]>([]);

  const [partner, setPartner] = useState({
    givenName: "",
    id: "",
    pictureUrl: "",
    surname: "",
  });

  const [notes, setNotes] = useState<Note[]>([]);

  const [imageURL, setImageURL] = useState("");

  const dispatch = useDispatch();
  const handleImageFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      let image: File = e.target.files[0];
      getBase64(image, (result: any) => {
        setImageURL(result);
      });
    }
  };

  const getBase64 = (file: File, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const addPartnerToContact = (selectedItem: any) => {
    setPartner({
      id: selectedItem.id,
      givenName: selectedItem.givenName,
      surname: selectedItem.surname,
      pictureUrl: selectedItem.pictureUrl,
    });
  };
  const addNonExistingPartner = (value: any) => {
    setPartner({
      id: "",
      givenName: value,
      surname: "",
      pictureUrl: "",
    });
  };

  const removePartnerFromContact = () => {
    setPartner({
      id: "",
      givenName: "",
      surname: "",
      pictureUrl: "",
    });
  };

  const savingNonExistingKeyPerson = (value: any) => {
    setNonExistingKeyPerson(value);
    setAddingDescription(true);
  };

  const savingExistingKeyPerson = (selectedItem: any) => {
    setExistingKeyPerson(selectedItem);
    setAddingDescription(true);
  };

  const addExistingKeyPersonToContact = (selectedItem: any) => {
    const newKeyPeople = keyPeople?.concat({
      id: selectedItem.id,
      givenName: selectedItem.givenName,
      surname: selectedItem.surname,
      pictureUrl: selectedItem.pictureUrl,
      description,
    });
    setKeyPeople(newKeyPeople);
  };
  const addNonExistingKeyPersonToContact = (value: any) => {
    const newKeyPeople = keyPeople?.concat({
      id: "",
      givenName: nonExistingKeyPerson,
      surname: "",
      pictureUrl: "",
      description,
    });
    setKeyPeople(newKeyPeople);
  };

  const removeKeyPersonFromContact = (
    keyPerson: any,
    keyPersonIndex: number
  ) => {
    const newKeyPeople = keyPeople?.filter(
      (keyPerson, index) => index !== keyPersonIndex
    );
    setKeyPeople(newKeyPeople);
  };

  const resetKeyPersonAddition = () => {
    setAddingKeyPerson(false);
    setAddingDescription(false);
    setExistingKeyPerson(undefined);
    setNonExistingKeyPerson("");
    setDescription("");
  };

  const togglePinNote = (note: Note) => {
    if (!note.isPinned) {
      setNotes(
        [
          {
            id: note.id,
            createdAt: note.createdAt,
            isPinned: !note.isPinned,
            note: note.note,
          },
        ].concat(notes.filter((item) => item.note !== note.note))
      );
    } else {
      setNotes(
        notes
          .filter((item) => item.note !== note.note)
          .concat([
            {
              id: note.id,
              createdAt: note.createdAt,
              isPinned: !note.isPinned,
              note: note.note,
            },
          ])
      );
    }
  };

  const saveContact = () => {
    if (
      firstName !== "" ||
      surname !== "" ||
      personalEmail !== "" ||
      personalPhone !== "" ||
      professionalEmail !== "" ||
      professionalPhone !== ""
    ) {
      dispatch({
        type: ADD_CONTACT,
        value: {
          givenName: firstName,
          surname: surname,
          personalEmail: personalEmail,
          professionalEmail: professionalEmail,
          personalPhoneNumber: personalPhone,
          professionalPhoneNumber: professionalPhone,
          partner: partner,
          keyPeople: keyPeople,
          lists: lists,
          orbitNumber: orbitNumber,
          pictureUrl: imageURL,
          notes: notes,
        },
      });
    }
  };

  return (
    <div className="add-contact-card-body">
      <div className="first-section-container">
        <label>
          <img
            className="add-contact-avatar"
            src={imageURL === "" ? AddContactAvatar : imageURL}
          />
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              handleImageFile(e);
            }}
          />
        </label>
        <div className="name-orbit-lists">
          <div className="names">
            <input
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              onFocus={() =>
                firstName === "First Name" ? setFirstName("") : null
              }
              onBlur={() =>
                firstName === "" ? setFirstName("First Name") : null
              }
            />
            <input
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
              }}
              onFocus={() => (surname === "Surname" ? setSurname("") : null)}
              onBlur={() => (surname === "" ? setSurname("Surname") : null)}
            />
          </div>
          <div className="orbit-lists">
            <OrbitNumber
              currentValue={orbitNumber}
              updateCurrentValue={setOrbitNumber}
              colSpan={1}
            />
            <div style={{ marginLeft: 12 }}>
              <TagsList availableTags={lists} setAvailableTags={setLists} />
            </div>
          </div>
        </div>
        <div className="notes-container">
          <div className="note-input">
            <img src={notesIcon} width={25} height={25} />
            <input
              value={noteValue}
              onChange={(e) => {
                setNoteValue(e.target.value);
              }}
              placeholder="Quick Note. Press enter to save."
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setNotes(
                    notes.concat([
                      {
                        id: "",
                        createdAt: Date.now(),
                        isPinned: false,
                        note: noteValue,
                      },
                    ])
                  );
                  setNoteValue("");
                }
              }}
            />
          </div>
        </div>
        <OrbitButton
          text="x"
          white
          style={{
            fontSize: 20,
            fontWeight: 700,
            height: 18,
            width: 18,
            paddingBottom: 5,
            position: "absolute",
            right: 15,
            top: 15,
          }}
          onClick={() => {
            dispatch({ type: UNSHOW_ADD_CONTACT });
          }}
        />
      </div>
      <div className="second-section-container">
        <span className="title">Contact Details</span>
        <div className="details-container">
          <div className="personal-container">
            <span className="header">Personal</span>
            <div className="personal-detail">
              <img src={mailIcon} width={13} height={13} />
              <input
                value={personalEmail}
                onChange={(e) => {
                  setPersonalEmail(e.target.value);
                }}
                onFocus={() =>
                  personalEmail === "Add email" ? setPersonalEmail("") : null
                }
                onBlur={() =>
                  personalEmail === "" ? setPersonalEmail("Add email") : null
                }
              />
            </div>
            <div className="personal-detail">
              <img src={phoneIcon} width={13} height={13} />
              <input
                value={personalPhone}
                onChange={(e) => {
                  setPersonalPhone(e.target.value);
                }}
                onFocus={() =>
                  personalPhone === "Add phone" ? setPersonalPhone("") : null
                }
                onBlur={() =>
                  personalPhone === "" ? setPersonalPhone("Add phone") : null
                }
              />
            </div>
            <div className="personal-detail">
              <img src={locationIcon} width={13} height={13} />
              <input
                value={personalAddress}
                onChange={(e) => {
                  setPersonalAddress(e.target.value);
                }}
                onFocus={() =>
                  personalAddress === "Add address"
                    ? setPersonalAddress("")
                    : null
                }
                onBlur={() =>
                  personalAddress === ""
                    ? setPersonalAddress("Add address")
                    : null
                }
              />
            </div>
          </div>
          <div className="vertical-line"></div>
          <div className="personal-container">
            <span className="header">Professional</span>
            <div className="personal-detail">
              <img src={mailIcon} width={13} height={13} />
              <input
                value={professionalEmail}
                onChange={(e) => {
                  setProfessionalEmail(e.target.value);
                }}
                onFocus={() =>
                  professionalEmail === "Add email"
                    ? setProfessionalEmail("")
                    : null
                }
                onBlur={() =>
                  professionalEmail === ""
                    ? setProfessionalEmail("Add email")
                    : null
                }
              />
            </div>
            <div className="personal-detail">
              <img src={phoneIcon} width={13} height={13} />
              <input
                value={professionalPhone}
                onChange={(e) => {
                  setProfessionalPhone(e.target.value);
                }}
                onFocus={() =>
                  professionalPhone === "Add phone"
                    ? setProfessionalPhone("")
                    : null
                }
                onBlur={() =>
                  professionalPhone === ""
                    ? setProfessionalPhone("Add phone")
                    : null
                }
              />
            </div>
            <div className="personal-detail">
              <img src={locationIcon} width={13} height={13} />
              <input
                value={professionalAddress}
                onChange={(e) => {
                  setProfessionalAddress(e.target.value);
                }}
                onFocus={() =>
                  professionalAddress === "Add address"
                    ? setProfessionalAddress("")
                    : null
                }
                onBlur={() =>
                  professionalAddress === ""
                    ? setProfessionalAddress("Add address")
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="third-section-container">
        <div className="row" style={{ justifyContent: "space-between" }}>
          <div className="partner">
            <span className="title">Partner</span>

            <div>
              {partner.givenName !== "" ? (
                <div className="row">
                  <img
                    id="preview"
                    className="avatar-img"
                    src={
                      partner?.pictureUrl !== ""
                        ? partner?.pictureUrl
                        : avatarPlaceholder
                    }
                    alt=""
                    width={20}
                    height={20}
                    style={{ borderRadius: 20 }}
                  />
                  <span className="person-name">
                    {partner?.givenName} {partner?.surname}
                  </span>
                  {editing ? (
                    <img
                      alt=""
                      className="x-icon"
                      src={xIcon}
                      onClick={() => {
                        removePartnerFromContact();
                      }}
                    />
                  ) : null}
                </div>
              ) : (
                <DownshiftForLinking
                  placeholder={"Add partner"}
                  contacts={contacts}
                  onSelect={addPartnerToContact}
                  addNonExistingPerson={addNonExistingPartner}
                />
              )}
            </div>
          </div>
          {editing ? (
            <div className="edit-icon">
              <Done
                style={{ width: 20 }}
                onClick={() => {
                  setEditing(false);
                  resetKeyPersonAddition();
                }}
              />
            </div>
          ) : (
            <img
              className="edit-icon"
              src={editIcon}
              onClick={() => setEditing(true)}
            />
          )}
        </div>
        <div className="key-people">
          <div>
            <div className="row">
              <span className="title">Key People</span>
              <div className="addition-key-people">
                {!addingKeyPerson && !addingDescription ? null : (
                  <div className="addition-part">
                    {addingKeyPerson ? (
                      <DownshiftForLinking
                        placeholder={"Add key person"}
                        contacts={contacts}
                        onSelect={savingExistingKeyPerson}
                        addNonExistingPerson={savingNonExistingKeyPerson}
                      />
                    ) : null}
                    {addingDescription ? (
                      <div>
                        -
                        <input
                          className="add-person"
                          style={{ width: 230, marginLeft: 10 }}
                          placeholder={"Add description"}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    ) : null}
                    <div className="row">
                      {(nonExistingKeyPerson !== "" || existingKeyPerson) &&
                      description !== "" ? (
                        <div className="edit-icon">
                          <Done
                            style={{ width: 20 }}
                            onClick={() => {
                              if (existingKeyPerson) {
                                addExistingKeyPersonToContact(
                                  existingKeyPerson
                                );
                              } else {
                                addNonExistingKeyPersonToContact(
                                  nonExistingKeyPerson
                                );
                              }
                              resetKeyPersonAddition();
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="list-of-key-people">
                  {keyPeople?.map((keyPerson, index) => (
                    <div className="row" style={{ paddingBottom: 10 }}>
                      <img
                        id="preview"
                        className="avatar-img"
                        src={
                          keyPerson?.pictureUrl !== ""
                            ? keyPerson?.pictureUrl
                            : avatarPlaceholder
                        }
                        alt=""
                        width={20}
                        height={20}
                        style={{ borderRadius: 20 }}
                      />
                      <span className="person-name">
                        {keyPerson?.givenName} {keyPerson?.surname} -{" "}
                        {keyPerson?.description}
                      </span>
                      {editing ? (
                        <img
                          alt=""
                          className="x-icon"
                          src={xIcon}
                          onClick={() => {
                            removeKeyPersonFromContact(keyPerson, index);
                          }}
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {editing ? (
            <div>
              {!addingKeyPerson && !addingDescription ? (
                <OrbitButton
                  text="+"
                  white
                  style={{
                    fontSize: 32,
                    fontWeight: 400,
                    height: 20,
                    width: 20,
                  }}
                  onClick={() => {
                    setAddingKeyPerson(true);
                  }}
                />
              ) : (
                <img
                  className="edit-icon"
                  src={purpleXIcon}
                  onClick={() => {
                    resetKeyPersonAddition();
                  }}
                />
              )}
            </div>
          ) : null}
        </div>
        <div className="notes">
          <div className="notes-header">
            <span className="title">Notes</span>
          </div>

          <div className="notes-content">
            {notes.map((note, index) => (
              <div key={index} className="note-container">
                <div className="note-and-pin">
                  <div
                    className={
                      note.isPinned ? "purple-pin-icon" : "grey-pin-icon"
                    }
                    onClick={() => {
                      togglePinNote(note);
                    }}
                  ></div>
                  <span className="note">{note.note}</span>
                </div>
                <div className="right-field">
                  <div className="timestamp-container">
                    <span className="timestamp">
                      {new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(note.createdAt)}
                    </span>
                    <span className="timestamp">
                      {new Intl.DateTimeFormat("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(note.createdAt)}
                    </span>
                  </div>
                  {editing ? (
                    <div>
                      {notes.length !== 0 ? (
                        <img
                          className="x-icon"
                          src={xIcon}
                          onClick={() => {
                            setNotes(
                              notes.filter((item) => item.note !== note.note)
                            );
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        // style={{ width: "50%", height: 40, marginLeft: "25%", marginTop: 10 }}
        style={{
          width: "50%",
          height: 40,
          position: "absolute",
          bottom: 10,
          left: "25%",
        }}
      >
        <RaisedButton
          type="submit"
          text="Save Contact"
          style={{
            height: 40,
            fontSize: 16,
          }}
          // disabled={!isReadyToSubmit}
          loading={addContactProcessing}
          dark
          onClick={saveContact}
        />
      </div>
    </div>
  );
}
