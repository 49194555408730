import { fork } from "redux-saga/effects";
import testUserSaga from "./testUserSaga";
import authSaga from "./authSaga";
import filtersSaga from "./filtersSaga";
import flowsSaga from "./flowsSaga";
import contactsSaga from "./contactsSaga";
import paymentSaga from "./paymentSaga";

import "firebase/functions";

export default function* rootSaga() {
  yield fork(testUserSaga);
  yield fork(authSaga);
  yield fork(filtersSaga);
  yield fork(flowsSaga);
  yield fork(contactsSaga);
  yield fork(paymentSaga);
}
