import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_CURRENT_USER_DATA } from "../../actions";
import { RootState } from "../../Constants/types";
import OrbitAvatar from "../shared/OrbitAvatar";
import "./AccountSettingsPage.css";
import ChangePassword from "./ChangePassword";
import PersonalInfo from "./PersonalInfo";
import Subscription from "./Subscription";

export default function AccountSettingsPage() {
  const userData: any = useSelector((state: RootState) => state.auth.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_CURRENT_USER_DATA });
  }, []);

  return (
    <div>
      <div className="header">
        <div className="app-logo">
          <OrbitAvatar defaultRedirect={""} />
        </div>
        <div className="headline">
          Account Settings for {userData?.displayName}
        </div>
      </div>
      <hr className="break-line"></hr>
      <PersonalInfo userData={userData} />
      <ChangePassword />
      <Subscription />
    </div>
  );
}
