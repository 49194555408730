import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideSix({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Human Connection</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Orbit helps you stay close to your “strong ties” (soulmates, family
          and friends), and keeps you in touch with your “weak ties”
          (colleagues, community, and acquainttances).
        </span>
        <span style={{ fontSize: "100px" }}>❤</span>
        <span className="sub-description">
          Social isolation is one of the biggest public health crises in the
          world today. While maintaing a close friendships improves your health,
          and staying in touch with your community improves your professional
          success and sense of belonging.
        </span>
        <br />
      </div>
    </div>
  );
}
