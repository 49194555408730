import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideFive({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Not a Messaging App</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Messaging Apps are some of the most innovative and reliable
          technologies out there... but they are all islands of communication
          that rise and fall.
        </span>
        <span style={{ fontSize: "100px" }}>💬</span>
        <span className="sub-description">
          We do not want to dictate where message or text peopel, or make it
          hard to store your own communications history. We want to power your
          preferred messaging app’s address book.
        </span>
        <span className="sub-description">
          The Law of Messaging Apps: All successful apps, regardless of function
          or origin, eventually builds a closed messaging system, that gets
          hacked periodically before getting acquired by the largest technology
          company of the day. See: WhatsApp, FB Messenger, Instagram, Gchat,
          Bloomberg, BBM, etc.
        </span>
        <br />
      </div>
    </div>
  );
}
