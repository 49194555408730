import React, { useState } from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";
import RaisedButton from "../../../shared/RaisedButton";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideFourteen({ inViewport, forwardedRef }: props) {
  const [loading, setLoading] = useState(false);
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span id="headline14">
        Thank you for reading and joining us on this ride. We can’t wait to
        build Orbit together.
      </span>
      <RaisedButton
        text="BULD YOUR ORBITS"
        to="/signup/details"
        style={{ maxWidth: "285px" }}
        loading={loading}
        onClick={() => setLoading(true)}
        dark
      />
    </div>
  );
}
