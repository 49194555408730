import React, { useState } from "react";
import {
  injectStripe,
  ReactStripeElements,
  CardElement,
} from "react-stripe-elements";

import RaisedButton from "../../../shared/RaisedButton";
import SelectButtons from "../../../shared/SelectButtons";
import CountrySelect from "./CountrySelect";
import { useHistory } from "react-router-dom";
import {
  BRIGHT_ROSE,
  PRIMARY,
  PRIMARY_60,
} from "../../../../Constants/colorsPalette";
import { useDispatch, useSelector } from "react-redux";
import { NEW_PAYMENT } from "../../../../actions";
import InputField from "../../../shared/InputField";
import Error from "@material-ui/icons/ErrorOutline";
import { RootState } from "../../../../Constants/types";

function _SplitFieldsForm({
  stripe,
  elements,
}: ReactStripeElements.InjectedStripeProps) {
  const [errorMsg, setErrorMsg] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [country, setCountry] = useState("");
  const [postal, setPostal] = useState("");
  const [amount, setAmount] = useState(1000);
  const [cardIsReady, setCardIsReady] = useState(false);
  const { error, processing } = useSelector(
    (state: RootState) => state.paymentStatus
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = ({ error }: ReactStripeElements.ElementChangeResponse) =>
    setErrorMsg(error?.message ? error.message : ".");

  const handleSubmit = async (evt: React.FormEvent) => {
    evt.preventDefault();
    if (stripe) {
      const cardElement = elements?.getElement("card");
      const billing_details: stripe.BillingDetails = {
        name: nameOnCard,
        address: {
          country: country,
          postal_code: postal,
        },
      };
      dispatch({
        type: NEW_PAYMENT,
        value: { cardElement, billing_details, amount, stripe, history },
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ height: "100%", width: "100%" }}
      id="card-element"
    >
      <div className="split-form column ">
        <div
          className={"failure-error row centered " + (error ? "" : " hidden")}
        >
          <Error /> Credit card verification failed. Please check your
          information and try again.
        </div>
        <label style={{ width: "100%", textAlign: "center" }}>
          Select cadence of payment
          <SelectButtons
            onValueChange={setAmount}
            options={[
              { label: "$10 / month", value: 1000 },
              { label: "$100 / year", value: 10000 },
            ]}
          />
        </label>
        <label className="label column">
          Card information
          <CardElement
            onReady={() => setCardIsReady(true)}
            style={{
              base: {
                color: PRIMARY,
                "::placeholder": {
                  color: PRIMARY_60,
                },
              },
              invalid: {
                color: BRIGHT_ROSE,
              },
            }}
            hidePostalCode
            className="test-class"
            onChange={handleChange}
          />
          <div className="error" role="alert">
            {errorMsg ? errorMsg : ""}&nbsp;
          </div>
        </label>
        <label className="label column">
          Name on card
          <InputField
            placeholder="First Last"
            value={nameOnCard}
            onChange={(e) => setNameOnCard(e.target.value)}
          />
        </label>
        <label className="label column">
          Country & Region
          <CountrySelect onChange={setCountry} />
          <InputField
            inputmode="numeric"
            invalid={
              postal
                ? !postal.match(/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/)
                : false
            }
            pattern="^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$"
            placeholder="Postal Code"
            value={postal}
            onChange={(e) => setPostal(e.target.value)}
            errorMessage="Invalid Postal code."
            style={{ borderRadius: "0px 0px 5px 5px" }}
          />
        </label>
        <RaisedButton
          text="BEGIN ORBIT SUBSCRIPTION"
          dark
          style={{ maxWidth: "339px" }}
          type="submit"
          disabled={
            !(
              cardIsReady &&
              nameOnCard.length > 0 &&
              country.length > 0 &&
              postal.length > 0
            )
          }
          loading={processing}
        />
      </div>
    </form>
  );
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

export default SplitFieldsForm;
