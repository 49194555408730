import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";
import placeholder from "../../../../assets/img_placeholder.png";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideOne({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Welcome to Orbit Contacts Pro</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Orbit is a simple and powerful tool to help you organize and update
          your personal and professional contacts in one place.
        </span>
        <span className="main-description column">
          As easy as an Address Book. As powerful as graph database. Orbit helps
          you grow and maintain your lifelong relationships.
        </span>

        <img src={placeholder} style={{ height: "120px" }} />
        <span className="sub-description">
          It is 2020... and most of us have an incomplete and out-of-date
          address book, and our friends, frenemies, colleageus, and classmates
          are spread across half a dozen social networks.
        </span>
        <span className="sub-description">
          It is 2020... and so most of us are changing jobs, changing where we
          live, changing our priorities, and falling out of touch with some
          people, while trying to reconnect with others.
        </span>
        <span className="sub-description">
          Orbit helps you stay close to your “strong ties” (soulmates, family
          and friends), and keeps you in touch with your “weak ties”
          (colleagues, community, and acquainttances).
        </span>
        <span className="sub-description">
          Trusted & used by thoughtful people at
        </span>
        <span className="sub-description">
          Harvard, MIT, Google... (png logos will be added)
        </span>
      </div>
      {/* </div> */}
    </div>
  );
}
