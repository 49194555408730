import React, { CSSProperties, HtmlHTMLAttributes } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { PRIMARY, SECONDARY } from "../../../Constants/colorsPalette";
import "./FlatButton.css";

type props = {
  text: String;
  type?: "button" | "submit" | "reset" | undefined;
  to?: string;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export default function FlatButton({
  text,
  type = "button",
  style,
  to,
  disabled,
  loading,
  onClick,
}: props) {
  const className = "flat-btn  " + (disabled ? " disabled " : "");

  const content = loading ? (
    <Loader type="TailSpin" color={PRIMARY} height={30} width={30} />
  ) : (
    <> {text} </>
  );

  return (
    <div className={className} onClick={onClick}>
      {to && !disabled ? (
        <Link
          to={to}
          style={style}
          type={type}
          className={"fixed-row centered"}
        >
          {content}
        </Link>
      ) : (
        <button
          className="fixed-row centered"
          style={style}
          type={type}
          disabled={disabled}
        >
          {content}
        </button>
      )}
    </div>
  );
}
