import { Reducer } from "redux";
import update from "immutability-helper";
import {
  KEY_PERSON_PROCESSING_FINISH,
  KEY_PERSON_PROCESSING_START,
  PROCESSING_NOTES_FINISH,
  PROCESSING_NOTES_START,
  PARTNER_PROCESSING_FINISH,
  PARTNER_PROCESSING_START,
  EDIT_CONTACTS_LIST,
  EDIT_CONTACTS_LIST_FINISHED,
  EDIT_CONTACTS_LIST_STARTED,
  FINISH_ORBIT_CHANGE,
  SHOW_CONTACT,
  START_ORBIT_CHANGE,
  UNSHOW_CONTACT,
  UPDATE_CONTACT_AFTER_EMAIL_CHANGE,
  UPDATE_CONTACT_AFTER_FIRST_NAME_CHANGE,
  UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION,
  UPDATE_CONTACT_AFTER_LIST_ADDITION,
  UPDATE_CONTACT_AFTER_ORBIT_CHANGE,
  UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
  UPDATE_CONTACT_AFTER_PHONENUMBER_CHANGE,
  UPDATE_CONTACT_AFTER_SURNAME_CHANGE,
  UPLOAD_CONTACT_NOTES,
  UPLOAD_ORIGINAL_CONTACTS,
  RESET_CHANGE_IN_CONTACTS_LIST,
  SET_CHANGE_IN_CONTACTS_LIST,
  UPDATE_CONTACT_AFTER_LIST_DELETION,
  SET_SORTING_VALUE,
  UPDATE_CONTACTS,
  UPDATE_CONTACTS_AFTER_LAST_CONTACTED,
  UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START,
  UPDATE_CONTACTS_AFTER_LAST_CONTACTED_FINISH,
  CHANGE_PAGE_NUMBER,
  UPDATE_CONTACT_AFTER_KEY_PERSON_REMOVAL,
  SHOW_ADD_CONTACT,
  UNSHOW_ADD_CONTACT,
  ADD_CONTACT_PROCESSING_START,
  ADD_CONTACT_PROCESSING_FINISH,
} from "../actions";
import { dummyContacts } from "../Constants/dummyContacts";
import { Action, Contact, Note } from "../Constants/types";

interface contactsRootState {
  originalContacts: Contact[];
  contactsList: Contact[];
  changeInContactsAttributes: boolean;
  currentPageNumber: number;
  sortingValue: keyof Contact | null;
  // searchedContacts: Contact[];
  displayedContact: any;
  displayedContactNotes: Note[];
  displayContact: boolean;
  addContact: boolean;
  addContactProcessing: boolean;
  orbitChangeProcessing: boolean;
  partnerProcessing: boolean;
  keyPeopleProcessing: boolean;
  notesProcessing: boolean;
  lastContactedProcessing: boolean;
  processing: boolean;
}

const initialState: contactsRootState = {
  originalContacts: [],
  contactsList: [],
  changeInContactsAttributes: false,
  currentPageNumber: 1,
  sortingValue: null,
  // searchedContacts: [],
  displayedContact: null,
  displayedContactNotes: [],
  displayContact: false,
  addContact: false,
  addContactProcessing: false,
  orbitChangeProcessing: false,
  partnerProcessing: false,
  keyPeopleProcessing: false,
  notesProcessing: false,
  lastContactedProcessing: false,
  processing: false,
};

const contactsReducer: Reducer<contactsRootState, Action> = (
  state: contactsRootState = initialState,
  action: Action
) => {
  const newState = { ...state };

  switch (action.type) {
    case UPLOAD_ORIGINAL_CONTACTS:
      return {
        ...newState,
        originalContacts: action.value,
        contactsList: action.value,
      };
    case UPLOAD_CONTACT_NOTES:
      return { ...newState, displayedContactNotes: action.value };
    // case UPLOAD_SEARCHED_CONTACTS:
    //   return { ...newState, searchedContacts: action.value };
    case UPDATE_CONTACTS:
      // newState.originalContacts.splice(action.value.offset, 100);
      // newState.contactsList.splice(action.value.offset, 100);
      return {
        ...newState,
        originalContacts: newState.originalContacts.concat(
          action.value.contacts
        ),
        contactsList: newState.contactsList.concat(action.value.contacts),
      };

    case UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START:
      return { ...newState, lastContactedProcessing: true };
    case UPDATE_CONTACTS_AFTER_LAST_CONTACTED_FINISH:
      return { ...newState, lastContactedProcessing: false };
    case UPDATE_CONTACTS_AFTER_LAST_CONTACTED:
      newState.originalContacts.splice(action.value.offset, 100);
      newState.contactsList.splice(action.value.offset, 100);
      return {
        ...newState,
        originalContacts: newState.originalContacts.concat(
          action.value.contacts
        ),
        contactsList: newState.contactsList.concat(action.value.contacts),
      };
    case SHOW_CONTACT:
      return {
        ...newState,
        displayedContact: action.value,
        displayContact: true,
      };

    case UNSHOW_CONTACT:
      return {
        ...newState,
        displayedContact: null,
        displayContact: false,
      };
    case SHOW_ADD_CONTACT:
      return {
        ...newState,
        addContact: true,
      };

    case UNSHOW_ADD_CONTACT:
      return {
        ...newState,
        addContact: false,
      };
    case ADD_CONTACT_PROCESSING_START:
      return { ...newState, addContactProcessing: true };
    case ADD_CONTACT_PROCESSING_FINISH:
      return { ...newState, addContactProcessing: false };
    case SET_SORTING_VALUE:
      return { ...newState, sortingValue: action.value };
    case EDIT_CONTACTS_LIST_STARTED:
      return { ...newState, processing: true };
    case EDIT_CONTACTS_LIST:
      return {
        ...newState,
        contactsList: action.value,
        currentPageNumber: 1,
        changeInContactsAttributes: true,
      };
    case CHANGE_PAGE_NUMBER:
      return { ...newState, currentPageNumber: action.value };
    case RESET_CHANGE_IN_CONTACTS_LIST:
      return { ...newState, changeInContactsAttributes: false };
    case SET_CHANGE_IN_CONTACTS_LIST:
      return { ...newState, changeInContactsAttributes: true };
    case EDIT_CONTACTS_LIST_FINISHED:
      return { ...newState, processing: false };
    case START_ORBIT_CHANGE:
      return { ...newState, orbitChangeProcessing: true };
    case UPDATE_CONTACT_AFTER_ORBIT_CHANGE:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            orbitNumber: { $set: action.value.newOrbitNumber },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            orbitNumber: { $set: action.value.newOrbitNumber },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_LIST_ADDITION:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            lists: { $push: [action.value.list] },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            lists: { $push: [action.value.list] },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_LIST_DELETION:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            lists: {
              $set: newState.contactsList[
                action.value.contactsListIndex
              ].lists.filter((item) => item !== action.value.list),
            },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            lists: {
              $set: newState.contactsList[
                action.value.originalListIndex
              ].lists.filter((item) => item !== action.value.list),
            },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_FIRST_NAME_CHANGE:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            givenName: { $set: action.value.newGivenName },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            givenName: { $set: action.value.newGivenName },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_SURNAME_CHANGE:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            surname: { $set: action.value.newSurname },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            surname: { $set: action.value.newSurname },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_EMAIL_CHANGE:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            email: { $set: action.value.newEmail },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            email: { $set: action.value.newEmail },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_PHONENUMBER_CHANGE:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            phoneNumbers: {
              $set: [
                { type: "mobile", number: action.value.newPhoneNumber },
                ...newState.contactsList[action.value.contactsListIndex]
                  .phoneNumbers,
              ],
            },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            phoneNumbers: {
              $set: [
                { type: "mobile", number: action.value.newPhoneNumber },
                ...newState.contactsList[action.value.contactsListIndex]
                  .phoneNumbers,
              ],
            },
          },
        },
      });

    case UPDATE_CONTACT_AFTER_PARTNER_CHANGE:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            partner: {
              $set: {
                givenName: action.value.givenName,
                id: action.value.id,
                pictureUrl: action.value.pictureUrl,
                surname: action.value.surname,
              },
            },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            partner: {
              $set: {
                givenName: action.value.givenName,
                id: action.value.id,
                pictureUrl: action.value.pictureUrl,
                surname: action.value.surname,
              },
            },
          },
        },
      });

    case UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            keyPeople: {
              $push: [
                {
                  givenName: action.value.givenName,
                  id: action.value.id,
                  pictureUrl: action.value.pictureUrl,
                  surname: action.value.surname,
                  description: action.value.description,
                },
              ],
            },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            keyPeople: {
              $push: [
                {
                  givenName: action.value.givenName,
                  id: action.value.id,
                  pictureUrl: action.value.pictureUrl,
                  surname: action.value.surname,
                  description: action.value.description,
                },
              ],
            },
          },
        },
      });
    case UPDATE_CONTACT_AFTER_KEY_PERSON_REMOVAL:
      return update(newState, {
        contactsList: {
          [action.value.contactsListIndex]: {
            keyPeople: {
              $set: newState.contactsList[
                action.value.contactsListIndex
              ].keyPeople.filter(
                (keyPerson, index) => index !== action.value.keyPersonIndex
              ),
            },
          },
        },
        originalContacts: {
          [action.value.originalListIndex]: {
            keyPeople: {
              $set: newState.originalContacts[
                action.value.originalListIndex
              ].keyPeople.filter(
                (keyPerson, index) => index !== action.value.keyPersonIndex
              ),
            },
          },
        },
      });
    case FINISH_ORBIT_CHANGE:
      return { ...newState, orbitChangeProcessing: false };

    case PARTNER_PROCESSING_START:
      return { ...newState, partnerProcessing: true };

    case PARTNER_PROCESSING_FINISH:
      return { ...newState, partnerProcessing: false };
    case KEY_PERSON_PROCESSING_START:
      return { ...newState, keyPeopleProcessing: true };

    case KEY_PERSON_PROCESSING_FINISH:
      return { ...newState, keyPeopleProcessing: false };
    case PROCESSING_NOTES_START:
      return { ...newState, notesProcessing: true };
    case PROCESSING_NOTES_FINISH:
      return { ...newState, notesProcessing: false };
  }
  return newState;
};
export default contactsReducer;
