import React from "react";

import { PRIMARY } from "../../../../Constants/colorsPalette";

type props = { inViewport: boolean; forwardedRef: any };

export default function SlideTwo({ inViewport, forwardedRef }: props) {
  return (
    <div className="slide centered column" ref={forwardedRef}>
      <span className="slide-title">Not LinkedIn</span>
      <hr style={{ width: "80%", backgroundColor: `${PRIMARY}` }} />
      <br />
      <div className="slide-body column">
        <span className="main-description column">
          Linkedin is useful... but everyone uses it differently, or not at all,
          and utlimately it is designed for influencer posts, if not spam.
        </span>
        <span style={{ fontSize: "100px" }}>🚮</span>
        <span className="sub-description">
          It’s hard to leave Linkedin because of your connections and history of
          connections. It’s hard to contact your connections outside of LinkedIn
          given the limited contact details.
        </span>
        <span className="sub-description">
          Orbit helps you build and maintain your private network.
        </span>
        <span className="sub-description">
          Our business model does not rely on selling your data to recruiters or
          dark design patterns to increase daily engagement.
        </span>{" "}
        <br />
        <br />
      </div>
    </div>
  );
}
