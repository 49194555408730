// TEST USER VERIFICATION
export const CHECK_EXISTING_TEST_USER = "CHECK_EXISTING_TEST_USER";
export const VERIFY = "VERIFY";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";

// SIGN UP
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

//LOG IN
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//User
export const GET_CURRENT_USER_DATA = "GET_CURRENT_USER_DATA";
export const GET_CURRENT_USER_DATA_SUCCESS = "GET_CURRENT_USER_DATA_SUCCESS";
export const CHECK_EXTRA_USER_DATA = "CHECK_EXTRA_USER_DATA";
export const EDIT_EXTRA_DATA_IN_STATE = "EDIT_EXTRA_DATA_IN_STATE";
export const EDIT_ACCOUNT_SETTINGS = "EDIT_ACCOUNT_SETTINGS";
export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";
export const UPDATE_PROFILE_PICTURE_START = "UPDATE_PROFILE_PICTURE_STRAT";
export const UPDATE_PROFILE_PICTURE_FINISH = "UPDATE_PROFILE_PICTURE_FINISH";
export const UPDATE_USER_AFTER_PROFILE_PICTURE_CHANGE =
  "UPDATE_USER_AFTER_PROFILE_PICTURE_CHANGE";
export const CHANGE_USER_PASSWORD_START = "CHANGE_USER_PASSWORD_START";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_FINISH = "CHANGE_USER_PASSWORD_FINISH";
export const EDIT_CHANGE_PASSWORD_ERROR = "EDIT_CHANGE_PASSWORD_ERROR";

//Signout
export const SIGNOUT = "SIGNOUT";

//FILTERS
export const ADD_COLLECTION = "ADD_COLLECTION";
export const EDIT_COLLECTION_NAME = "EDIT_COLLECTION_NAME";
export const ADD_LIST_TO_CONTACT = "ADD_LIST_TO_CONTACT";
export const DELETE_LIST_FROM_CONTACT = "DELETE_LIST_FROM_CONTACT";
export const ADD_LIST = "ADD_LIST";
export const DELETE_LIST_FROM_COLLECTION = "DELETE_LIST_FROM_COLLECTION";
export const DELETE_EMPTY_COLLECTION = "DELETE_EMPTY_COLLECTION";
export const EDIT_LIST_NAME = "EDIT_LIST_NAME";
export const GET_LISTS_START = "GET_LISTS_START";
export const GET_LISTS = "GET_LISTS";
export const GET_LISTS_FINISH = "GET_LISTS_FINISH";
export const UPDATE_USER_LISTS = "UPDATE_USER_LISTS";
export const UPDATE_USER_TAGS = "UPDATE_USER_TAGS";
export const GET_ORBITS_START = "GET_ORBITS_START";
export const GET_ORBITS = "GET_ORBITS";
export const UPDATE_ORBITS = "UPDATE_ORBITS";
export const GET_ORBITS_FINISH = "GET_ORBITS_FINISH";
export const EDIT_ORBITS_TITLES_START = "EDIT_ORBITS_TITLES_START";
export const EDIT_ORBITS_TITLES = "EDIT_ORBITS_TITLES";
export const EDIT_ORBIT_TITLE = "EDIT_ORBIT_TITLE";
export const EDIT_ORBITS_TITLES_FINISH = "EDIT_ORBITS_TITLES_FINISH";
export const UPLOAD_ORBITS = "UPLOAD_ORBITS";
export const SELECT_ORBIT = "SELECT_ORBIT";
export const SELECT_LIST = "SELECT_LIST";
export const SELECT_FLOW = "SELECT_FLOW";
export const SELECT_PROPERTY = "SELECT_PROPERTY";
export const SET_SELECTED_FLOW = "SET_SELECTED_FLOW";
export const EDIT_ORBITS_IN_STATE = "EDIT_ORBITS_IN_STATE";
export const EDIT_LISTS_IN_STATE = "EDIT_LISTS_IN_STATE";
export const EDIT_PROPERTIES_IN_STATE = "EDIT_PROPERTIES_IN_STATE";
export const CHANGE_GROUPING = "CHANGE_GROUPING";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
export const RESET_CHANGE_IN_TAGS_NAMES = "RESET_CHANGE_IN_TAGS_NAMES";
export const SET_CHANGE_IN_TAGS_NAMES = "SET_CHANGE_IN_TAGS_NAMES";

//CONTACTS

export const UPLOAD_ORIGINAL_CONTACTS = "UPLOAD_ORIGINAL_CONTACTS";
export const UPLOAD_CONTACT_NOTES = "UPLOAD_CONTACT_NOTES";
// export const UPLOAD_SEARCHED_CONTACTS = "UPLOAD_SEARCHED_CONTACTS";
export const SHOW_CONTACT = "SHOW_CONTACT";
export const UNSHOW_CONTACT = "UNSHOW_CONTACT";
export const SHOW_ADD_CONTACT = "SHOW_ADD_CONTACT";
export const UNSHOW_ADD_CONTACT = "UNSHOW_ADD_CONTACT";
export const GET_CONTACTS = "GET_CONTACTS";
export const GENERATE_ACCESS_TOKEN = "GENERATE_ACCESS_TOKEN";
export const SYNC_CONTACTS = "SYNC_CONTACTS";
export const SEARCH_CONTACTS = "SEARCH_CONTACTS";

export const EDIT_CONTACTS_LIST_STARTED = "EDIT_CONTACTS_LIST_STARTED";
export const EDIT_CONTACTS_LIST = "EDIT_CONTACTS_LIST";
export const UPDATE_CONTACTS = "UPDATE_CONTACTS";
export const UPDATE_CONTACTS_AFTER_LAST_CONTACTED =
  "UPDATE_CONTACTS_AFTER_LAST_CONTACTED";
export const UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START =
  "UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START";
export const UPDATE_CONTACTS_AFTER_LAST_CONTACTED_FINISH =
  "UPDATE_CONTACTS_AFTER_LAST_CONTACTED_FINISH";
export const RESET_CHANGE_IN_CONTACTS_LIST = "RESET_CHANGE_IN_CONTACTS_LIST";
export const SET_CHANGE_IN_CONTACTS_LIST = "SET_CHANGE_IN_CONTACTS_LIST";
export const EDIT_CONTACTS_LIST_FINISHED = "EDIT_CONTACTS_LIST_FINISHED";
export const SET_SORTING_VALUE = "SET_SORTING_VALUE";
export const SORT_CONTACTS = "SORT_CONTACTS";
export const CHANGE_PAGE_NUMBER = "CHANGE_PAGE_NUMBER";

export const ADD_CONTACT = "ADD_CONTACT";
export const MODIFY_EXISTING_CONTACT = "MODIFY_EXISTING_CONTACT";

export const ADD_PARTNER_TO_CONTACT = "ADD_PARTNER_TO_CONTACT";
export const REMOVE_PARTNER_FROM_CONTACT = "REMOVE_PARTNER_FROM_CONTACT";
export const ADD_KEY_PERSON_TO_CONTACT = "ADD_KEY_PERSON_TO_CONTACT";
export const REMOVE_KEY_PERSON_FROM_CONTACT = "REMOVE_KEY_PERSON_FROM_CONTACT";

export const MOVE_CONTACT_TO_ORBIT = "MOVE_CONTACT_TO_ORBIT";

export const ADD_NOTE_TO_CONTACT = "ADD_NOTE_TO_CONTACT";
export const GET_ALL_NOTES_OF_CONTACT = "GET_ALL_NOTES_OF_CONTACT";
export const TOGGLE_IS_PINNED_NOTE = "TOGGLE_IS_PINNED_NOTE";
export const DELETE_NOTE = "DELETE_NOTE";

export const UPDATE_CONTACT_AFTER_ORBIT_CHANGE =
  "UPDATE_CONTACT_AFTER_ORBIT_CHANGE";
export const UPDATE_CONTACT_AFTER_LIST_ADDITION =
  "UPDATE_CONTACT_AFTER_LIST_ADDITION";
export const UPDATE_CONTACT_AFTER_LIST_DELETION =
  "UPDATE_CONTACT_AFTER_LIST_DELETION";

export const UPDATE_CONTACT_AFTER_FIRST_NAME_CHANGE =
  "UPDATE_CONTACT_AFTER_FIRST_NAME_CHANGE";
export const UPDATE_CONTACT_AFTER_SURNAME_CHANGE =
  "UPDATE_CONTACT_AFTER_SURNAME_CHANGE";
export const UPDATE_CONTACT_AFTER_EMAIL_CHANGE =
  "UPDATE_CONTACT_AFTER_EMAIL_CHANGE";
export const UPDATE_CONTACT_AFTER_PHONENUMBER_CHANGE =
  "UPDATE_CONTACT_AFTER_PHONENUMBER_CHANGE";
export const UPDATE_CONTACT_AFTER_PARTNER_CHANGE =
  "UPDATE_CONTACT_AFTER_PARTNER_CHANGE";
export const UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION =
  "UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION";
export const UPDATE_CONTACT_AFTER_KEY_PERSON_REMOVAL =
  "UPDATE_CONTACT_AFTER_KEY_PERSON_REMOVAL";
export const UPDATE_DISPLAYED_CONTACT = "UPDATE_DISPLAYED_CONTACT";

export const START_ORBIT_CHANGE = "START_ORBIT_CHANGE";
export const FINISH_ORBIT_CHANGE = "FINISH_ORBIT_CHANGE";

export const PARTNER_PROCESSING_START = "PARTNER_PROCESSING_START";
export const PARTNER_PROCESSING_FINISH = "PARTNER_PROCESSING_FINISH";

export const ADD_CONTACT_PROCESSING_START = "ADD_CONTACT_PROCESSING_START";
export const ADD_CONTACT_PROCESSING_FINISH = "ADD_CONTACT_PROCESSING_FINISH";

export const KEY_PERSON_PROCESSING_START = "KEY_PERSON_PROCESSING_START";
export const KEY_PERSON_PROCESSING_FINISH = "KEY_PERSON_PROCESSING_FINISH";
export const PROCESSING_NOTES_START = "PROCESSING_NOTES_START";
export const PROCESSING_NOTES_FINISH = "PROCESSING_NOTES_FINISH";
//SUBSCRIPTION
export const NEW_PAYMENT = "NEW_PAYMENT";
export const PAYMENT_FAILED = "PAYMENT_FAILED";
export const GET_LAST_PAYMENT = "GET_LAST_PAYMENT";
export const UPDATE_LAST_PAYMENT = "UPDATE_LAST_PAYMENT";
