import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {
  ADD_KEY_PERSON_TO_CONTACT,
  KEY_PERSON_PROCESSING_FINISH,
  KEY_PERSON_PROCESSING_START,
  ADD_NOTE_TO_CONTACT,
  PROCESSING_NOTES_FINISH,
  PROCESSING_NOTES_START,
  ADD_PARTNER_TO_CONTACT,
  PARTNER_PROCESSING_FINISH,
  PARTNER_PROCESSING_START,
  EDIT_CONTACTS_LIST,
  EDIT_CONTACTS_LIST_FINISHED,
  EDIT_CONTACTS_LIST_STARTED,
  GENERATE_ACCESS_TOKEN,
  GET_ALL_NOTES_OF_CONTACT,
  GET_CONTACTS,
  GET_ORBITS,
  MODIFY_EXISTING_CONTACT,
  MOVE_CONTACT_TO_ORBIT,
  SYNC_CONTACTS,
  TOGGLE_IS_PINNED_NOTE,
  UPDATE_CONTACT_AFTER_EMAIL_CHANGE,
  UPDATE_CONTACT_AFTER_FIRST_NAME_CHANGE,
  UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION,
  UPDATE_CONTACT_AFTER_ORBIT_CHANGE,
  UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
  UPDATE_CONTACT_AFTER_PHONENUMBER_CHANGE,
  UPDATE_CONTACT_AFTER_SURNAME_CHANGE,
  UPLOAD_CONTACT_NOTES,
  UPLOAD_ORIGINAL_CONTACTS,
  DELETE_NOTE,
  SET_CHANGE_IN_CONTACTS_LIST,
  SET_SORTING_VALUE,
  SORT_CONTACTS,
  UPDATE_CONTACTS,
  UPDATE_CONTACTS_AFTER_LAST_CONTACTED,
  UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START,
  UPDATE_CONTACTS_AFTER_LAST_CONTACTED_FINISH,
  REMOVE_PARTNER_FROM_CONTACT,
  REMOVE_KEY_PERSON_FROM_CONTACT,
  UPDATE_CONTACT_AFTER_KEY_PERSON_REMOVAL,
  ADD_CONTACT,
  UNSHOW_ADD_CONTACT,
  ADD_CONTACT_PROCESSING_FINISH,
  ADD_CONTACT_PROCESSING_START,
  UPDATE_ORBITS,
  // UPLOAD_SEARCHED_CONTACTS,
} from "../actions";
import { Action, Contact, RootState } from "../Constants/types";
import firebase from "../config/firebase";
import store from "../store";
import { ServerResponse } from "http";
import { filterContacts } from "../functions";

function* getContacts() {
  try {
    yield put({ type: EDIT_CONTACTS_LIST_STARTED });
    var getContacts = firebase.functions().httpsCallable("getContacts");
    const user = firebase.auth().currentUser;
    const response = yield call(getContacts, { uid: user?.uid });
    if (!response || response?.data.status !== "success") {
      throw new Error("Something went wrong! please try again.");
    } else {
      yield put({
        type: UPLOAD_ORIGINAL_CONTACTS,
        value: response.data.contacts,
      });
      // yield put({
      //   type: EDIT_CONTACTS_LIST,
      //   value: response.data.contacts,
      // });
      // yield put({
      //   type: UPLOAD_SEARCHED_CONTACTS,
      //   value: response.data.contacts,
      // });
    }
    yield put({ type: EDIT_CONTACTS_LIST_FINISHED });
    yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
  } catch (error) {
    console.log(error.message);
  }
}

function* sortContacts(action: Action) {
  try {
    const contacts = action.value.contacts;
    const newSortingValue = action.value.newSortingValue;
    const oldSortingValue = yield select(
      (state: RootState) => state.contacts.sortingValue
    );
    let sortedContacts;
    if (newSortingValue === oldSortingValue) {
      sortedContacts = contacts.reverse();
    } else {
      sortedContacts = contacts.sort((a: any, b: any) => {
        var x = a[newSortingValue];
        var y = b[newSortingValue];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    }
    yield put({ type: SET_SORTING_VALUE, value: newSortingValue });
    yield put({
      type: EDIT_CONTACTS_LIST,
      value: sortedContacts,
    });
    yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
  } catch (error) {
    console.log(error);
  }
}

function* syncContacts() {
  try {
    yield put({ type: EDIT_CONTACTS_LIST_STARTED });

    const selectedOrbits: number[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedOrbits
    );
    const selectedLists: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedLists
    );

    const selectedProperties: string[] = yield select(
      (state: RootState) => state.filtersAndLists.selectedProperties
    );

    const groupingOption: "Show All" | "Intersection" | "none" = yield select(
      (state: RootState) => state.filtersAndLists.groupingOption
    );
    const contacts: Contact[] = yield select(
      (state: RootState) => state.contacts.originalContacts
    );

    var syncContactsFromGoogle = firebase
      .functions()
      .httpsCallable("syncContacts", { timeout: 400000 });
    var setLastContactedAttribute = firebase
      .functions()
      .httpsCallable("setLastContactedAttribute", { timeout: 540000 });
    let syncResponse;
    let lastContactedResponse;

    const tmpContacts = yield select(
      (state: RootState) => state.contacts.originalContacts
    );

    // let contactsHashMap = new Map<string, number>();

    // // let contactsHashMap = {};
    // for (let i = 0; i < tmpContacts.length; i++) {
    //   const element: Contact = tmpContacts[i];
    //   let key = element.id;

    //   contactsHashMap.set(key, element.orbitNumber);
    // }
    let isFirstCall = tmpContacts.length === 0 ? true : false;
    let totalSyncedContacts: Contact[] = [];

    var offset = 0;
    if (isFirstCall) {
      while (true) {
        syncResponse = yield call(syncContactsFromGoogle, {
          limit: 100,
          offset,
          // isFirstCall: false,
          previousContacts: tmpContacts.length > 0 ? tmpContacts : [],
        });

        if (!syncResponse || syncResponse?.data.status !== "success") {
          throw new Error("Something went wrong! please try again.");
        } else {
          yield put({
            type: UPDATE_CONTACTS,
            value: { contacts: syncResponse.data.data.contacts, offset },
          });

          yield put({ type: GET_ORBITS });
          if (offset === 0) {
            yield put({ type: EDIT_CONTACTS_LIST_FINISHED });
          }

          yield put({ type: UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START });
          lastContactedResponse = yield call(setLastContactedAttribute, {
            offset,
          });
          if (
            !lastContactedResponse ||
            lastContactedResponse.data.status !== "success"
          ) {
            throw new Error("Something went wrong!");
          } else {
            yield put({
              type: UPDATE_CONTACTS_AFTER_LAST_CONTACTED,
              value: {
                contacts: lastContactedResponse.data.data.contacts,
                offset,
              },
            });
            yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
            if (offset === 0) {
              yield put({ type: UPDATE_CONTACTS_AFTER_LAST_CONTACTED_FINISH });
            }
          }
          console.log(offset);
          offset += 100;
        }
        if (syncResponse.data.data.contacts.length < 100) {
          break;
        }
      }
    } else {
      while (true) {
        syncResponse = yield call(syncContactsFromGoogle, {
          limit: 100,
          offset,
          previousContacts: tmpContacts.length > 0 ? tmpContacts : [],
        });

        if (!syncResponse || syncResponse?.data.status !== "success") {
          throw new Error("Something went wrong! please try again.");
        } else {
          if (offset === 0) {
            yield put({ type: EDIT_CONTACTS_LIST_FINISHED });
          }
          yield put({ type: UPDATE_CONTACTS_AFTER_LAST_CONTACTED_START });
          lastContactedResponse = yield call(setLastContactedAttribute, {
            offset,
          });
          if (
            !lastContactedResponse ||
            lastContactedResponse.data.status !== "success"
          ) {
            throw new Error("Something went wrong!");
          } else {
            totalSyncedContacts = totalSyncedContacts.concat(
              lastContactedResponse.data.data.contacts
            );
          }
          console.log(offset);
          offset += 100;
        }
        if (syncResponse.data.data.contacts.length < 100) {
          break;
        }
      }
      yield put({ type: UPLOAD_ORIGINAL_CONTACTS, value: totalSyncedContacts });
    }
  } catch (error) {
    console.log("error in syncContacts", error);
  }
}

function* addContact(action: Action) {
  try {
    yield put({ type: ADD_CONTACT_PROCESSING_START });
    const addContact = firebase.functions().httpsCallable("addContact");
    const response = yield call(addContact, {
      givenName: action.value.givenName,
      surname: action.value.surname,
      personalEmail: action.value.personalEmail,
      professionalEmail: action.value.professionalEmail,
      personalPhoneNumber: action.value.personalPhoneNumber,
      professionalPhoneNumber: action.value.professionalPhoneNumber,
      partner: action.value.partner,
      keyPeople: action.value.keyPeople,
      lists: action.value.lists,
      orbitNumber: action.value.orbitNumber,
      pictureUrl: action.value.pictureUrl,
      notes: action.value.notes,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      console.log(response.data.data.id);
      yield put({
        type: UPDATE_CONTACTS,
        value: [
          {
            id: response.data.data.id,
            givenName: action.value.givenName,
            surname: action.value.surname,
            email: action.value.personalEmail
              ? action.value.personalEmail
              : action.value.professionalEmail
              ? action.value.professionalEmail
              : "",
            phoneNumbers: response.data.data.phoneNumbers,
            partner: action.value.partner,
            keyPeople: action.value.keyPeople,
            lists: action.value.lists,
            orbitNumber: action.value.orbitNumber,
            pictureUrl: action.value.pictureUrl,
            lastContacted: "",
            notes: action.value.notes,
          },
        ],
      });
      if (action.value.partner.id !== "") {
        const contactsListIndexPartner = yield select((state: RootState) =>
          state.contacts.contactsList.findIndex(
            (contact) => contact.id === action.value.partner.id
          )
        );
        const originalListIndexPartner = yield select((state: RootState) =>
          state.contacts.originalContacts.findIndex(
            (contact) => contact.id === action.value.partner.id
          )
        );
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: response.data.data.id,
            givenName: action.value.givenName,
            surname: action.value.surname,
            pictureUrl: action.value.pictureUrl,
            contactsListIndex: contactsListIndexPartner,
            originalListIndex: originalListIndexPartner,
          },
        });
      }
      yield put({ type: ADD_CONTACT_PROCESSING_FINISH });
      yield put({ type: UNSHOW_ADD_CONTACT });
    }
  } catch (error) {
    console.log(error);
  }
}

function* setLastContactedAttribute(contacts: any) {
  try {
    var setLastContactedAttribute = firebase
      .functions()
      .httpsCallable("setLastContactedAttribute", { timeout: 400000 });
    const response = yield call(setLastContactedAttribute, { contacts });
    if (!response || response?.data.status !== "success") {
      throw new Error("Something went wrong! Please try again.");
    } else {
    }
  } catch (error) {
    console.log(error);
  }
}

function* generateAccessToken(action: Action) {
  try {
    var generateAccessToken = firebase
      .functions()
      .httpsCallable("generateAccessToken");
    const input = {
      uid: action.value.userID,
      code: action.value.code,
    };
    const response = yield call(generateAccessToken, input);
    if (!response || response?.data.status !== "success") {
      throw new Error("Something went wrong! please try again.");
    } else {
      // yield put({ type: SYNC_CONTACTS });
      yield call(syncContacts);
    }
  } catch (error) {
    console.log(error.message);
  }
}

function* moveContactToOrbit(action: Action) {
  try {
    // yield put({ type: START_ORBIT_CHANGE });
    const moveContactToOrbit = firebase
      .functions()
      .httpsCallable("moveContactToOrbit ");
    const contactsListIndex = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const originalListIndex = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    yield put({
      type: UPDATE_CONTACT_AFTER_ORBIT_CHANGE,
      value: {
        id: action.value.contactId,
        contactsListIndex,
        originalListIndex,
        newOrbitNumber: action.value.newOrbitNumber,
      },
    });
    yield put({ type: SET_CHANGE_IN_CONTACTS_LIST });
    yield put({
      type: UPDATE_ORBITS,
      value: {
        newOrbitNumber: action.value.newOrbitNumber,
        currentOrbitNumber: action.value.currentOrbitNumber,
      },
    });
    const uid = firebase.auth().currentUser?.uid;
    const response = yield call(moveContactToOrbit, {
      uid,
      contactId: action.value.contactId,
      newOrbitNumber: action.value.newOrbitNumber,
      currentOrbitNumber: action.value.currentOrbitNumber,
    });
    if (response && response.data.status === "success") {
      // yield put({ type: GET_ORBITS });
    } else {
      throw new Error("Something went wrong! please try again.");
    }
    // yield put({ type: FINISH_ORBIT_CHANGE });
  } catch (error) {}
}

function* modifyExistingContact(action: Action) {
  try {
    const modifyExistingContact = firebase
      .functions()
      .httpsCallable("modifyExistingContact");
    const uid = firebase.auth().currentUser?.uid;

    const contactsListIndex = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const originalListIndex = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    let response;
    switch (action.value.attributeChanged) {
      case "givenName":
        response = yield call(modifyExistingContact, {
          uid,
          contactId: action.value.contactId,
          firstName: action.value.newValue,
        });
        if (response.data.status === "success") {
          yield put({
            type: UPDATE_CONTACT_AFTER_FIRST_NAME_CHANGE,
            value: {
              id: action.value.contactId,
              contactsListIndex,
              originalListIndex,
              newGivenName: action.value.newValue,
            },
          });
        }
        break;
      case "surname":
        response = yield call(modifyExistingContact, {
          uid,
          contactId: action.value.contactId,
          surname: action.value.newValue,
        });
        if (response.data.status === "success") {
          yield put({
            type: UPDATE_CONTACT_AFTER_SURNAME_CHANGE,
            value: {
              id: action.value.contactId,
              contactsListIndex,
              originalListIndex,
              newSurname: action.value.newValue,
            },
          });
        }
        break;
      case "phoneNumbers":
        response = yield call(modifyExistingContact, {
          uid,
          contactId: action.value.contactId,
          phoneNumber: action.value.newValue,
        });
        if (response.data.status === "success") {
          yield put({
            type: UPDATE_CONTACT_AFTER_PHONENUMBER_CHANGE,
            value: {
              id: action.value.contactId,
              contactsListIndex,
              originalListIndex,
              newPhoneNumber: action.value.newValue,
            },
          });
        }
        break;
      case "email":
        response = yield call(modifyExistingContact, {
          uid,
          contactId: action.value.contactId,
          email: action.value.newValue,
        });
        if (response.data.status === "success") {
          yield put({
            type: UPDATE_CONTACT_AFTER_EMAIL_CHANGE,
            value: {
              id: action.value.contactId,
              contactsListIndex,
              originalListIndex,
              newEmail: action.value.newValue,
            },
          });
        }
        break;
    }
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong again");
    } else {
    }
  } catch (error) {
    console.log(error);
  }
}

function* addPartnerToContact(action: Action) {
  try {
    yield put({ type: PARTNER_PROCESSING_START });
    const addPartner = firebase.functions().httpsCallable("addPartner");
    const contactsListIndexContact = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const originalListIndexContact = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const contactsListIndexPartner = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.partnerId
      )
    );
    const originalListIndexPartner = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.partnerId
      )
    );
    let response;

    if (action.value.partnerId) {
      response = yield call(addPartner, {
        contactId: action.value.contactId,
        contactGivenName: action.value.contactGivenName,
        contactSurname: action.value.contactSurname,
        contactPictureUrl: action.value.contactPictureUrl,
        partnerId: action.value.partnerId,
        partnerGivenName: action.value.partnerGivenName,
        partnerSurname: action.value.partnerSurname,
        partnerPictureUrl: action.value.partnerPictureUrl,
      });
    } else {
      response = yield call(addPartner, {
        contactId: action.value.contactId,
        contactGivenName: action.value.contactGivenName,
        contactSurname: action.value.contactSurname,
        contactPictureUrl: action.value.contactPictureUrl,
        partnerGivenName: action.value.partnerGivenName,
      });
    }

    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      if (action.value.partnerId) {
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: action.value.partnerId,
            givenName: action.value.partnerGivenName,
            surname: action.value.partnerSurname,
            pictureUrl: action.value.partnerPictureUrl,
            contactsListIndex: contactsListIndexContact,
            originalListIndex: originalListIndexContact,
          },
        });
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: action.value.contactId,
            givenName: action.value.contactGivenName,
            surname: action.value.contactSurname,
            pictureUrl: action.value.contactPictureUrl,
            contactsListIndex: contactsListIndexPartner,
            originalListIndex: originalListIndexPartner,
          },
        });
      } else {
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: "",
            givenName: action.value.partnerGivenName,
            surname: "",
            pictureUrl: "",
            contactsListIndex: contactsListIndexContact,
            originalListIndex: originalListIndexContact,
          },
        });
      }

      yield put({ type: PARTNER_PROCESSING_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* removePartnerFromContact(action: Action) {
  try {
    yield put({ type: PARTNER_PROCESSING_START });
    const contactsListIndexContact = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const originalListIndexContact = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const contactsListIndexPartner = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.partnerId
      )
    );
    const originalListIndexPartner = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.partnerId
      )
    );
    const removePartner = firebase.functions().httpsCallable("removePartner");
    let response;
    if (action.value.partnerId) {
      response = yield call(removePartner, {
        contactId: action.value.contactId,
        partnerId: action.value.partnerId,
      });
    } else {
      response = yield call(removePartner, {
        contactId: action.value.contactId,
      });
    }
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      if (action.value.partnerId) {
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: "",
            givenName: "",
            surname: "",
            pictureUrl: "",
            contactsListIndex: contactsListIndexContact,
            originalListIndex: originalListIndexContact,
          },
        });
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: "",
            givenName: "",
            surname: "",
            pictureUrl: "",
            contactsListIndex: contactsListIndexPartner,
            originalListIndex: originalListIndexPartner,
          },
        });
      } else {
        yield put({
          type: UPDATE_CONTACT_AFTER_PARTNER_CHANGE,
          value: {
            id: "",
            givenName: "",
            surname: "",
            pictureUrl: "",
            contactsListIndex: contactsListIndexContact,
            originalListIndex: originalListIndexContact,
          },
        });
      }
    }
    yield put({ type: PARTNER_PROCESSING_FINISH });
  } catch (error) {
    console.log(error);
  }
}

function* addKeyPersonToContact(action: Action) {
  try {
    yield put({ type: KEY_PERSON_PROCESSING_START });
    const addKeyPersonToContact = firebase
      .functions()
      .httpsCallable("addKeyPersonToContact");
    const contactsListIndex = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const originalListIndex = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    let response;

    if (action.value.keyPersonId) {
      response = yield call(addKeyPersonToContact, {
        contactId: action.value.contactId,
        keyPersonGivenName: action.value.keyPersonGivenName,
        keyPersonSurname: action.value.keyPersonSurname,
        keyPersonUrl: action.value.keyPersonUrl,
        keyPersonId: action.value.keyPersonId,
        description: action.value.description,
      });
    } else {
      response = yield call(addKeyPersonToContact, {
        contactId: action.value.contactId,
        keyPersonGivenName: action.value.keyPersonGivenName,
        description: action.value.description,
      });
    }

    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      if (action.value.keyPersonId) {
        yield put({
          type: UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION,
          value: {
            id: action.value.keyPersonId,
            givenName: action.value.keyPersonGivenName,
            surname: action.value.keyPersonSurname,
            pictureUrl: action.value.keyPersonUrl,
            description: action.value.description,
            contactsListIndex,
            originalListIndex,
          },
        });
      } else {
        yield put({
          type: UPDATE_CONTACT_AFTER_KEY_PERSON_ADDITION,
          value: {
            id: "",
            givenName: action.value.keyPersonGivenName,
            surname: "",
            pictureUrl: "",
            description: action.value.description,
            contactsListIndex,
            originalListIndex,
          },
        });
      }

      yield put({ type: KEY_PERSON_PROCESSING_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* removeKeyPersonFromContact(action: Action) {
  try {
    yield put({ type: KEY_PERSON_PROCESSING_START });
    const contactsListIndex = yield select((state: RootState) =>
      state.contacts.contactsList.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const originalListIndex = yield select((state: RootState) =>
      state.contacts.originalContacts.findIndex(
        (contact) => contact.id === action.value.contactId
      )
    );
    const removeKeyPerson = firebase
      .functions()
      .httpsCallable("removeKeyPerson");
    const response = yield call(removeKeyPerson, {
      contactId: action.value.contactId,
      keyPerson: action.value.keyPerson,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      yield put({
        type: UPDATE_CONTACT_AFTER_KEY_PERSON_REMOVAL,
        value: {
          contactsListIndex,
          originalListIndex,
          keyPersonIndex: action.value.keyPersonIndex,
        },
      });
      yield put({ type: KEY_PERSON_PROCESSING_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* addNoteToContact(action: Action) {
  try {
    yield put({ type: PROCESSING_NOTES_START });
    const addNoteToContact = firebase
      .functions()
      .httpsCallable("addNoteToContact");
    const response = yield call(addNoteToContact, {
      contactId: action.value.contactId,
      note: action.value.note,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      yield put({
        type: GET_ALL_NOTES_OF_CONTACT,
        value: {
          contactId: action.value.contactId,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAllNotesOfContact(action: Action) {
  try {
    yield put({ type: PROCESSING_NOTES_START });
    const getAllNotesOfContact = firebase
      .functions()
      .httpsCallable("getAllNotesOfAContact");
    const response = yield call(getAllNotesOfContact, {
      contactId: action.value.contactId,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      yield put({ type: UPLOAD_CONTACT_NOTES, value: response.data.notes });
      yield put({ type: PROCESSING_NOTES_FINISH });
    }
  } catch (error) {
    console.log(error);
  }
}

function* toggleIsPinnedNote(action: Action) {
  try {
    yield put({ type: PROCESSING_NOTES_START });
    const toggleIsPinnedNote = firebase
      .functions()
      .httpsCallable("toggleIsPinnedNote");
    const response = yield call(toggleIsPinnedNote, {
      contactId: action.value.contactId,
      noteId: action.value.noteId,
      isPinned: action.value.isPinned,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      yield put({
        type: GET_ALL_NOTES_OF_CONTACT,
        value: {
          contactId: action.value.contactId,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteNote(action: Action) {
  try {
    yield put({ type: PROCESSING_NOTES_START });
    const deleteNote = firebase.functions().httpsCallable("deleteNote");
    const response = yield call(deleteNote, {
      contactId: action.value.contactId,
      noteId: action.value.noteId,
    });
    if (!response || response.data.status !== "success") {
      throw new Error("Something went wrong!");
    } else {
      yield put({
        type: GET_ALL_NOTES_OF_CONTACT,
        value: {
          contactId: action.value.contactId,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* contactsSaga() {
  yield takeLatest(GET_CONTACTS, getContacts);
  yield takeLatest(SYNC_CONTACTS, syncContacts);
  yield takeLatest(SORT_CONTACTS, sortContacts);
  // yield takeLatest(SEARCH_CONTACTS, searchContacts);
  yield takeLeading(GENERATE_ACCESS_TOKEN, generateAccessToken);
  yield takeEvery(ADD_CONTACT, addContact);
  yield takeEvery(MOVE_CONTACT_TO_ORBIT, moveContactToOrbit);
  yield takeEvery(MODIFY_EXISTING_CONTACT, modifyExistingContact);
  yield takeEvery(ADD_PARTNER_TO_CONTACT, addPartnerToContact);
  yield takeEvery(REMOVE_PARTNER_FROM_CONTACT, removePartnerFromContact);
  yield takeEvery(ADD_KEY_PERSON_TO_CONTACT, addKeyPersonToContact);
  yield takeEvery(REMOVE_KEY_PERSON_FROM_CONTACT, removeKeyPersonFromContact);
  yield takeEvery(ADD_NOTE_TO_CONTACT, addNoteToContact);
  yield takeEvery(GET_ALL_NOTES_OF_CONTACT, getAllNotesOfContact);
  yield takeEvery(TOGGLE_IS_PINNED_NOTE, toggleIsPinnedNote);
  yield takeEvery(DELETE_NOTE, deleteNote);
}
