import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Constants/types";

type props = {
  currentValue: number;
  updateCurrentValue: React.Dispatch<React.SetStateAction<number>>;
  colSpan: number;
};

export default function OrbitNumber({
  currentValue,
  updateCurrentValue,
  colSpan,
}: props) {
  // const [currentValue, updateCurrentValue] = useState(6);
  const [isFull, setIsFull] = useState(false);
  const orbits = useSelector(
    (state: RootState) => state.filtersAndLists.orbits
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const currentOrbit = orbits[currentValue - 1];
    if (currentOrbit) {
      setIsFull(currentOrbit.current_capacity === currentOrbit.max_capacity);
    }
  }, [orbits, currentValue]);

  const incrementOrbit = () => {
    switch (currentValue) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        if (
          orbits[currentValue].current_capacity !==
          orbits[currentValue].max_capacity
        ) {
          updateCurrentValue(currentValue + 1);
        }
        break;
      case 6:
        if (orbits[0].current_capacity !== orbits[0].max_capacity) {
          updateCurrentValue(1);
        }
        break;
    }
  };

  return (
    <td colSpan={colSpan}>
      <div
        className={"orbit-number-border centered" + (isFull ? " is-full" : "")}
      >
        {currentValue === 6 ? (
          <div
            className="orbit-number-beyond centered"
            onClick={incrementOrbit}
          >
            &
          </div>
        ) : (
          <div className="orbit-number centered" onClick={incrementOrbit}>
            {currentValue}
          </div>
        )}
      </div>
    </td>
  );
}
