import React, { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WARM_GRAY } from "../../../Constants/colorsPalette";
import { Orbit, RootState } from "../../../Constants/types";
import EditableCell from "../../ContactsTable/table components/EditableCell";
import "./FilterList.css";

type FilterItemProps = {
  label: string;
  onSelect: () => void;
  isSelected: boolean;
  style?: CSSProperties;
};

export function FilterItem({
  label,
  onSelect,
  isSelected,
  style,
}: FilterItemProps) {
  return (
    <div
      className="filter-item"
      style={{
        ...style,
        fontWeight: isSelected ? "bold" : "normal",
        backgroundColor: isSelected ? WARM_GRAY : "transparent",
      }}
      onClick={onSelect}
    >
      {label}
    </div>
  );
}

type OrbitFilterProps = {
  orbit: Orbit;
  orbitNumber: number;
  onSelect: (orbitNumber: number) => void;
  style?: CSSProperties;
};

export function OrbitFilterItem({
  orbit,
  orbitNumber,
  onSelect,
  style,
}: OrbitFilterProps) {
  const isSelected = useSelector(
    (state: RootState) => state.filtersAndLists.selectedOrbits
  ).includes(orbit.number);

  return (
    <div
      className="orbit filter-item fixed-row"
      style={{
        ...style,
        fontWeight: isSelected ? "bold" : "normal",
        backgroundColor: isSelected ? WARM_GRAY : "transparent",
      }}
      onClick={() => {
        onSelect(orbit.number);
      }}
    >
      {orbit.title === "Beyond" ? (
        <div>& {orbit.title}</div>
      ) : (
        <div className="row" style={{}}>
          <div>
            {orbit.number} | {orbit.title}
          </div>
        </div>
      )}
      {orbit.current_capacity / orbit.max_capacity > 0.7 ? (
        <span id="capacity">
          {orbit.current_capacity}/{orbit.max_capacity}
        </span>
      ) : null}
      {/* <span id="capacity">
        {orbit.current_capacity}/{orbit.max_capacity}
      </span> */}
    </div>
  );
}

type ListFilterProps = {
  label: string;
  onSelect: (orbit: string) => void;
  style?: CSSProperties;
};

export function ListFilterItem({ label, onSelect, style }: ListFilterProps) {
  const isSelected = useSelector(
    (state: RootState) => state.filtersAndLists.selectedLists
  ).includes(label);

  return (
    <div
      className="filter-item"
      style={{
        ...style,
        fontWeight: isSelected ? "bold" : "normal",
        backgroundColor: isSelected ? WARM_GRAY : "transparent",
      }}
      onClick={() => onSelect(label)}
    >
      {label}
    </div>
  );
}

type FlowFilterProps = {
  label: string;
  onSelect: (orbit: string) => void;
  style?: CSSProperties;
};

export function FlowFilterItem({ label, onSelect, style }: FlowFilterProps) {
  const isSelected = useSelector(
    (state: RootState) => state.filtersAndLists.selectedLists
  ).includes(label);

  return (
    <div
      className="filter-item"
      style={{
        ...style,
        fontWeight: isSelected ? "bold" : "normal",
        backgroundColor: isSelected ? WARM_GRAY : "transparent",
      }}
      onClick={() => onSelect(label)}
    >
      {label}
    </div>
  );
}
