import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import xIcon from "../../../assets/x.svg";

import Downshift from "downshift";
import { RootState } from "../../../../Constants/types";

type props = {
  availableTags: string[];
  setAvailableTags: React.Dispatch<React.SetStateAction<string[]>>;
  colSpan?: number;
};

export default function TagsList({
  availableTags,
  setAvailableTags,
  colSpan,
}: props) {
  const tags = useSelector((state: RootState) => state.filtersAndLists.tags);

  const [value, setValue] = useState("");
  // const [availableTags, setAvailableTags] = useState<string[]>([]);

  return (
    <td colSpan={colSpan} className="tags-list">
      <div className="row">
        {availableTags?.map((list) => (
          <div key={"list-" + list} className="list-tag">
            {list}
            <div
              className="delete-icon centered"
              onClick={() => {
                setAvailableTags(availableTags.filter((tag) => tag !== list));
              }}
            ></div>
          </div>
        ))}
        <div key={"list-test"} style={{ display: "flex" }}>
          <Downshift
            onChange={(selection) => {
              if (
                selection !== null &&
                tags.includes(selection.toLowerCase()) &&
                !availableTags.includes(selection.toLowerCase())
              ) {
                let newAvaialbleTags = availableTags?.concat([
                  selection.toLowerCase(),
                ]);
                setAvailableTags(newAvaialbleTags);
              }
              setValue("");
            }}
            inputValue={value}
            onInputValueChange={(newValue) => setValue(newValue)}
            itemToString={(item) => (item ? item : "")}
          >
            {({
              getInputProps,
              getItemProps,
              getMenuProps,
              inputValue,
              isOpen,
            }) => (
              <div>
                {/* <label {...getLabelProps()}>Enter a fruit:</label> */}
                <input
                  className="list-tag test"
                  style={{ width: 48 }}
                  {...getInputProps()}
                />
                <ul {...getMenuProps()} className="options-menu">
                  {isOpen &&
                    tags
                      .filter(
                        (tag) =>
                          !inputValue || tag.includes(inputValue.toLowerCase())
                      )
                      .map((item, index) => (
                        <li
                          className="options-menu-item"
                          {...getItemProps({
                            key: `${item}${index}`,
                            item,
                            index,
                          })}
                        >
                          {item}
                        </li>
                      ))}
                </ul>
              </div>
            )}
          </Downshift>
        </div>
      </div>
    </td>
  );
}
