import React, { useState } from "react";
import AvatarEditorComponent from "react-avatar-editor";

import { PRIMARY_60, WARM_GRAY } from "../../../Constants/colorsPalette";
import avatarPlaceholder from "../../../assets/avatar_placeholder_light.svg";
import corners from "../../../assets/corners.svg";
import RaisedButton, { FileButton } from "../RaisedButton";
import "./PhotoChange.css";
import { useDispatch } from "react-redux";
import { UPDATE_PROFILE_PICTURE } from "../../../actions";

export default function AvatarEditor(
  uploadPhoto: Function,
  photoURL: string,
  closeModal?: Function | undefined
) {
  let emptyImage: File = new File([], "");
  const [imageFile, setImageFile] = useState(emptyImage);

  const dispatch = useDispatch();

  var _editorRef: AvatarEditorComponent | null;
  const setEditorRef = (editor: AvatarEditorComponent | null) =>
    (_editorRef = editor);

  const handleImageFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files.length > 0) {
      let image: File = e.target.files[0];
      setImageFile(() => image);
    }
  };

  const getBase64 = (file: File, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onClickSave = () => {
    if (_editorRef) {
      const canvas = _editorRef.getImage();
      canvas.toBlob((blob: Blob | null) => {
        blob &&
          uploadPhoto(
            new File([blob], "avatarPhoto.png", {
              type: "image/png",
              lastModified: new Date().getTime(),
            })
          );
        closeModal && closeModal();
      });
    }
    getBase64(imageFile, (result: any) => {
      // dispatch({ type: UPDATE_PROFILE_PICTURE, value: { pictureUrl: result } });
      dispatch({
        type: UPDATE_PROFILE_PICTURE,
        value: { picture: imageFile, avatarName: photoURL },
      });
    });
  };

  return (
    <div
      className="column centered"
      style={{ flexGrow: 1, justifyContent: "space-around", padding: "5%" }}
    >
      <div
        className="centered"
        style={{
          width: "250px",
          height: "250px",
          backgroundImage: `url(${corners})`,
        }}
      >
        {imageFile?.size === 0 ? (
          <img src={avatarPlaceholder} width="220px" height="220px" />
        ) : (
          <AvatarEditorComponent
            ref={setEditorRef}
            image={imageFile}
            width={220}
            height={220}
            border={7}
            color={[196, 196, 196, 0.5]}
            scale={1.2}
            borderRadius={110}
          />
        )}
      </div>
      <FileButton
        text="Upload Photo"
        style={{
          maxWidth: "200px",
          backgroundColor: PRIMARY_60,
          color: WARM_GRAY,
        }}
        onChange={(e) => handleImageFile(e)}
      />
      <RaisedButton
        text="Save Photo"
        style={{
          maxWidth: "200px",
        }}
        onClick={onClickSave}
        dark
        disabled={imageFile?.size === 0}
      />
    </div>
  );
}
