import firebase from "../../config/firebase";
const db = firebase.firestore();
const TestUserCollection = db.collection("testUsers");

const getTestUser = async (password: String) => {
  const testUser = await TestUserCollection.where("password", "==", password)
    .limit(1)
    .get();
  if (testUser.empty) {
    console.log("Couldn't find the user, please try again.");
    throw new Error("Couldn't find test user");
  } else {
    return testUser.docs[0].data();
  }
};

export default { getTestUser };
