import { Reducer } from "redux";
import { NEW_PAYMENT, PAYMENT_FAILED, UPDATE_LAST_PAYMENT } from "../actions";
import { Action } from "../Constants/types";

interface paymentRootState {
  lastPayment: any;
  processing: boolean;
  error: boolean;
}

const initialState: paymentRootState = {
  lastPayment: null,
  processing: false,
  error: false,
};

const filtersReducer: Reducer<paymentRootState, Action> = (
  state: paymentRootState = initialState,
  action: Action
) => {
  const newState = { ...state };

  switch (action.type) {
    case NEW_PAYMENT:
      return { ...newState, processing: true };
    case PAYMENT_FAILED:
      return { ...newState, processing: false, error: true };
    case UPDATE_LAST_PAYMENT:
      return { ...newState, lastPayment: action.value };
  }
  return newState;
};

export default filtersReducer;
