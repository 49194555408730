import React, { useEffect, useState } from "react";

import { Contact, Flow, FlowStatus, RootState } from "../../Constants/types";
import TableHeader from "./table components/TableHeader";
import TableRow from "./table components/TableRow";
import "./ContactsTable.css";
import ContactCard from "./ContactCard";
import { useSelector } from "react-redux";

// const spans = [1, 1, 3, 3, 6, 4, 3, 3];

const spans = [1, 1, 3, 3, 4, 5, 4, 3];
const displayedKeys = [
  "orbit",
  "pictureUrl",
  "givenName",
  "surname",
  "lists",
  "email",
  "phoneNumbers",
  "lastContacted",
];
const headers: { title: string; contactKey: keyof Contact | "status" }[] = [
  { title: "Orbit", contactKey: "orbitNumber" },
  { title: "", contactKey: "pictureUrl" },
  { title: "First Name", contactKey: "givenName" },
  { title: "Surname", contactKey: "surname" },
  { title: "Lists", contactKey: "lists" },
  { title: "Email", contactKey: "email" },
  { title: "Phone Number", contactKey: "phoneNumbers" },
  { title: "Last Contacted", contactKey: "lastContacted" },
];

type props = {
  searchValue: string;
  contacts: Flow["contacts"];
  flowView: boolean;
};

export default function ContactsTable({
  searchValue,
  contacts,
  flowView,
}: props) {
  const displayContact = useSelector(
    (state: RootState) => state.contacts.displayContact
  );
  const sortingValue = useSelector(
    (state: RootState) => state.contacts.sortingValue
  );

  const [displayedContacts, setDisplayedContacts] = useState(contacts);
  const [sortedBy, setSortedBy] = useState<keyof Contact | null>(sortingValue);
  const [isReversed, setIsReversed] = useState(false);
  const [currentSpans, setCurrentSpans] = useState(spans);
  const [currentHeaders, setCurrentHeaders] = useState(headers);

  useEffect(() => {
    setDisplayedContacts(contacts);
    //render flow table view correctly
    if (flowView) {
      let newHeaders: typeof headers = [
        { title: "Status", contactKey: "status" },
      ];
      newHeaders = newHeaders.concat(headers);
      setCurrentHeaders(newHeaders);
      setCurrentSpans([3].concat(spans));
    } else {
      setCurrentHeaders(headers);
      setCurrentSpans(spans);
    }
  }, [contacts, searchValue, sortedBy, isReversed]);

  const setSortingValue = (key: keyof Contact) => {
    if (key === sortedBy) setIsReversed(!isReversed);
    else {
      setSortedBy(key);
      isReversed && setIsReversed(false);
    }
  };

  const renderContactFields = (
    { contact, status }: { contact: Contact; status?: FlowStatus },
    rowIndex: number
  ) => {
    const keys = status
      ? [status.toString()].concat(Object.keys(contact))
      : displayedKeys;
    return keys.map((key: any, i: number) => (
      <TableRow
        key={"contact-" + rowIndex + i + contact.givenName}
        fieldKey={key}
        index={i}
        spans={currentSpans}
        status={status}
        contact={contact}
        rowIndex={rowIndex}
      />
    ));
  };
  const renderContacts = () =>
    displayedContacts.map(({ contact, status }, i: number) => (
      <tr key={"contact-" + contact.givenName + contact.surname + i}>
        {renderContactFields({ contact, status }, i)}
      </tr>
    ));

  return (
    <div className="max-size">
      <table className="contacts-table">
        <thead>
          <TableHeader
            headers={currentHeaders}
            spans={currentSpans}
            setSortingValue={setSortingValue}
            sortedBy={sortedBy}
          />
        </thead>
        <tbody>{renderContacts()}</tbody>
      </table>
      {displayContact ? <ContactCard /> : null}
    </div>
  );
}
