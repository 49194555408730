import React, { useEffect, useState } from "react";
import firebase from "../../../config/firebase";

import defaultAvatar from "../../../assets/avatar_placeholder.svg";
import logo from "../../../assets/noO_OrbitLogo_Purple.png";
import "./OrbitAvatar.css";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../Constants/types";

type props = {
  defaultRedirect: string;
};

export default function OrbitAvatar({ defaultRedirect }: props) {
  const userData = useSelector((state: RootState) => state.auth.userData);
  const avatar = userData?.pictureUrl;
  const history = useHistory();

  return (
    <div
      className="orbit-avatar-container centered fixed-row"
      onClick={() => {
        history.push("/my-orbits");
      }}
    >
      <div className="o-shape" style={{ width: "61px", height: "61px" }}>
        <div className="o-shape" style={{ width: "48px", height: "48px" }}>
          <div className="o-shape" style={{ width: "35px", height: "35px" }}>
            <img
              className="avatar-img"
              src={avatar ? avatar : defaultAvatar}
              style={{ width: "32px", height: "32px" }}
            />
          </div>
        </div>
      </div>
      <img className="logo-img" src={logo} />
    </div>
  );
}
