import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from "react-router-dom";
import { AuthenticatedRoute, SignupStepRoute } from "./customRoutes";
import { useDispatch, useSelector } from "react-redux";

import Loader from "react-loader-spinner";
import { CHECK_EXTRA_USER_DATA } from "../actions";
import FirebaseApp from "./firebase";
import firebase from "../config/firebase";

//Pages
import WelcomePage from "./WelcomePage";
import Signup from "./Signup";
import Login from "./Login";
import LandingPage from "./LandingPage";
import OrbitContactsPage from "./OrbitContactsPage";
import PrivacyPolicy from "./PrivacyPolicy";
import { PRIMARY } from "../Constants/colorsPalette";
import { RootState } from "../Constants/types";
import AccountSettingsPage from "./AccountSettingsPage";
import ForgotYourPasswordPage from "./Login/ForgotYourPasswordPage";

export default function App() {
  const [isUserLoaded, setisUserLoaded] = useState(false);
  const { signedinithGoogle, hasSubscribed, ready } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();

  firebase.auth().onAuthStateChanged((user) => {
    setisUserLoaded(true);
  });

  useEffect(() => {
    isUserLoaded && dispatch({ type: CHECK_EXTRA_USER_DATA });
  }, [isUserLoaded]);

  return isUserLoaded && ready ? (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact
          render={(props: RouteComponentProps) =>
            firebase.auth().currentUser ? (
              <Redirect to="/my-orbits" {...props} />
            ) : (
              <LandingPage />
            )
          }
        />
        <Route path="/welcome" exact component={WelcomePage} />
        <Route
          path="/login"
          exact
          render={(props: RouteComponentProps) =>
            firebase.auth().currentUser ? (
              <Redirect to="/my-orbits" {...props} />
            ) : (
              <Login />
            )
          }
        />
        <Route path="/privacy" exact component={PrivacyPolicy} />
        <Route
          path="/ForgotYourPassword"
          exact
          component={ForgotYourPasswordPage}
        />
        <Route
          path="/sync-contacts"
          component={() => {
            window.location.href =
              "https://accounts.google.com/o/oauth2/v2/auth?client_id=201100756936-7nt3a3qrl8ibb7fp85b9b1f69n2t3roc.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/contacts&redirect_uri=http://localhost:3000/oauthcallback/:code&response_type=code";
            return null;
          }}
        />
        <AuthenticatedRoute
          path="/my-orbits"
          exact
          component={OrbitContactsPage}
        />
        <AuthenticatedRoute
          path="/account-settings"
          exact
          component={AccountSettingsPage}
        />
        {/* this has to always be at the bottom of the switch component */}
        <SignupStepRoute />
      </Switch>
      <FirebaseApp></FirebaseApp>
    </BrowserRouter>
  ) : (
    <div className="max-size centered" style={{ height: "100vh" }}>
      <Loader type="TailSpin" color={PRIMARY} height={30} width={30} />
    </div>
  );
}
