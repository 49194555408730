import React from "react";

type props = {
  src: string;
  colSpan?: number;
};

export default function ContactAvatar({ src, colSpan }: props) {
  return (
    <td colSpan={colSpan} className="row centered">
      <img src={src} className="contact-avatar" alt="" />
    </td>
  );
}
