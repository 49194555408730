import { Orbit, Contact, Flow, FlowStatus } from "./types";

export const dummyContacts: Contact[] = [
  // {
  //   orbit: Orbit.o3,
  //   avatar:
  //     "https://writestylesonline.com/wp-content/uploads/2019/01/What-To-Wear-For-Your-Professional-Profile-Picture-or-Headshot.jpg",
  //   firstName: "Alma",
  //   surName: "Lovisa",
  //   lists: ["Movie Addicts", "Dinner Parties"],
  //   email: "balmalovisa@gmail.com",
  //   phoneNumber: "+1 838 385 1264",
  //   lastContacted: new Date("2020-12-01T12:01:04.753Z"),
  // },
  // {
  //   orbit: Orbit.o3,
  //   avatar:
  //     "https://writestylesonline.com/wp-content/uploads/2019/01/What-To-Wear-For-Your-Professional-Profile-Picture-or-Headshot.jpg",
  //   firstName: "Dorothy",
  //   surName: "Tautz",
  //   lists: ["Publishing"],
  //   email: "d.tautz@gmail.com",
  //   phoneNumber: "+1 987 234 5429",
  //   lastContacted: new Date("2020-11-01T12:01:04.753Z"),
  // },
  // {
  //   orbit: Orbit.o2,
  //   avatar:
  //     "https://i.pinimg.com/236x/64/04/cc/6404cc68451dc3dd31385a37487b6c6a--fill-in-flash-photography-corporate-photography-poses.jpg",
  //   firstName: "Angelo",
  //   surName: "Nickleson",
  //   lists: ["New York", "Dinner Parties"],
  //   email: "angelo@gmail.com",
  //   phoneNumber: "+1 535 420 7720",
  //   lastContacted: new Date("2019-12-01T12:01:04.753Z"),
  // },
  // {
  //   orbit: Orbit.o5,
  //   avatar:
  //     "https://i.pinimg.com/236x/64/04/cc/6404cc68451dc3dd31385a37487b6c6a--fill-in-flash-photography-corporate-photography-poses.jpg",
  //   firstName: "Elvis",
  //   surName: "O’Niel",
  //   lists: ["Publishing", "Writers"],
  //   email: "elvis.o.niel@gmail.com",
  //   phoneNumber: "+44 7915 378760",
  //   lastContacted: new Date("2018-12-01T12:01:04.753Z"),
  // },
];

export const dummyFlows: Flow[] = [
  // {
  //   title: "30th Birthday Party",
  //   contacts: [
  //     { contact: dummyContacts[0], status: FlowStatus.LEAD },
  //     { contact: dummyContacts[2], status: FlowStatus.NO },
  //     { contact: dummyContacts[1], status: FlowStatus.CONTACTED },
  //   ],
  // },
];
