import React from "react";

import { SECONDARY } from "../../../Constants/colorsPalette";
import logo from "../../../assets/OrbitLogo_Purple.png";
import SidebarElement from "./SidebarElement";
import "./Sidebar.css";

type props = {
  selectedId: number;
  setSelectedId: Function;
};

export default function SideBar({ selectedId, setSelectedId }: props) {
  const elements = [
    { cy: "90", text: "Welcome" },
    { cy: "180", text: "Not Linkedin" },
    { cy: "230", text: "Not Social Media" },
    { cy: "280", text: "Not Salesforce" },
    { cy: "330", text: "Not Messaging" },
    { cy: "420", text: "Human Connection" },
    { cy: "470", text: "Slow Software" },
    { cy: "520", text: "The Orbit Pledge" },
    { cy: "570", text: "Bicycle for the Mind" },
    { cy: "660", text: "What is an Orbit?" },
    { cy: "710", text: "What is a tag?" },
    { cy: "760", text: "What is a slice?" },
    { cy: "810", text: "Orbit Reviews" },
    { cy: "880", text: "Get Started" },
  ];

  return (
    <div className="sidebar">
      <div id="logo">
        <img src={logo} />
      </div>
      <svg
        viewBox="0 0 300 1000"
        id="chart"
        preserveAspectRatio="xMinYMax meet"
      >
        <line
          strokeWidth="2"
          x1="9"
          y1="-250"
          x2="9"
          y2="4000"
          stroke={SECONDARY}
        />
        {elements.map((element, i) => (
          <SidebarElement
            id={i + 1}
            key={"menuItem" + (i + 1)}
            cx="9"
            cy={element.cy}
            text={element.text}
            selectedId={selectedId}
            onClick={() => {
              var slide = document.getElementById(`slide${i + 1}`)?.offsetTop;
              window.scrollTo({
                top: (slide ? slide : 100) + (selectedId > i + 1 ? 400 : -400),
              });
              setSelectedId(i + 1);
            }}
          />
        ))}
      </svg>
    </div>
  );
}
