import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapsible from "react-collapsible";
import Loader from "react-loader-spinner";
import editIcon from "../../../assets/pencil-fill.svg";

import { RootState } from "../../../Constants/types";
import { PRIMARY } from "../../../Constants/colorsPalette";
import {
  EDIT_ORBITS_TITLES_START,
  EDIT_ORBITS_TITLES,
  SELECT_LIST,
  SELECT_ORBIT,
  SELECT_PROPERTY,
  ADD_COLLECTION,
  GET_CONTACTS,
  RESET_CHANGE_IN_TAGS_NAMES,
  DELETE_LIST_FROM_COLLECTION,
  DELETE_EMPTY_COLLECTION,
  SHOW_ADD_CONTACT,
  UNSHOW_CONTACT,
} from "../../../actions";
import ListTrigger from "./Trigger";
import {
  Flows,
  Lists,
  MissingProperties,
  Orbits,
} from "../../shared/FilterList";
import OrbitAvatar from "../../shared/OrbitAvatar";
import GroupingOptions from "./GroupingOptions";
import RaisedButton from "../../shared/RaisedButton";
import Add from "@material-ui/icons/Add";
import rightArrowIcon from "../../../assets/right-arrow.svg";
import deleteIcon from "../../../assets/purple-x.svg";
import { Done } from "@material-ui/icons";
import ValueInput from "./ValueInput";

const missingProperties = ["Orbit", "List", "Name", "Email", "Phone"];

export default function OrbitSidebar() {
  const { orbits, lists, changeInTagsNames } = useSelector(
    (state: RootState) => state.filtersAndLists
  );
  const flows = useSelector((state: RootState) => state.flows).flows.map(
    (flow) => flow.title
  );

  const processingLists = useSelector(
    (state: RootState) => state.filtersAndLists.listsProcessing
  );

  const processingOrbits = useSelector(
    (state: RootState) => state.filtersAndLists.orbitsProcessing
  );

  const editOrbitNames = useSelector(
    (state: RootState) => state.filtersAndLists.editOrbitsNames
  );

  var count = 0;
  for (let i = 0; i < lists.length; i++) {
    const tags = lists[i].tags;
    count = count + 2;
    for (let j = 0; j < tags.length; j++) {
      count++;
    }
  }

  const [editing, setEditing] = useState(false);

  const [titleO1, setTitleO1] = useState("");
  const [titleO2, setTitleO2] = useState("");
  const [titleO3, setTitleO3] = useState("");
  const [titleO4, setTitleO4] = useState("");
  const [titleO5, setTitleO5] = useState("");

  const [groupValue, setGroupValue] = useState("");

  const [elRefs, setElRefs] = useState<any[]>([]);
  var indexer = 0;

  const dispatch = useDispatch();
  const selectOrbit = (orbitNumber: number) => {
    dispatch({ type: SELECT_ORBIT, value: { orbitNumber } });
  };

  const selectList = (listName: string) =>
    dispatch({ type: SELECT_LIST, value: { listName } });

  // const selectFlow = (title: string) => {
  //   dispatch({ type: SELECT_FLOW, value: { flowTitle: title } });
  // };

  const selectProperty = (propertyName: string) =>
    dispatch({ type: SELECT_PROPERTY, value: { propertyName } });

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(count)
        .fill(null)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [lists]);

  useEffect(() => {
    if (orbits.length === 6) {
      setTitleO1(orbits[0].title);
      setTitleO2(orbits[1].title);
      setTitleO3(orbits[2].title);
      setTitleO4(orbits[3].title);
      setTitleO5(orbits[4].title);
    }
  }, [orbits]);

  useEffect(() => {
    if (changeInTagsNames) {
      dispatch({ type: GET_CONTACTS });
      dispatch({ type: RESET_CHANGE_IN_TAGS_NAMES });
    }
  }, [changeInTagsNames]);

  const onDoneClick = () => {
    for (let index = 0; index < elRefs.length; index++) {
      const element = elRefs[index].current;
      element.focus();
    }
    if (groupValue !== "") {
      dispatch({
        type: ADD_COLLECTION,
        value: {
          listHeader: groupValue,
        },
      });
      setGroupValue("");
    }
    setEditing(false);
  };

  const renderFilters = () => (
    <>
      <div className="row" style={{ width: "100%" }}>
        <Collapsible open={true} trigger={<ListTrigger text="Orbits" />}>
          {editOrbitNames ? (
            <div className="col">
              <div className="edit-Orbit-name-container">
                1 |
                <input
                  className="edit-Orbit-name"
                  value={titleO1}
                  onChange={(e) => setTitleO1(e.target.value)}
                />
              </div>
              <div className="edit-Orbit-name-container">
                2 |
                <input
                  className="edit-Orbit-name"
                  value={titleO2}
                  onChange={(e) => setTitleO2(e.target.value)}
                />
              </div>
              <div className="edit-Orbit-name-container">
                3 |
                <input
                  className="edit-Orbit-name"
                  value={titleO3}
                  onChange={(e) => setTitleO3(e.target.value)}
                />
              </div>
              <div className="edit-Orbit-name-container">
                4 |
                <input
                  className="edit-Orbit-name"
                  value={titleO4}
                  onChange={(e) => setTitleO4(e.target.value)}
                />
              </div>
              <div className="edit-Orbit-name-container">
                5 |
                <input
                  className="edit-Orbit-name"
                  value={titleO5}
                  onChange={(e) => setTitleO5(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div>
              {processingOrbits ? null : (
                <Orbits
                  style={{ display: "flex" }}
                  onSelect={selectOrbit}
                  orbits={orbits}
                />
              )}
            </div>
          )}
        </Collapsible>
        {processingOrbits ? (
          <div className="edit-icon">
            <Loader type="TailSpin" color={PRIMARY} height={20} width={20} />
          </div>
        ) : null}
        {editOrbitNames && !processingOrbits ? (
          <div className="edit-icon">
            <Done
              style={{ width: 20 }}
              onClick={() => {
                dispatch({
                  type: EDIT_ORBITS_TITLES,
                  value: {
                    o1PrevTitle: orbits[0].title,
                    o1NewTitle: titleO1,
                    o2PrevTitle: orbits[1].title,
                    o2NewTitle: titleO2,
                    o3PrevTitle: orbits[2].title,
                    o3NewTitle: titleO3,
                    o4PrevTitle: orbits[3].title,
                    o4NewTitle: titleO4,
                    o5PrevTitle: orbits[4].title,
                    o5NewTitle: titleO5,
                  },
                });
              }}
            />
          </div>
        ) : (
          <div>
            {!processingOrbits ? (
              <img
                className="edit-icon"
                alt=""
                src={editIcon}
                width={18}
                height={18}
                onClick={() => {
                  dispatch({ type: EDIT_ORBITS_TITLES_START });
                }}
              />
            ) : null}
          </div>
        )}
      </div>
      <br />
      <div className="row" style={{ width: "100%" }}>
        <Collapsible trigger={<ListTrigger text="Lists" />}>
          {processingLists ? null : (
            <div>
              {editing ? (
                <div>
                  <div className="adding-input-container">
                    <img alt="" src={rightArrowIcon} width={10} height={10} />
                    <input
                      className="adding-input"
                      placeholder="Add Group"
                      value={groupValue}
                      onChange={(e) => setGroupValue(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          dispatch({
                            type: ADD_COLLECTION,
                            value: {
                              listHeader: groupValue,
                            },
                          });
                          setGroupValue("");
                        }
                      }}
                    />
                  </div>
                  <div>
                    {lists.map(({ id, title, tags }) => (
                      <div id={title + "-" + id} className="group-container">
                        <div className="whole-row">
                          <div className="adding-input-container">
                            <img
                              alt=""
                              src={rightArrowIcon}
                              width={10}
                              height={10}
                            />
                            <ValueInput
                              defaultValue={title}
                              headerId={id}
                              valueType="group"
                              ref={elRefs[indexer++]}
                            />
                          </div>
                          {tags.length === 0 ? (
                            <img
                              alt=""
                              className="delete-icon"
                              src={deleteIcon}
                              onClick={() => {
                                dispatch({
                                  type: DELETE_EMPTY_COLLECTION,
                                  value: { listId: id },
                                });
                              }}
                            />
                          ) : null}
                        </div>
                        <div className="list-container">
                          {tags.map((tag) => (
                            <div className="whole-row">
                              <div className="adding-input-container">
                                <ValueInput
                                  defaultValue={tag}
                                  title={title}
                                  headerId={id}
                                  ref={elRefs[indexer++]}
                                  valueType="list"
                                />
                              </div>
                              <img
                                alt=""
                                className="delete-icon"
                                src={deleteIcon}
                                onClick={() => {
                                  dispatch({
                                    type: DELETE_LIST_FROM_COLLECTION,
                                    value: { tag, listId: id },
                                  });
                                }}
                              />
                            </div>
                          ))}
                          <div
                            className="adding-input-container"
                            style={{ marginTop: 15 }}
                          >
                            <ValueInput
                              defaultValue={""}
                              placeholder="Add List"
                              title={title}
                              ref={elRefs[indexer++]}
                              valueType={"new-list"}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  {lists.map(({ title, tags }) => (
                    <div
                      key={"collection-" + title}
                      style={{ paddingBottom: 10 }}
                    >
                      <Collapsible
                        key={"filters-" + title}
                        trigger={
                          <ListTrigger
                            text={title}
                            style={{ fontWeight: "normal" }}
                          />
                        }
                      >
                        <Lists list={tags} onSelect={selectList} />
                      </Collapsible>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Collapsible>
        {processingLists ? (
          <Loader type="TailSpin" color={PRIMARY} height={20} width={20} />
        ) : (
          <div>
            {editing ? (
              <Done
                className="edit-icon"
                style={{ width: 18, height: 18 }}
                onClick={onDoneClick}
              />
            ) : (
              <img
                alt=""
                className="edit-icon"
                src={editIcon}
                width={18}
                height={18}
                onClick={() => {
                  setEditing(true);
                }}
              />
            )}
          </div>
        )}
      </div>

      <br />
      <hr style={{ backgroundColor: PRIMARY, width: "100%", height: "0px" }} />
      <br />
      {/* <Collapsible trigger={<ListTrigger text="Flow" />}>
        <Flows
          list={flows}
          onSelect={() => selectFlow("30th Birthday Party")}
        />
      </Collapsible>
      <br />
      <hr style={{ backgroundColor: PRIMARY, width: "100%", height: "0px" }} />
      <br /> */}
      <Collapsible trigger={<ListTrigger text="Completion" />}>
        <MissingProperties list={missingProperties} onSelect={selectProperty} />
      </Collapsible>
    </>
  );

  return (
    <div className="orbits-sidebar column">
      <OrbitAvatar defaultRedirect={""} />
      <div style={{ marginTop: 30, width: "100%" }}>
        <RaisedButton
          text="Add Contact"
          style={{
            maxWidth: "170px",
            maxHeight: "36px",
            fontSize: "16px",
            marginLeft: "15.5px",
          }}
          leadingIcon={() => (
            <Add style={{ width: "22px", marginBottom: "3px" }} />
          )}
          onClick={() => {
            dispatch({ type: UNSHOW_CONTACT });
            dispatch({ type: SHOW_ADD_CONTACT });
          }}
        />
      </div>
      <GroupingOptions />
      {renderFilters()}
    </div>
  );
}
