import firebase from "firebase";
import ReduxSagaFirebase from "redux-saga-firebase";

const config = {
  apiKey: "AIzaSyACRkOU4nSVkr-xrXQGJlENBS-1O01RQHA",
  authDomain: "orbit-cd765.firebaseapp.com",
  databaseURL: "https://orbit-cd765.firebaseio.com",
  projectId: "orbit-cd765",
  storageBucket: "orbit-cd765.appspot.com",
  messagingSenderId: "201100756936",
  appId: "1:201100756936:web:bbe5aba9a311341fa5a2fd",
  measurementId: "G-6CCGMCWHKH",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const reduxSagaFirebase = new ReduxSagaFirebase(firebase);
reduxSagaFirebase.region = "us-central1";

export const rsf = reduxSagaFirebase;

export default firebase;
